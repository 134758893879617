import React from 'react';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import RiverTrip_tours_content from '../../../../components/version_anglais/OuestAng/Rivertrip_tours/itineraire/RiverTrip_tours_content';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const RiverTrip_tours = () => {
    return (
      <div className="app-tours">
        <Navbar_tours />
        <RiverTrip_tours_content />
        <Footer/>
      </div>
    );
};

export default RiverTrip_tours;