import React from 'react';
import Tour_nosyBe_content_FR from '../../../../components/version_francaise/nordFR/nosyBe_tours/itineraire/Tour_nosyBe_content_FR';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Footer_FR from '../../../../components/Footer_FR';


const Tour_nord_nosyBe_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />
        <Tour_nosyBe_content_FR/>
        <Footer_FR />
      </div>
    );
};

export default Tour_nord_nosyBe_FR;