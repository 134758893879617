import React from 'react';
import Classical_north_content from '../../../../components/version_anglais/nordAng/nord_classique/itineraire/Classical_north_content';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const Classical_north = () => {
    return (
        <div className='app-tours'>
            <Navbar_tours />
            <Classical_north_content />
            <Footer/>
        </div>
    );
};

export default Classical_north;