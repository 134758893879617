import React from 'react';
import Couv from "./../../assets/tsingyrouge-900x525.jpg";
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/akarafantsika.jpg";
import i3 from "./../../../../../assets/ankarafantsika.jpg";
import i4 from "./../../../../../assets/antsohihy.jpg";
import i5 from "./../../../../../assets/ambanja.jpg";
import i6 from "./../../../../../assets/Ankarana2.jpg";
import i7 from "./../../../../../assets/tsingyankaranan.jpg";
import i8 from "./../../../../../assets/tsingyrouge-900x525.jpg";
import i9 from "./../../../../../assets/amber.jpg";
import i10 from "./../../../../../assets/diego.jpg";
import i11 from "./../../../../../assets/emerald.jpg";
import i12 from "./../../../../../assets/baie-de-sakalava.jpg";
import i13 from "./../../../../../assets/ramena.jpg";
import i14 from "./../../../../.././assets/Tana.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Classical_north_map from '../../../../version_anglais/nordAng/map/Classical_north_map';
import { Link } from "react-router-dom";
import Classical_north_cover_FR from '../Classical_north_cover_FR';

const Classique_nord_content = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <Classical_north_cover_FR />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itineraire</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/FR/nord/Classique-nord-gallery-FR"
                        className="a"
                      >
                        Photos
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>

                <div className="itinerary">
                  <div className="row gy-5" id="row-princip">
                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i1} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 1{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antananarivo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Acceuil à l' aéroport , Transfert à l' hôtel .
                                Mise au point pour le détail du voyage Si le
                                temps vous le permet , Vous pourriez admirer au
                                palais de la reine Antananarivo et ses environs
                                pour un point de vue magnifique , La plus haute
                                altitude à Antananarivo
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i2} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 2{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana - Ankarafantsika
                              </h2>

                              <div className="lig"></div>
                              <p>
                                Route pour Ankarafantsika à travers les vastes
                                étendues de savanes, du plateaux du Tampoketsa,
                                avec le paysage subdésertiques: les marchands
                                des mangues, jujubes, tamarins en fonction des
                                saisons .
                                <br />
                                Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i3} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 3{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarafantsika
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visite du PARC NATIONAL d' ANKARAFANTSIKA pour
                                les plantes médicinales, le baobab, les
                                lémuriens comme le Coquerelle Sifaka, les
                                oiseaux. <br /> Le parc National Ankarafantsika
                                est idéal pour l'observation des oiseaux
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i4} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 4{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarafantsika - Port Berger - Antsohihy
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Antsohihy . Sur la route , vous
                                rencontreriez les populations locales du Nord
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i5} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 5{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antsohihy - Ambanja
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Ambanja Ambanja , une ville entourée
                                de plantation de cacao , d'Ilang Ilang et de
                                Vanille Visite du réserve villageoise pour le
                                Black INDRI INDRI
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i6} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 6{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ambanja - Ankarana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Ankarana Visite de la plantation
                                MILLOT , plantation de vanille, d' Ilang Ilang,
                                de cacao où ils vont vous démontrer les étapes à
                                suivre pour planter le cacao jusqu' à son
                                exportation .
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i7} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 7{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visite du parc National de l' ANKARANA pour le
                                TSINGY d' ANKARANA . Tsingy d' Ankarana est le
                                même formation que ceux du Tsingy de Bemaraha. A
                                la découverte des Tsingys , une vastes étendues
                                de roches calcaires sculptés par l' érosion, les
                                grottes et rivières souterraines , les caves des
                                chauves souris , les faunes et flores les
                                euphorbias, les pachypodium, les baobabs ; les
                                lémuriens , les oiseaux, tenrecs, mangoustes
                                Galidia Elegans à queue annelés
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i8} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 8{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarana - Red Tsingy- Joffre ville
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Joffre Ville . A la découverte du
                                Tsingy rouge avant d' arriver à Joffre ville ,
                                de formation latérite avec ses fabuleux couleurs
                                rouges TSINGY ROUGE née de la rencontre de l'eau
                                de pluie et d'une roche compactée il y a
                                plusieurs millions d' années .Tsingy rouge est
                                le résultat de l' érosion de ce massif de grés .
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i9} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 9{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Joffre ville - Parc National Montagne d' Ambre
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Le visite du parc National de Montagne d' Ambre
                                le plus vieux parcs à Madagascar crée en 1958 a
                                pour but de préserver le trésor biologique
                                faunes et flores. Avec ses climats tropicales
                                humides; le parc vous offre l'opportunité de
                                voir des cascades, des lémuriens comme le makis
                                bruns de Sanford, Chirogale, spécalement le
                                chaméléon " BROOKESIA MINIMA" le plus petit
                                chaméléon au monde qui mesure au moin d'une
                                pouce.
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i10} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 10{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Joffre Ville - Diego - Ramena
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Ramena Visite de la ville de Diégo
                                avant d' arriver à Ramena , en passant par l'île
                                du "PAIN du SUCRE" emblème de Diégo , le baobab
                                Madagascar Suarenzis endémique à Madagascar
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i11} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 11{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ramena - Emerald Sea - Ramena
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Excursion toute la journée au Mer d' Eméraude
                                Départ avec le bateaux pour le fameux MER D'
                                EMERAUDE , un lagon aux eaux cristallines , des
                                massifs coraux , banc de sables blancs . Les
                                délicieux et frais fruits de mer vous attend à
                                midi pendant l' excursion Baignade, plongée avec
                                masque et cuba
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i12} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 12{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ramena - 3 baies Baies of Sakalava , Pigeon,
                                Baies of Sakalava ideal for surfing
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visite des 3baies; le Baie de Sakalava, pigeon;
                                de dûnes Baie de Sakalava pour les funs de
                                sports , le surfs . Baies de dûnes et Pigeon
                                sont bordés des plages désertes
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i13} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 13{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ramena - Diego - Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Ramena Avion pour Antananarivo
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12" id="dernier-col">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i14} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 14{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Tour de ville à Antananarivo Transfert à l'
                                aéroport pour votre vol international
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Classical_north_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Classique_nord_content;