import React from 'react';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Rivertrip_tours_content_FR from '../../../../components/version_francaise/OuestFr/Rivertrip_tours/itineraire/Rivertrip_tours_content_FR';
import Footer_FR from '../../../../components/Footer_FR';

const Rivertrip_tour_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />
        <Rivertrip_tours_content_FR />
        <Footer_FR />
      </div>
    );
};

export default Rivertrip_tour_FR;