import React from "react";
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets//indri.jpg";
import i3 from "./../../../../../assets/andasibé.jpg";
import i4 from "./../../../../../assets/tamatave.jpg";
import i5 from "./../../../../../assets/mahambo.jpg";
import i6 from "./../../../../../assets/Paradis des baleines l ile sainte Marie Madagascar.jpeg";
import i7 from "./../../../../../assets/Tana.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Classical_map_east from "../../../../map_east/Classical_map_east";
import { Link } from "react-router-dom";
import Classical_east_cover from "../Classical_east_cover";

const Classical_east_content = () => {
  return (
    <div className="app-tours__content">
      <div className="app-contain">
        <div className="row g-4">
          <div className="col-12  col-lg-8">
            <div className="main-contain">
              <Classical_east_cover />

              <div className="tour-navigation">
                <div className="tour-navigation__content d-flex justify-content-center">
                  <h3 className="active">Itinerary</h3>
                  <h3>
                    {" "}
                    <Link
                      to="/tours/east-anglais/classical-east-gallery"
                      className="a"
                    >
                      Pictures
                    </Link>
                  </h3>
                </div>
                <div className="tour-navigation__line"></div>
              </div>

              <div className="itinerary">
                <div className="row gy-5" id="row-princip">
                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i1} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 1{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antananarivo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Assistance at the airport, and transfer to the
                              hotel. If you have time, going to the point of
                              view that you can see Antananarivo and his area
                              Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i2} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 2{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana- Andasibé
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Departure in the morning for Andasibe, going
                              through the green scenery. Stop at Madagascar
                              Exotic reserve, which is reputed for its
                              butterflies, frogs,chameleons, reptiles,
                              batrachians, and crocodiles. Night walk to
                              spotting the nocturnal lemurs known as the
                              smallest of the lemurs in the world. Overnight at
                              the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i3} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 3{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Andasibé
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit of Andasibe Park . You will have opportunity
                              to observe the INDRI-INDRI , the biggest lemur,
                              endemic to the island,Orchids, ferns as well as
                              birds. Visit of Vakona reserve which gives you
                              opportunity to see the lemurs jumping in your
                              shoulders Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i4} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 4{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Andasibé - Tamatave - Mahambo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Departure for Toamasina, through green hills and
                              mountains, composed of bambous, traveller’s trees
                              and palms. Stop to visit the coastal town of
                              Tamatave,which is also the biggest Port of
                              Madagascar, then continue to Mahambo , following
                              the East luxuriant coast of Madagascar. Overnight
                              at the hotel.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i5} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 5{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Mahambo - Sainte-Marie
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Mahambo , village of embarcader that you will take
                              boat to Sainte Marie
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i6} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 6, Day 7, Day 8{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Sainte-Marie
                            </h2>
                            <div className="lig"></div>
                            <p>Trip to Sainte-Marie</p>

                            <button className="btn btn-warning text-light">
                              Book Sainte-Marie Tours
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12" id="dernier-col">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i7} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 9{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Transfert to the airport for your international
                              flight
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
            <div className="main-secondary">
              <Classical_map_east />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classical_east_content;
