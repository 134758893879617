import React from 'react';
import Navbar_tours_FR from '../../../Navbar/Navbar_tours_FR';
import Activity_south_FR from '../../Activity_south_FR';
import Classical_map from "../../../../version_anglais/SudAng/map/Classical_map";
import { Link } from "react-router-dom";
import Footer_FR from '../../../../Footer_FR';
import Classical_south_cover_FR from '../Classical_south_cover_FR';

const Activity_classical_south_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />

        <div className="app-tours__content">
          <div className="app-contain">
            <div className="row g-4">
              <div className="col-12 col-lg-8">
                <div className="main-contain">
                  <Classical_south_cover_FR />

                  <div className="tour-navigation">
                    <div className="tour-navigation__content d-flex justify-content-center">
                      <h3>
                        <Link to="/FR/sud/Classique-sud-francais" className="a">
                          Itineraire
                        </Link>
                      </h3>
                      <h3 className="active">Activités</h3>
                      <h3>
                        <Link to="/FR/sud/Classique-sud-pictures" className="a">
                          Photos
                        </Link>
                      </h3>
                    </div>
                    <div className="tour-navigation__line"></div>
                  </div>

                  <Activity_south_FR />
                </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <Classical_map />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer_FR />
      </div>
    );
};

export default Activity_classical_south_FR;