import React from 'react';
import Navbar_FR from '../../../components/version_francaise/Navbar/Navbar_FR';
import Carousel_FR from '../../../components/version_francaise/Accueil/Carousel_FR';
import About_FR from '../../../components/version_francaise/Accueil/About_FR';
import Choice_FR from '../../../components/version_francaise/Accueil/Choice_FR';
import Travel_FR from '../../../components/version_francaise/Accueil/Travel_FR';
import Tours_FR from '../Tours_FR';
import Footer_FR from '../../../components/Footer_FR';
import Guestbook_FR from '../../../components/version_francaise/Accueil/Guestbook_FR';

const Accueil_FR = () => {
    return (
      <div className="accueil">
        <Navbar_FR/>
        <Carousel_FR/>
        <About_FR/>
        <Choice_FR/>
        <Travel_FR/>
        <Tours_FR/>
        <Guestbook_FR/>
        <Footer_FR/>
      </div>
    );
};

export default Accueil_FR;