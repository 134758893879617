import React from 'react';
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../../assets/alley.jpg";
import i4 from "./../../../../../assets/alle_baobab.jpg";
import i5 from "./../../../../../assets/fosa_.jpg";
import i6 from "./../../../../../assets/alley.jpg";
import i7 from "./../../../../../assets/alle_baobab.jpg";
import i8 from "./../../../../../assets/Tana.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Baobab_tour_map from '../../../../version_anglais/OuestAng/map/Baobab_tour_map';
import { Link } from "react-router-dom";
import Baobab_tours_cover_FR from '../Baobab_tours_cover_FR';



const Baobab_tours_content_FR = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <Baobab_tours_cover_FR />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itineraire</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/FR/ouest/Baobab-tours-francais-gallery"
                        className="a"
                      >
                        Photos
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>

                <div className="itinerary">
                  <div className="row gy-5" id="row-princip">
                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i1} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 1{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antananarivo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Acceuil à l' aéroport , Transfert à l' hôtel .
                                Mise au point pour le détail du voyage Si le
                                temps vous le permet , Vous pourriez admirer au
                                palais de la reine Antananarivo et ses environs
                                pour un point de vue magnifique , La plus haute
                                altitude à Antananarivo Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i2} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 2{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana- Antsirabé
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Antsirabe, à travers le paysages des
                                hauts plateaux, la maison argile traditionelle
                                de couleur rouge pour joindre Antsirabe qui
                                signifie là où il y a beaucoup de sel ,
                                Antsirabe la ville d'eau avec ses sources
                                thermales et aussi la capitale des pousses
                                pousses Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i3} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 3{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antsirabé - Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Morondava après le petit déjeuner
                                pour les beaux paysages des vallées et de
                                plaines Avant d' arriver à Morondava , vous
                                apercevrez votre premier baobab Nuit à l' hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i4} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 4{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visite du village de pêcheurs avec le pirogue
                                locale en passant à travers les mangroves ,
                                possibilité de continuer sur la mer de canal de
                                Mozambique Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i5} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 5{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava - Kirindy
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Kirindy réserve. Kirindy réserve pour
                                les , lémuriens, Fossa , oiseaux.Kirindy réserve
                                est idéale pour les ornithologues Visite
                                nocturne vous donne l' opportunité d' observer
                                le Rat Géant ainsi que le petit lémurien au
                                monde Madame Berthers Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i6} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 6{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                kirindy - Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Morondava en passant par le baobab
                                amoureux , le baobab sacrée Admirer l'
                                irrésisitible coucher de soleil sur l' avenue de
                                Baobab Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i7} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 7{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava - Tana en Avion
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Transfert à l' hôtel
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12" id="dernier-col">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i8} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 8{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Tour de ville , visite du lémur's park , le
                                palais du roi à Ambohimanga
                                <br /> Transfert à l' hotel pour votre vol
                                international
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Baobab_tour_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Baobab_tours_content_FR;