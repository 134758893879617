import React from 'react';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Rivertript_Bemaraha_content_FR from '../../../../components/version_francaise/OuestFr/Rivertrip_bemaraha/itineraire/Rivertript_Bemaraha_content_FR';
import Footer_FR from '../../../../components/Footer_FR';


const Rivertrip_Bemaraha_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />
        <Rivertript_Bemaraha_content_FR />
        <Footer_FR />
      </div>
    );
};

export default Rivertrip_Bemaraha_FR;