import React from 'react';
import Navbar from './version_anglais/Navbar/Navbar';
import Carousel from './version_anglais/Accueil/Carousel';
import About from './version_anglais/Accueil/About';

const Welcoming: React.FC = () => {
    return (
        <div className='Welcoming'>
               
        </div>
    );
};

export default Welcoming;