import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i1 from "./../../../assets/5-La-plage-dAnakao-à-Tuléar-1024x678.jpg";
import i2 from "./../../../assets/art-zafimaniry-sculpture.jpg";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Andringitra_map from '../../version_anglais/SudAng/map/Andringitra_map';


const Activity_south_FR = () => {
    return (
      <div className="activity">
        <div className="container">
          <div className="row gy-5" id="row-princip">
            <div className="col-12">
              <div className="row row-child" id="row-child">
                <div className="col-10  col-md-7 col-lg-5">
                  <div className="image">
                    <img src={i1} alt="" />
                  </div>
                </div>

                <div className="col-12 col-lg-7">
                  <div className="tour_details">
                    <div className="tour_details_title">
                      <h1>EXTENSION : Le meilleur Plage à Anakao</h1>
                      <h2>
                        <FontAwesomeIcon icon={faLocationDot} id="icon" />
                        Anakao
                      </h2>
                      <div className="lig"></div>
                      <div className="div">
                        <b>ACTIVITES à  ANAKAO:</b> <br />
                        <ul className="ul">
                          <li>Relax sur la plage </li>
                          <li>plongée sous marine</li>
                          <li>
                            Excursion avec la pirogue locale pour voir les
                            corails
                          </li>
                          <li>
                            Excursion avec la pirogue locale à Nosy Be pour
                            l'oiseaux "Paille en Queue" idéal pour LES
                            ORNITHOLOGUES
                          </li>
                          <li>Safari baleine de Juillet à Septembre</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12" id="dernier_col">
              <div className="row row-child" id="row-child">
                <div className="col-10  col-md-7 col-lg-5">
                  <div className="image">
                    <img src={i2} alt="" />
                  </div>
                </div>

                <div className="col-12 col-lg-7">
                  <div className="tour_details">
                    <div className="tour_details_title">
                      <h1>EXTENSION: Le Village Zafimaniry à Antoetra</h1>
                      <h2>
                        <FontAwesomeIcon icon={faLocationDot} id="icon" />
                        Antoetra
                      </h2>
                      <div className="lig"></div>
                      <div className="div">
                        <ul className="ul">
                          <li>
                            ANTOETRA se situe à 45km d' Ambositra, Le village où
                            habitent les Zafimaniry, Les ZAFIMANIRY reputés pour
                            l' habilité de la sculpture et marquetterie en bois
                            avec la main, presque toute les familles y vivent
                            come revenus. Presque toutes les maisons sont
                            construits avec du bois, les portes, fenêtres, les
                            tables, les ustensils, les objets du quotidien sont
                            sculptés avec des écritures géométriques , la raison
                            pour laquelle reconnu par l' Unesco . TREKKING à
                            Ifasina pour la découverte du village Zafimaniry,
                            les magnifiques paysages des rizières en térasses
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Activity_south_FR;