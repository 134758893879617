import React from "react";
import Tsingy_contents_FR from "../../../../components/version_francaise/OuestFr/Tsingy_tour/itineraire/Tsingy_contents_FR";
import Navbar_tours_FR from "../../../../components/version_francaise/Navbar/Navbar_tours_FR";
import Footer_FR from "../../../../components/Footer_FR";

const Tsingy_tour_FR = () => {
  return (
    <div className="app-tours">
      <Navbar_tours_FR />
      <Tsingy_contents_FR />
      <Footer_FR />
    </div>
  );
};

export default Tsingy_tour_FR;
