import React from 'react';
import Zoom from "react-reveal/Zoom";
import weast from "./../../../assets/ouest-.jpg";
import i1 from "./../../../assets/tsiribihina.jpg";
import i2 from "./../../../assets/tsingy2.jpg";
import i3 from "./../../../assets/fosa_.jpg";
import i4 from "./../../../assets/tsingy1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from '../../../components/version_anglais/Footer/Footer';
import Navbar_tours from "./../../../components/version_anglais/Navbar/Navbar_tours";

const Weast_welcome: React.FC = () => {
    return (
      <div className="weast_welcome">
        <Navbar_tours />

        <div className="Weast_welcome_content">
          <div className="row" id="couv">
            <div className="col-12">
              <div className="image">
                <img src={weast} alt="" className="w-100" />
              </div>

              <div className="col-12">
                <div className="title text-center">
                  <Zoom>  
                    <h2>Weast Tour</h2>
                    <h1>Madagascar Holidays for the Famous Alley of Baobab</h1>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="desc">
            <div className="col-12">
              <div className="title">
                <h1> Weast Trip details</h1>
                <div className="lig"></div>
              </div>

              <div className="desc">
                <p>
                  WEST TOUR is for the people who loves adventures , natures
                  ,and specially the birds. A paradise of the birds. You might
                  discover the originality and beauty of the scenery among the
                  Tsiribihina river with local’s pirogue,or boat motor”s . The
                  gorge of Bemaraha , and also the Tsingy of Bemaraha known as
                  ‘the forest of the stones” , an insolate site formed with
                  calcareous pics .The famous and unique Baobab Alley .The
                  famous sunset in the baobab alley without forgetting the Fossa
                  in freddom in Kirindy reserve . The ambiance during the
                  camping with fire , meeting with a local people always smiling
                  , a life so far from the technology gives you an unforgettable
                  memory
                </p>
              </div>
            </div>
          </div>

          <div className="row" id="tours">
            <div className="row" id="row1">
              <div className="col-12">
                <div className="title">
                  <h1> Avalaible tours in the Weast</h1>
                  <div className="lig"></div>
                </div>
              </div>
            </div>

            <div className="tour-list">
              <div className="contain1">
                <div className="row  gy-5 gy-lg-4 text-center gx-4" id="row2">
                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i1} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 9 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>Rivertrip in Tsiribihina River Cruise</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                             
                          </p> */}
                        </div>

                        <div className="desc mt-3">
                          <a href="/tours/weast-anglais/RiverTrip-tours-anglais">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

               

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i2} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 12 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>Rivertrip in Tsiribihina and tsingy BEMARAHA</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/weast-anglais/RiverTrip-Bemaraha-anglais">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i3} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 8 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>Baobab Tour and Kirindy</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/weast-anglais/Baobab-weast-anglais">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i4} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 8 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1> Tsingy Tour</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/weast-anglais/Tsingy-weast-anglais">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
};

export default Weast_welcome;