import React, { useState } from "react";
import travel_1 from "./../../../assets/SIFAKA.jpg";
import travel_2 from "./../../../assets/famadihana.jpg";
import travel_3 from "./../../../assets/5-La-plage-dAnakao-à-Tuléar-1024x678.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useApp } from "../../../AppContext";


const Travel_FR : React.FC = () => {
    const {scrollToTour} =useApp();

    const [up, setUp] = useState<boolean>(false);
    const [up1, setUp1] = useState<boolean>(false);
    const [up2, setUp2] = useState<boolean>(false);
  
    const MouseHover = () => {
      setUp(true);
    };
    const MouseHover1 = () => {
      setUp1(true);
    };
    const MouseHover2 = () => {
      setUp2(true);
    };
  
    const MouseOut = () => {
      setUp(false);
    };
    const MouseOut1 = () => {
      setUp1(false);
    };
    const MouseOut2 = () => {
      setUp2(false);
    };
  
    return (
      <div className="travel">
        <div className="row text-center" id="row1">
          <div className="col-12">
            <div className="title">
              <h2>La grand île</h2>
              <h1>Que voir à Madagascar ?</h1>
            </div>
          </div>
        </div>

        <div className="row gx-2 gy-5 gy-lg-0 text-center" id="row2">
          <div className="col-12 col-lg-4 col-md-6">
            <div className="box">
              <div className="box_image">
                <img src={travel_1} alt="" />
              </div>

              <div
                className={`box_content ${up ? "open" : "close"}`}
                onMouseEnter={MouseHover}
                onMouseLeave={MouseOut}
              >
                <div className="title">
                  <h1>Des espèces Endemiques</h1>
                  <div className="lig"></div>
                  <div className="toogle">
                    <FontAwesomeIcon icon={faSortDown} id="icon" />
                  </div>
                </div>

                <div className="desc">
                  <p>
                  Vous découvrirez plusieurs espèces comme les lémuriens SIFAKA
                  </p>
                  <button className="btn" onClick={scrollToTour}>
                    Découvrez nos circuits{" "}
                    <FontAwesomeIcon icon={faArrowRight} id="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-md-6">
            <div className="box">
              <div className="box_image">
                <img src={travel_2} alt="" />
              </div>

              <div
                className={`box_content ${up1 ? "open" : "close"}`}
                onMouseEnter={MouseHover1}
                onMouseLeave={MouseOut1}
              >
                <div className="title">
                  <h1>Cultures et traditions</h1>
                  <div className="lig"></div>
                  <div className="toogle">
                    <FontAwesomeIcon icon={faSortDown} id="icon" />
                  </div>
                </div>

                <div className="desc">
                  <p>
                     Découvrez les cultures et traditions uniques de la Grande île
                  </p>
                  <button className="btn" onClick={scrollToTour}>
                    Découvrez nos circuits{" "}
                    <FontAwesomeIcon icon={faArrowRight} id="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 col-md-6">
            <div className="box">
              <div className="box_image">
                <img src={travel_3} alt="" />
              </div>

              <div
                className={`box_content ${up2 ? "open" : "close"}`}
                onMouseEnter={MouseHover2}
                onMouseLeave={MouseOut2}
              >
                <div className="title">
                  <h1>De splendides plages</h1>
                  <div className="lig"></div>
                  <div className="toogle">
                    <FontAwesomeIcon icon={faSortDown} id="icon" />
                  </div>
                </div>

                <div className="desc">
                  <p>
                    Resérver votre voyage pour des plages splendides comme à Nosy-Be
                  </p>
                  <button className="btn" onClick={scrollToTour}>
                    Découvrez nos circuits{" "}
                    <FontAwesomeIcon icon={faArrowRight} id="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Travel_FR;