import React from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
} from "react-leaflet";

type Position = [number, number];

const Adventures_tours_map: React.FC = () => {
  const initialPosition: Position = [-21.417, 44.316] ;

  const coordinates: { name: string; position: Position }[] = [
    { name: "Antananarivo", position: [-18.8792, 47.5079] },
    { name: "Antsirabé", position: [-19.8642, 47.0389] },
    { name: "Morondava", position: [-20.2847, 44.3176] },
    { name: "Belo sur Mer", position: [-20.8, 44.5333] },
    { name: "Manja", position: [-21.417, 44.316] },
    { name: "Morombe", position: [-21.7532, 43.3844] },
    { name: "Andavadaoka", position: [-22.5, 44.5] },
    { name: "Salary", position: [-22.75, 43.6667] },
    { name: "Ifaty", position: [-23.1334, 43.6375] },
    { name: "Tuléar", position: [-23.3437, 43.6856] },
    { name: "Isalo", position: [-22.7333, 45.35] },
    { name: "Ambalavao", position: [-21.8498, 46.9383] },
    { name: "Ranomafana", position: [-21.2555, 47.4241] },
    { name: "Ambositra", position: [-20.5283, 47.4054] },
    { name: "Antsirabé", position: [-19.8642, 47.0389] }
  ];

  return (
    <div className="map">
      <MapContainer
        center={initialPosition}
        zoom={6}
        scrollWheelZoom={false}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {coordinates.map((city) => (
          <Marker key={city.name} position={city.position}>
            <Popup>{city.name}</Popup>
          </Marker>
        ))}

        <Polyline
          positions={coordinates.map((city) => city.position)}
          color="blue"
          weight={3}
          opacity={0.7}
          dashArray="5, 10"
        />
      </MapContainer>
    </div>
  );
};

export default Adventures_tours_map;
