import React from "react";
import Navbar_tours from "../../../Navbar/Navbar_tours";
import Activity_South_components from "../../../../south/Activity_South_components";
import Combined_map from "../../map/Combined_map";
import { Link } from "react-router-dom";
import Footer from "../../../Footer/Footer";
import Combined_tour_cover from '../Combined_tour_cover';

const Activity_South_Combined = () => {
  return (
    <div className="app-tours">
      <Navbar_tours />

      <div className="app-tours__content">
        <div className="app-contain">
          <div className="contain">
            <div className="row g-4">
              <div className="col-12 col-lg-8">
                <div className="main-contain">
                  
                  <Combined_tour_cover />

                  <div className="tour-navigation">
                    <div className="tour-navigation__content d-flex justify-content-center">
                      <h3>
                        <Link
                          to="/tours/south-anglais/combined_tour_south"
                          className="a"
                        >
                          Itinerary
                        </Link>
                      </h3>
                      <h3 className="active">Activity</h3>
                      <h3>
                        <Link
                          to="/tours/south-anglais/combined-gallery"
                          className="a"
                        >
                          Pictures
                        </Link>
                      </h3>
                    </div>
                    <div className="tour-navigation__line"></div>
                  </div>

                  <Activity_South_components />
                </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <Combined_map />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Activity_South_Combined;
