import React from 'react';
import Navbar_tours_FR from '../../../Navbar/Navbar_tours_FR';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import south from "./../../assets/Parc-national-dAndringitra.webp";
import Activity_south_FR from '../../Activity_south_FR';
import {
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Andringitra_map from "../../../../version_anglais/SudAng/map/Andringitra_map";
import { Link } from "react-router-dom";
import Footer_FR from '../../../../Footer_FR';
import Andringira_cover_FR from '../Andringira_cover_FR';


const Activity_andringitra_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />

        <div className="app-tours__content">
          <div className="app-contain">
            <div className="row g-4">
              <div className="col-12  col-lg-8">
                <div className="main-contain">
                  <Andringira_cover_FR />

                  <div className="tour-navigation">
                    <div className="tour-navigation__content d-flex justify-content-center">
                      <h3>
                        <Link to="/FR/sud/Andringitra-sud-FR" className="a">
                          Itineraire
                        </Link>
                      </h3>
                      <h3 className="active">Activités</h3>
                      <h3>
                        <Link to="/FR/sud/Andringitra-gallery-FR" className="a">
                          Photos
                        </Link>
                      </h3>
                    </div>
                    <div className="tour-navigation__line"></div>
                  </div>
                  <Activity_south_FR />
                </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <Andringitra_map />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer_FR />
      </div>
    );
};

export default Activity_andringitra_FR;