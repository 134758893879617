import React from "react";
import Navbar_tours from "../../../Navbar/Navbar_tours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import south from "./../../../../../assets/fenetre.jpg";
import Activity_South_components from "../../../../south/Activity_South_components";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Classical_map from "../../map/Classical_map";
import Andringitra_south_content from "../../Andringitra_tours/itineraire/Andringitra_south_content";
import { Link } from "react-router-dom";
import Footer from "../../../Footer/Footer";
import South_tour_cover from "../South_tour_cover";

const Activity_South_classical = () => {
  return (
    <div className="app-tours">
      <Navbar_tours />

      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12 col-lg-8">
              <div className="main-contain">

                <South_tour_cover />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3>
                      <Link
                        to="/tours/south-anglais/classical_south"
                        className="a"
                      >
                        Itinerary
                      </Link>
                    </h3>
                    <h3 className="active">Activity</h3>
                    <h3>
                      <Link
                        to="/tours/south-anglais/classical-gallery"
                        className="a"
                      >
                        Pictures
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>
                
                <Activity_South_components />
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Classical_map />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Activity_South_classical;
