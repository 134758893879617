import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Couv from "./../../assets/tsingy2.jpg";
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../../assets/miandrivazo.jpg";
import i4 from "./../../../../../assets/fleuve1.jpg";
import i5 from "./../../../../../assets/tsiribihina (1).jpg";
import i6 from "./../../../../../assets/belo_tsiribihina.jpg";
import i7 from "./../../../../../assets/bekopaka.jpg";
import i8 from "./../../../../../assets/tsingy1.jpg";
import i9 from "./../../../../../assets/alley.jpg";
import i10 from "./../../../../../assets/alle_baobab.jpg";
import i11 from "./../../../../../assets/alle_baobab.jpg";
import i12 from "./../../../../../assets/Tana.jpg";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Rivertrip_Bemaraha_map from '../../../../version_anglais/OuestAng/map/Rivertrip_Bemaraha_map';
import { Link } from "react-router-dom";
import Rivertrip_bemaraha_cover from '../../../../version_anglais/OuestAng/Rivertrip_bemaraha/Rivertrip_bemaraha_cover';
import Rivertrip_bemaraha_cover_FR from '../Rivertrip_bemaraha_cover_FR';


const Rivertript_Bemaraha_content_FR = () => {
  return (
    <div className="app-tours__content">
      <div className="app-contain">
        <div className="row g-4">
          <div className="col-12  col-lg-8">
            <div className="main-contain">
              <Rivertrip_bemaraha_cover_FR />

              <div className="tour-navigation">
                <div className="tour-navigation__content d-flex justify-content-center">
                  <h3 className="active">Itineraire</h3>
                  <h3>
                    {" "}
                    <Link
                      to="/FR/ouest/Rivertrip-bemaraha-gallery-FR"
                      className="a"
                    >
                      Photos
                    </Link>
                  </h3>
                </div>
                <div className="tour-navigation__line"></div>
              </div>

              <div className="itinerary">
                      <div className="row gy-5" id="row-princip">
                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i1} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 1{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    Antananarivo
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Acceuil à l' aéroport , Transfert à l'
                                    hôtel . Mise au point pour le détail du
                                    voyage Si le temps vous le permet , Vous
                                    pourriez admirer au palais de la reine
                                    Antananarivo et ses environs pour un point
                                    de vue magnifique , La plus haute altitude
                                    à Antananarivo
                                    <br /> Nuit à l'hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i2} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 2{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    Tana- Antsirabé
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Route pour Antsirabe, à travers le
                                    paysages des hauts plateaux, la maison
                                    argile traditionelle de couleur rouge pour
                                    joindre Antsirabe qui signifie là où il y
                                    a beaucoup de sel , Antsirabe la ville
                                    d'eau avec ses sources thermales et aussi
                                    la capitale des pousses pousses
                                    <br /> Nuit à l' hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i3} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 3{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    Antsirabé - Miandrivazo
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Route pour Miandrivazo à travers les
                                    paysages de montagnes Déjeuner locale
                                    <br /> Nuitée à l' hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i4} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 4{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    DEBUT DE L' AVENTURE sur LE FLEUVE DE
                                    TSIRIBIHINA
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Début de l' aventure sur le fleuve de
                                    Tsiribihina après le petit déjeuner, Toute
                                    la journée sur la pirogue ou bateau à
                                    moteur , La fleuve de Tsiribihina est le
                                    meilleur place pour apprécier la nature,
                                    Avec le fôret encore primaire,la
                                    tranquilité , le sable blanc le long du
                                    fleuve, le dinner sous le beau ciel ornés
                                    des étoiles rendent spécial et éxcitant l'
                                    aventure sur le fleuve de Tsiribihina
                                    <br /> Nuit sous tente
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i5} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 5{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    GORGE de TSIRIBIHINA - CASCADE et PISCINE
                                    NATURELLE
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Continuation de l' aventure après le petit
                                    déjeuner à travers le gorge de Tsiribihina
                                    où vous aurez la possibilité d' aperçevoir
                                    les lémuriens tels que le Sifaka, Lémur
                                    fulvus; les chaméleons, les crocodiles
                                    Baignade au piscine naturelle et le beaux
                                    cascade d' Anosiampela Dinner sous le
                                    beaux ciel ornés des étoiles Nuit sous
                                    tente
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i6} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 6{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    End of the river – ANTSIRARAKY- BELO sur
                                    TSIRIBIHINA
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Continuation aprés le petit déjeuner, à
                                    travers les falaises l' habitation des
                                    chauves souris, les oiseaux comme les
                                    hibous.
                                    <br /> Nuit à l'hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i7} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 7{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    BELO sur TSIRIBIHINA - Bekopaka
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Departure to Bekopaka by 4x 4 car, lunch
                                    at Belo/Tsiribihina, Crossing by ferry the
                                    2 rivers (Tsiribihina and Manambolo),
                                    Passing through the dry landscape with a
                                    sandy and bumpy road Overnight at the
                                    hotelDépart avec la voiture 4X4 après le
                                    petit déjeuner à travers le paysage aride
                                    avec la route houleux Traversés du bac
                                    pour Bekopaka
                                    <br /> Nuit à l'hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i8} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 8{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    TSINGY DE BEMARAHA
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Départ avec le 4X4 pour joindre l' entrée
                                    du parc, Visite du Tsingy pendant 3
                                    jusqu'à 8h dépendent de votre condition
                                    physique. La visite du Tsingy vous donne
                                    la possibilité de voir les différents
                                    éspèces de lémuriens, des oiseaux, des
                                    reptiles Une forte émotion lors du passage
                                    sur le pont d'Andamozavaky que vous n'
                                    oublierez jamais .
                                    <br /> Nuit à l' hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i9} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 9{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    Bekopaka - Morondava
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Route pour le retour à Morondava avec la
                                    route houleux Travérsée de 2 bacs, le bacs
                                    de Bekopaka et Belo Déjeuner à Belo sur
                                    Tsiribihina Stop pour le baobab sacré, le
                                    baobab amoureux, L'irrésisitible coucher
                                    de baobab sur l' avenue de Baobab
                                    <br /> Nuit à l' hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i10} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 10{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    Morondava
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Route pour le retour à Morondava avec la
                                    route houleux Travérsée de 2 bacs, le bacs
                                    de Bekopaka et Belo Déjeuner à Belo sur
                                    Tsiribihina Stop pour le baobab sacré, le
                                    baobab amoureux, L'irrésisitible coucher
                                    de baobab sur l' avenue de Baobab
                                    <br /> Nuit à l' hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i11} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 11{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    Morondava - Tana en avion
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    Transfert à l' aéroport et à l' hotel
                                    <br /> Nuit à l' hôtel
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12" id="dernier-col">
                          <div className="row row-child" id="row-child">
                            <div className="col-10 col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i12} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon
                                      icon={faCalendarDay}
                                      id="icon"
                                    />{" "}
                                    Jour 12{" "}
                                  </h1>
                                  <h2>
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      id="icon"
                                    />
                                    Tana
                                  </h2>
                                  <div className="lig"></div>
                                  <p>
                                    our de ville à Antananarivo , visite du
                                    lemur"s park ; le palais d' Ambohimanga
                                    <br />Transfert à l' aéroport pour votre vol
                                    international
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

            </div>
          </div>

          <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
            <div className="main-secondary">
              <Rivertrip_Bemaraha_map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rivertript_Bemaraha_content_FR;