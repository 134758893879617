import React from 'react';
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/akarafantsika.jpg";
import i3 from "./../../../../../assets/ankarafantsika.jpg";
import i4 from "./../../../../../assets/antsohihy.jpg";
import i5 from "./../../../../../assets/ambanja.jpg";
import i6 from "./../../../../../assets/Ankarana2.jpg";
import i7 from "./../../../../../assets/tsingyankaranan.jpg";
import i8 from "./../../../../../assets/tsingyrouge-900x525.jpg";
import i9 from "./../../../../../assets/ankify.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Tour_Nosy_Be_map from '../../map/Tour_Nosy_Be_map';
import { Link } from "react-router-dom";
import Tour_nosyBe_cover from '../Tour_nosyBe_cover';

const Tour_NosyBe_content: React.FC = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                
                <Tour_nosyBe_cover />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itinerary</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/tours/north-anglais/north-tour-with-nosyBe-gallery"
                        className="a"
                      >
                        Pictures
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>
                
                <div className="itinerary">
                  <div className="row gy-5" id="row-princip">
                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i1} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 1{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antananarivo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Assistance at the airport, and transfer to the
                                hotel. If you have time, going to the point of
                                view that you can see Antananarivo and his area
                                <br /> Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i2} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 2{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana - Ankarafantsika
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i3} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 3{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarafantsika
                              </h2>
                              <div className="lig"></div>
                              <p>
                                like Coquerel's Sifaka, Brown lemur, Milne
                                Edwards Sportive lemurs if you're lucky. The
                                bird, Ankarafantsika National Park is home of
                                bird , BAOBAB, medicinal plants
                                <br /> Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i4} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 4{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarafantsika - Port Berger - Antsohihy
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Road to Antsohihy through the local people in
                                the North part of Madagascar
                                <br /> Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i5} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 5{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antsohihy - Ambanja
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visit ot 1h for the reserv lead by the local
                                people for the BLACK INDRI in wild.
                                <br /> Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i6} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 6{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ambanja - Ankarana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visit of MILLOT PLANTATION in Ambanja for the
                                plantation of cacao, vanilla, Ilang Ilang ,
                                before road to Ankarana .Millot plantation show
                                you how to plant cocoa until the packing of
                                exportation ,
                                <br /> Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i7} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 7{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visit of ANKARANA NATIONAL PARK for the TSINGY
                                d'ANKARANA , Tsingy formed by llimestones
                                similar in Tsingy of Bemaraha .The lemurs,
                                birds, The sacred bat's cave, Baobab
                                <br /> Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i8} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 8{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ankarana - Red Tsingy- Ambanja
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Road to Ambanja ; before road to Ambanja ;you
                                will drive to Red Tsingy , Red Tsingy , eroded
                                red soil formed by laterite formation with his
                                amazing colours red .
                                <br /> Overnight at the hotel .
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12" id="dernier-col">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i9} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 9{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ambanja - ANKIFY- Nosy-Be
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Road to Ankify , embarcader , the place that you
                                will take boat speed to Nosy Be . Boat speed to
                                Nosy Be for 2hours .
                                <br /> Overnight at the hotel
                              </p>

                              <button className="btn btn-warning text-light">
                                Book Nosy-Be Tours
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Tour_Nosy_Be_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Tour_NosyBe_content;