import React from 'react';
import Navbar_tours_FR from '../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Zoom from "react-reveal/Zoom";
import Couv from "./../.././../assets/couvertureNord.jpg";
import i1 from "./../../../assets/tsingyrouge-900x525.jpg";
import i2 from "./../../../assets/nosy-Be.jpg";
import i3 from "./../../../assets/nosy-iranja.jpg";


import aventures from "./../assets/Baobabs-andavadaoka.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Footer_FR from '../../../components/Footer_FR';


const Nord_welcome = () => {
    return (
      <div className="north_welcome">
        <Navbar_tours_FR />

        <div className="North_welcome_content">
          <div className="row" id="couv">
            <div className="col-12">
              <div className="image">
                <img src={Couv} alt="" className="w-100" />
              </div>

              <div className="col-12">
                <div className="title text-center">
                  <Zoom>
                    <h2>Tour Nord</h2>
                    <h1>Visiter Madagascar  pour le BROOKESIA MINIMA </h1>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="desc">
            <div className="col-12">
              <div className="title">
                <h1> Détails du circuit</h1>
                <div className="lig"></div>
              </div>

              <div className="desc">
                <p>
                  Le Circuit NORD renferme des surprises de découvrir en une
                  place tous les beautés de Madagascar avec la plus belle plage
                  au monde. Avec ses diversifications , endémiques faunes et
                  flores, qui existent seulement à Madagascar, comme les plantes
                  médicinales , les baobabs, les oiseaux, les lémuriens, le
                  chaméléon dont le BROOKESIA MINIMA le plus petit chaméleon au
                  monde qui mesure moin d'une pouce . le TSINGY à Ankarana . le
                  TSINGY ROUGE , LE CIRCUIT NORD vous offre aussi la belle plage
                  le Mer d' Eméraude un lagon aux eaux cristallines, des massifs
                  de coraux aux poissons multicolores, , les 3 baies ( baies de
                  Sakalava, baie de pigeon, baie dunes) . LE CIRCUIT NORD vous
                  fais découvrir aussi les meilleurs plages de Madagascar à NOSY
                  BE , avec ses bancs de sables blancs,eau turquoise, C'est
                  juste à côté du paradis . LE SAFARI de BALEINE au mois de
                  juillet jusqu' à novembre .
                </p>
              </div>
            </div>
          </div>

          <div className="row" id="tours">
            <div className="row" id="row1">
              <div className="col-12">
                <div className="title">
                  <h1> Circuits disponibles pour le Nord</h1>
                  <div className="lig"></div>
                </div>
              </div>
            </div>

            <div className="tour-list">
              <div className="contain1">
                <div className="row  gy-5 gy-lg-0 text-center gx-4" id="row2">
                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i1} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 14 Jours
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>Classique Circuit Nord</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/nord/Classique-nord-FR">
                            <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i2} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 9 Jours
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1> Circuit Classique Nord avec Nosy-Be</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/nord/Tour-nosyBE">
                            <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i3} alt="" />
                      </div>

                      <div className="box-content" id="nosy-be">
                        <div className="title1">
                          <h1>Nosy-Be Hell-Ville</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/nord/nosy-Be-FR">
                            <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer_FR />
      </div>
    );
};

export default Nord_welcome;