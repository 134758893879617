import React from 'react';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Couv from "./../../../../assets/nosy-iranja.jpg";
import i1 from "./../../../../assets/couv_nosy-Be.webp";
import i2 from "./../../../../assets/nosy-tanikely-1.jpg";
import i3 from "./../../../../assets/SAINTE MARIE2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faLocationDot,
    faCalendarDay,
    faCircle
  } from "@fortawesome/free-solid-svg-icons";
  import { Link } from "react-router-dom";
  import NosyBe_map from '../../../../components/version_anglais/nordAng/map/NosyBe_map';
import Footer_FR from '../../../../components/Footer_FR';


const Nosy_Be_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />

        <div className="app-tours__content">
          <div className="app-contain">
            <div className="row g-4">
              <div className="col-12  col-lg-8">
                <div className="main-contain">
                  <div className="tour-cover">
                    <div
                      className="tour-coverpictures"
                      style={{
                        backgroundImage: `url(${Couv})`,
                      }}
                    >
                      <div className="tour-cover__header">
                        <div className="title">
                          <div className="title_1">
                            <h1>Nosy-Be Hell-Ville</h1>
                          </div>

                          <div className="title_2 mt-3 d-flex">
                            <div className="duration">
                              <h1>
                                <FontAwesomeIcon icon={faClock} id="icon" />
                                {/* 16
                              Days */}
                              </h1>
                            </div>

                            <div className="location">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />{" "}
                                Sainte-marie
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="activity">
                    <div className="container">
                      <div className="row gy-5" id="row-princip">
                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10  col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i1} alt="" />
                              </div>
                              <div className="image mt-4">
                                <img src={i2} alt="" />
                              </div>

                              <div className="image mt-4">
                                <img src={i3} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7 mt-3 mt-lg-0">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon icon={faCircle} fade />{" "}
                                    Nosy-Be 's Description{" "}
                                  </h1>
                                  <p className="p">
                                    NOSY BE ,une splendide île à Madagascar est
                                    un des joyaux du littoral malgache pour les
                                    plages avec ses sables fins blancs, ses eaux
                                    turquoises avec ses déliceux fruits de mer .
                                    Comme si vous étes au paradis sur terre .
                                    Les lémuriens , les tortues marines, les
                                    baleines en mois de juillet jusqu' à
                                    Novembre vous émerveillent à Nosy Be
                                  </p>
                                  <div className="lig"></div>
                                  <div className="div">
                                    <b>Activités possible in Nosy-Be:</b> <br />
                                    <ul className="ul">
                                      <li>
                                        <b>Nosy Komba </b>
                                        <br /> est une île volcanique , la
                                        visite de Nosy Komba vous offre
                                        l'opportunité de voir les lémuriens
                                        MAKAKO ; Avec ses plages de sables fins
                                        , possibilité de se baigner .
                                      </li>
                                      <li>
                                        <b>Nosy Sakatia </b>
                                        <br /> Pour la plongée et le baignade
                                      </li>
                                      <li>
                                        <b>Nosy Iranja</b>
                                        <br />
                                        Le meilleur plage , Répuée pour ses 2
                                        îles Nosy Iranja Be et Nosy Iranja Kely
                                        reliées par une banc de sable accessible
                                        à pied en marée basse Belle plage avec
                                        ses sables fins, aux eaux turquoises et
                                        cristallines , baignade
                                      </li>
                                      <li>
                                        <b>Nosy Tanikely </b>
                                        <br />
                                        Nosy Tanikely est un parc marin, situé
                                        au Sude de Nosy Be, protégée par des
                                        corails naturels .C' est aussi un
                                        véritable aquarium avec ses
                                        exceptionnels tortues marines, myriades
                                        de poissons multicolores, Superbe
                                        plongée , baignade
                                      </li>
                                      <li>
                                        <b>Andilana Beach </b>
                                        <br /> Plus belle plage avec le long
                                        ruban de sable blanc
                                      </li>
                                      <li>
                                        <b>WhaleWatching </b>
                                        <br />
                                        de juillet en novembre
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <NosyBe_map />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer_FR />
      </div>
    );
};

export default Nosy_Be_FR;