import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import GuestbookData from "../../../Data/Guestbook/GuestbookData";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Guestbook_FR: React.FC = () => {
  const { testimonialData } = GuestbookData();

  return (
    <div className="guestbook" id="guestbook">
      <div className="row text-center" id="row1">
        <div className="col-12">
          <div className="title">
            <h2>Guestbook</h2>
            <h1>Avis de quelques clients</h1>
          </div>
        </div>
      </div>

      <div className="row" id="row2">
        <div className="swiper-container">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay
            modules={[Pagination]}
            className="mySwiper"
            autoHeight
          >
            {testimonialData.map((item) => (
              <SwiperSlide>
                <div className="AboutCustomer">
                  <h1>{item.name}</h1>
                  <p>{item.date}</p>
                </div>

                <div className="TestimonialCustomer">
                  <div className="quote">
                    <FontAwesomeIcon icon={faQuoteLeft} id="icon" />
                  </div>
                  <div className="content">
                    <p>{item.content}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Guestbook_FR;
