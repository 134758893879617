import React from 'react';
import Tours_content from '../../../components/version_anglais/Accueil/Tours_content';


const Tours = () => {
    
    return (
        <div className='tours'>
              <Tours_content/>
        </div>
    );
};

export default Tours;