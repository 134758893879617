import React from 'react';
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../../assets/alley.jpg";
import i4 from "./../../../../../assets/bekopaka.jpg";
import i5 from "./../../../../../assets/tsingy1.jpg";
import i6 from "./../../../../../assets/alley.jpg";
import i7 from "./../../../../../assets/alle_baobab.jpg";
import i8 from "./../../../../../assets/alle_baobab.jpg";
import i9 from "./../../../../../assets/Tana.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Tsingy_tour_map from '../../../../version_anglais/OuestAng/map/Tsingy_tour_map';
import { Link } from "react-router-dom";
import Tsingy_tour_cover_FR from '../Tsingy_tour_cover_FR';


const Tsingy_contents_FR = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <Tsingy_tour_cover_FR />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itineraire</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/FR/ouest/Tsingy-tours-francais-gallery"
                        className="a"
                      >
                        Photos
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>

                <div className="itinerary">
                  <div className="row gy-5" id="row-princip">
                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i1} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 1{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antananarivo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Acceuil à l' aéroport , Transfert à l' hôtel .
                                Mise au point pour le détail du voyage Si le
                                temps vous le permet , Vous pourriez admirer au
                                palais de la reine Antananarivo et ses environs
                                pour un point de vue magnifique , La plus haute
                                altitude à Antananarivo
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i2} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 2{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana- Antsirabé
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Antsirabe, à travers le paysages des
                                hauts plateaux, la maison argile traditionelle
                                de couleur rouge pour joindre Antsirabe qui
                                signifie là où il y a beaucoup de sel ,
                                Antsirabe la ville d'eau avec ses sources
                                thermales et aussi la capitale des pousses
                                pousses
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i3} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 3{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antsirabé - Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Morondava à travers les magnifiques
                                paysages de montagnes Déjeuner à Miandrivazo
                                Votre premier baobab vous attend avant d' entrée
                                à Morondava
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i4} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 4{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava - Bekopaka
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour Bekopaka pour la piste houleuse
                                Déjeuner à Belo sur Tsiribihina Travérsée des 2
                                bacs de Belo sur Tsiribihina et Bekopaka
                                <br /> Nuit à l' hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i5} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 5{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Visite du Tsingy de Bemaraha
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Départ avec le 4X4 pour joindre l' entrée du
                                parc, Visite du Tsingy pendant 3 jusqu'à 8h
                                dépendent de votre condition physique. La visite
                                du Tsingy vous donne la possibilité de voir les
                                différents éspèces de lémuriens, des oiseaux,
                                des reptiles Une forte émotion lors du passage
                                sur le pont d'Andamozavaky que vous n' oublierez
                                jamais .
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i6} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 6{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Bekopaka - Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Route pour le retour à Morondava avec la route
                                houleux Travérsée de 2 bacs, le bacs de Bekopaka
                                et Belo Déjeuner à Belo sur Tsiribihina Stop
                                pour le baobab sacré, le baobab amoureux,
                                L'irrésisitible coucher de baobab sur l' avenue
                                de Baobab
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i7} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 7{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Relax sur la plage après la mauvaise route;
                                Visite du village des pécheurs à travers le
                                mangrove avec la pirogue locale
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i8} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 8{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava - Tana en Avion
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Transfert à l' aéroport
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12" id="dernier-col">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i9} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 9{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Transfert à l' aéroport et à l' hotel
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Tsingy_tour_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Tsingy_contents_FR;