import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Couv from "./../../../../assets/SIFAKA.jpg";
import {
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

const Classical_east_cover: React.FC = () => {
    return (
      <div className="tour-cover">
        <div
          className="tour-coverpictures"
          style={{
            backgroundImage: `url(${Couv})`,
          }}
        >
          <div className="tour-cover__header">

              <div className="title">
                <div className="title_1">
                  <h1>Classical East Tour</h1>
                </div>

                <div className="title_2 mt-3 d-flex">
                  <div className="duration">
                    <h1>
                      <FontAwesomeIcon icon={faClock} id="icon" /> 9 Days
                    </h1>
                  </div>

                  <div className="location">
                    <h1>
                      <FontAwesomeIcon icon={faLocationDot} id="icon" /> Tana - SainteMarie
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Classical_east_cover;