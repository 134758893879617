import React from 'react';
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets//indri.jpg";
import i3 from "./../../../../../assets/andasibé.jpg";
import i4 from "./../../../../../assets/tamatave.jpg";
import i5 from "./../../../../../assets/mahambo.jpg";
import i6 from "./../../../../../assets/Paradis des baleines l ile sainte Marie Madagascar.jpeg";
import i7 from "./../../../../../assets/Tana.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Classical_map_east from '../../../../map_east/Classical_map_east';
import { Link } from "react-router-dom";
import East_classical_cover_FR from '../East_classical_cover_FR';


const Classique_est_content_FR : React.FC = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <East_classical_cover_FR />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itineraire</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/FR/est-FR/Classique-Est-gallery-FR"
                        className="a"
                      >
                        Photos
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>

                <div className="itinerary">
                  <div className="row gy-5" id="row-princip">
                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i1} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 1{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antananarivo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Acceuil à l' aéroport , Transfert à l' hôtel .
                                Mise au point pour le détail du voyage Si le
                                temps vous le permet , Vous pourriez admirer au
                                palais de la reine Antananarivo et ses environs
                                pour un point de vue magnifique , La plus haute
                                altitude à Antananarivo
                                <br /> Nuit à l'hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i2} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 2{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana- Andasibé
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Départ le matin pour Andasibe , à travers les
                                beaux paysages verdoyants . Stop à Madagascar
                                Excotic Réserve réputés pour les chaméléons,
                                grenouilles, reptiles, batracians et crocodiles
                                Visite nocturne à Andasibe pour les lémuriens,
                                le fameux microcebus le petit lémurien dans le
                                monde avec 50g de poids Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i3} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 3{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Andasibé
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visite d' Andasibe National parc , vous donne l'
                                opportunité d' observer l' INDRI INDRI , le plus
                                grand lémurien à Madagascar, les orchidées, les
                                plantes médicinales La visite du Vakona réserve
                                vous laisse une mémorable souvenir avec les
                                lémuriens que vous pouvrrez observer de prés
                                <br />
                                Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i4} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 4{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Andasibé - Tamatave - Mahambo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Départ pour Mahambo à travers les montagnes
                                verdoyants de bambou, arbre de voyageurs , les
                                palmiers Tour de ville à Tamatave , qui est le
                                plus grand port à Madagascar , puis continuation
                                sur Mahambo
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i5} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 5{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Mahambo - Sainte-Marie
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Bateau pour Sainte Marie pour une traversée de
                                2h
                                <br /> Nuit à l' hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i6} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 6, Jour 7, JOur 8{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Sainte-Marie
                              </h2>
                              <div className="lig"></div>
                              <p>Sainte-Marie</p>

                              <button className="btn btn-warning text-light">
                                Voir Sainte-Marie Tour
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12" id="dernier-col">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i7} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Jour 9{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Transfert à l' hôtel.
                                <br /> Si le temps vous le permet, un tour de
                                ville à Antananarivo
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Classical_map_east />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Classique_est_content_FR;