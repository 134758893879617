import React from 'react';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Rivertrip_Bemaraha_content from '../../../../components/version_anglais/OuestAng/Rivertrip_bemaraha/itineraire/Rivertrip_Bemaraha_content';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const Rivertrip_Bemaraha :React.FC = () => {
    return (
      <div className="app-tours">
        <Navbar_tours />
        <Rivertrip_Bemaraha_content />
        <Footer />
      </div>
    );
};

export default Rivertrip_Bemaraha;