import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import couv_combined from "./../../../../assets/train.jpg";
import {
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

const Combined_tours_cover_FR = () => {
    return (
      <div className="tour-cover">
        <div
          className="tour-coverpictures"
          style={{
            backgroundImage: `url(${couv_combined})`,
          }}
        >
          <div className="tour-cover__header">
            <div className="title">
              <div className="title_1">
                <h1>Circuit Sud Classique en combinaison avec Train</h1>
              </div>

              <div className="title_2 mt-3 d-flex">
                <div className="duration">
                  <h1>
                    <FontAwesomeIcon icon={faClock} id="icon" /> 15 Jours
                  </h1>
                </div>

                <div className="location">
                  <h1>
                    <FontAwesomeIcon icon={faLocationDot} id="icon" /> Tana -
                    Ifaty
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Combined_tours_cover_FR;