import React from 'react';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import i1 from "./../../../../assets/sm1.jpg";
import i2 from "./../../../../assets/sm2.jpg";
import Couv from "./../../../../assets/paradis_baleine.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faLocationDot,
    faCalendarDay,
    faCircle
  } from "@fortawesome/free-solid-svg-icons";
  import { Link } from "react-router-dom";
  import Sainte_marie_map from '../../../../components/map_east/Sainte_marie_map';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const Sainte_marie:React.FC = () => {
    return (
      <div className="app-tours">
        <Navbar_tours />

        <div className="app-tours__content">
          <div className="app-contain">
            <div className="row g-4">
              <div className="col-12  col-lg-8">
                <div className="main-contain">
                 
                  <div className="tour-cover">
                    <div
                      className="tour-coverpictures"
                      style={{
                        backgroundImage: `url(${Couv})`,
                      }}
                    >
                      <div className="tour-cover__header">
                        <div className="title">
                          <div className="title_1">
                            <h1>Island of Sainte-Marie</h1>
                          </div>

                          <div className="title_2 mt-3 d-flex">
                            <div className="duration">
                              <h1>
                                <FontAwesomeIcon icon={faClock} id="icon" />
                                {/* 16
                                Days */}
                              </h1>
                            </div>

                            <div className="location">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />{" "}
                                Sainte-marie
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                    <div className="activity">
                      <div className="row gy-5" id="row-princip">
                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10  col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i1} alt="" />
                              </div>
                              <div className="image mt-4">
                                <img src={i2} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon icon={faCircle} fade />{" "}
                                    Sainte-Marie 's Description{" "}
                                  </h1>
                                  <p className="p">
                                    Sainte Marie's Island with his cliché of
                                    tropical ,like a post card offers you a
                                    endless long beaches overhung by coconut
                                    palms, fisheman village. It's a place of
                                    appointement for the whales
                                  </p>
                                  <div className="lig"></div>
                                  <div className="div">
                                    <b>Activity possible in Sainte-Marie:</b>{" "}
                                    <br />
                                    <ul className="ul">
                                      <li>Surfing and scuba diving </li>
                                      <li>
                                        Motorcycle or quad rental to visit the
                                        north of the island where the natural
                                        swimming pool is located, like the
                                        aquarium{" "}
                                      </li>
                                      <li>
                                        Sainte Marie is only 50km long and 7km
                                        wide
                                      </li>
                                      <li>
                                        Humpback whale watching from July to
                                        September
                                      </li>
                                      <li>Visit to pirate cemeteries</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <Sainte_marie_map />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
};

export default Sainte_marie;