import React, { useEffect, useState } from 'react';
import Accueil from './pages/Anglais/Accueil/Accueil';
import { BrowserRouter, Routes, Route,Navigate,useLocation } from "react-router-dom";
import South_welcome from './pages/Anglais/SudAnglais/South_welcome'
import Classical_south from './pages/Anglais/SudAnglais/Tour_classique/Classical_south';
import With_train_south from './pages/Anglais/SudAnglais/Combined_tour/With_train_south';
import Andringitra_south from './pages/Anglais/SudAnglais/Andringitra_tours/Andringitra_south';
import Activity_South from './components/version_anglais/SudAng/Andringitra_tours/Activités/Activity_South_andringitra';
import Activity_South_andringitra from './components/version_anglais/SudAng/Andringitra_tours/Activités/Activity_South_andringitra';
import Activity_South_Combined from './components/version_anglais/SudAng/Combined_tours/Activités/Activity_South_Combined';
import Activity_South_classical from './components/version_anglais/SudAng/sud_classique/Activités/Activity_South_classical';
import Classical_gallery from './components/version_anglais/SudAng/sud_classique/gallerie/Classical_gallery';
import Combined_gallery from './components/version_anglais/SudAng/Combined_tours/gallerie/Combined_gallery';
import Andringitra_gallery from './components/version_anglais/SudAng/Andringitra_tours/gallerie/Andringitra_gallery';
import East_welcome from './pages/Anglais/estAnglais/East_welcome';
import Classical_east from './pages/Anglais/estAnglais/Tour_classique/Classical_east';
import Classical_east_gallery from './components/version_anglais/estAng/est_classique/gallerie/Classical_east_gallery';
import East_pangalane from './pages/Anglais/estAnglais/Pangalane_tours/East_pangalane';
import Pangalane_east_gallery from './components/version_anglais/estAng/pangalane_tours/gallerie/Pangalane_east_gallery';
import Sainte_marie from './pages/Anglais/estAnglais/Sainte-marie/Sainte_marie';
import Adventures_tours from './pages/Anglais/Aventures_tours/Adventures_tours';
import Adventures_gallery from './components/version_anglais/AventuresAnglais/gallerie/Adventures_gallery';
import North_welcome from './pages/Anglais/nordAnglais/North_welcome';
import Classical_north from './pages/Anglais/nordAnglais/Tour_classique/Classical_north';
import Classical_north_gallery from './components/version_anglais/nordAng/nord_classique/gallerie/Classical_north_gallery';
import North_tour_nosyBe from './pages/Anglais/nordAnglais/NosyBe_tours/North_tour_nosyBe';
import Tour_NosyBe_gallery from './components/version_anglais/nordAng/nosyBe_Tours/gallerie/Tour_NosyBe_gallery';
import NosyBe from './pages/Anglais/nordAnglais/Nosy_be/NosyBe';
import Weast_welcome from './pages/Anglais/OuestAnglais/Weast_welcome';
import Tsingy_tour_weast from './pages/Anglais/OuestAnglais/Tsingy_tour/Tsingy_tour_weast';
import Tsingy_tours_gallery from './components/version_anglais/OuestAng/Tsingy_tour/gallerie/Tsingy_tours_gallery';
import Baobab_tours from './pages/Anglais/OuestAnglais/Baobab_tours/Baobab_tours';
import Baobab_tours_gallery from './components/version_anglais/OuestAng/Baobab_tour/gallerie/Baobab_tours_gallery';
import RiverTrip_tours from './pages/Anglais/OuestAnglais/Rivertrip_tours/RiverTrip_tours';
import Rivertrip_tours_gallery from './components/version_anglais/OuestAng/Rivertrip_tours/gallerie/Rivertrip_tours_gallery';
import Rivertrip_Bemaraha from './pages/Anglais/OuestAnglais/Rivertrip_bemaraha/Rivertrip_Bemaraha';
import Rivertrip_Bemaraha_gallery from './components/version_anglais/OuestAng/Rivertrip_bemaraha/gallerie/Rivertrip_Bemaraha_gallery';
import Tours from './pages/Anglais/Accueil/Tours';
import Button_scroll_top from './components/Button_scroll_top';
import Loader from './components/Loader';
import Accueil_FR from './pages/Francais/Accueil_FR/Accueil_FR';
import Ouest_Welcome from './pages/Francais/ouestFR/Ouest_Welcome';
import Rivertrip_tour_FR from './pages/Francais/ouestFR/Rivertrip_tours/Rivertrip_tour_FR';
import Rivertrip_tours_content_FR from './components/version_francaise/OuestFr/Rivertrip_tours/itineraire/Rivertrip_tours_content_FR';
import Rivertrip_tours_gallery_FR from './components/version_francaise/OuestFr/Rivertrip_tours/gallerie/Rivertrip_tours_gallery_FR';
import Baobab_tours_Fr from './pages/Francais/ouestFR/Baobab_tours/Baobab_tours_Fr';
import Baobab_tours_gallery_FR from './components/version_francaise/OuestFr/Baobab_tours/gallerie/Baobab_tours_gallery_FR';
import Tsingy_tour_FR from './pages/Francais/ouestFR/Tsingy_tour/Tsingy_tour_FR';
import Tsingy_tours_gallery_FR from './components/version_francaise/OuestFr/Tsingy_tour/gallerie/Tsingy_tours_gallery_FR';
import Sud_welcome from './pages/Francais/sudFR/Sud_welcome';
import Classique_sud from './pages/Francais/sudFR/Tour_classique/Classique_sud';
import Activity_classical_south_FR from './components/version_francaise/sudFR/sud_classique/Activités/Activity_classical_south_FR';
import Classical_gallery_FR from './components/version_francaise/sudFR/sud_classique/gallerie/Classical_gallery_FR';
import Combined_sud_FR from './pages/Francais/sudFR/combined_tours/Combined_sud_FR';
import Activity_combined_FR from './components/version_francaise/sudFR/combined_tours/Activités/Activity_combined_FR';
import Combined_gallery_FR from './components/version_francaise/sudFR/combined_tours/gallerie/Combined_gallery_FR';
import Andringitra_FR from './pages/Francais/sudFR/andringitra_tours/Andringitra_FR';
import Andringitra_gallery_FR from './components/version_francaise/sudFR/Andrigitra_tours/gallerie/Andringitra_gallery_FR';
import Activity_andringitra_FR from './components/version_francaise/sudFR/Andrigitra_tours/Activités/Activity_andringitra_FR';
import Nord_welcome from './pages/Francais/nordFR/Nord_welcome';
import Classique_nord_FR from './components/version_francaise/nordFR/nord_classique/itineraire/Classique_nord_content_FR';
import Classique_nord from './pages/Francais/nordFR/Tour_classique/Classique_nord';
import Classique_nord_gallery_FR from './components/version_francaise/nordFR/nord_classique/gallerie/Classique_nord_gallery_FR';
import Tour_nord_nosyBe_FR from './pages/Francais/nordFR/NosyBe_tours/Tour_nord_nosyBe_FR';
import Tour_nosyBe_gallery_FR from './components/version_francaise/nordFR/nosyBe_tours/gallerie/Tour_nosyBe_gallery_FR';
import Nosy_Be_FR from './pages/Francais/nordFR/NosyBe/Nosy_Be_FR';
import Est_welcome from './pages/Francais/estFR/Est_welcome';
import Classique_est from './pages/Francais/estFR/tour_classique/Classique_est';
import Classique_est_gallery_FR from './components/version_francaise/estFR/est_classique/gallerie/Classique_est_gallery_FR';
import Est_avec_pangalane from './pages/Francais/estFR/pangalane_tours/Est_avec_pangalane';
import Pangalane_est_gallery_FR from './components/version_francaise/estFR/pangalana_tours/gallerie/Pangalane_est_gallery_FR';
import SainteMarie_FR from './pages/Francais/estFR/Sainte-marie/SainteMarie_FR';
import Adventures_tours_FR from './pages/Francais/Adventures_FR/Adventures_tours_FR';
import Adventures_gallery_FR from './components/version_francaise/adventuresFR/gallerie/Adventures_gallery_FR';
import Rivertrip_Bemaraha_FR from './pages/Francais/ouestFR/Rivertrip_bemaraha/Rivertrip_Bemaraha_FR';
import Rivertrip_Bemaraha_gallery_FR from './components/version_francaise/OuestFr/Rivertrip_bemaraha/gallerie/Rivertrip_Bemaraha_gallery_FR';


const PagesRoutes:React.FC = () => {

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/tours/south-anglais" element={<South_welcome />} />
          <Route path="/tours/east-anglais" element={<East_welcome />} />
          <Route path="/tours" element={<Tours />} />
          <Route
            path="/tours/south-anglais/classical_south"
            element={<Classical_south />}
          />
          <Route
            path="/tours/east-anglais/classical_east"
            element={<Classical_east />}
          />
          <Route
            path="/tours/south-anglais/combined_tour_south"
            element={<With_train_south />}
          />
          <Route
            path="/tours/south-anglais/andringitra_tour_south"
            element={<Andringitra_south />}
          />
          <Route
            path="/tours/south-anglais/activity-andringitra"
            element={<Activity_South_andringitra />}
          />
          <Route
            path="/tours/south-anglais/activity-combined"
            element={<Activity_South_Combined />}
          />
          <Route
            path="/tours/south-anglais/activity-classical"
            element={<Activity_South_classical />}
          />
          <Route
            path="/tours/south-anglais/classical-gallery"
            element={<Classical_gallery />}
          />
          <Route
            path="/tours/east-anglais/classical-east-gallery"
            element={<Classical_east_gallery />}
          />
          <Route
            path="/tours/south-anglais/combined-gallery"
            element={<Combined_gallery />}
          />
          <Route
            path="/tours/south-anglais/andringitra-gallery"
            element={<Andringitra_gallery />}
          />
          <Route
            path="/tours/east-anglais/pangalane-east"
            element={<East_pangalane />}
          />
          <Route
            path="/tours/east-anglais/pangalane-east-gallery"
            element={<Pangalane_east_gallery />}
          />
          <Route
            path="/tours/east-anglais/sainte-marie-east"
            element={<Sainte_marie />}
          />
          <Route
            path="/tours/Adventures-tours"
            element={<Adventures_tours />}
          />
          <Route
            path="/tours/Adventures-tours-gallery"
            element={<Adventures_gallery />}
          />
          <Route path="/tours/north-anglais" element={<North_welcome />} />
          <Route
            path="/tours/north-anglais/classical-north"
            element={<Classical_north />}
          />
          <Route
            path="/tours/north-anglais/classical-north-gallery"
            element={<Classical_north_gallery />}
          />
          <Route
            path="/tours/north-anglais/north-tour-with-nosyBe"
            element={<North_tour_nosyBe />}
          />
          <Route
            path="/tours/north-anglais/north-tour-with-nosyBe-gallery"
            element={<Tour_NosyBe_gallery />}
          />
          <Route path="/tours/north-anglais/nosyBe" element={<NosyBe />} />
          <Route path="/tours/weast-anglais" element={<Weast_welcome />} />
          <Route
            path="/tours/weast-anglais/Tsingy-weast-anglais"
            element={<Tsingy_tour_weast />}
          />
          <Route
            path="/tours/weast-anglais/Tsingy-weast-anglais-gallery"
            element={<Tsingy_tours_gallery />}
          />
          <Route
            path="/tours/weast-anglais/Baobab-weast-anglais"
            element={<Baobab_tours />}
          />
          <Route
            path="/tours/weast-anglais/Baobab-weast-gallery-anglais"
            element={<Baobab_tours_gallery />}
          />
          <Route
            path="/tours/weast-anglais/RiverTrip-tours-anglais"
            element={<RiverTrip_tours />}
          />
          <Route
            path="/tours/weast-anglais/RiverTrip-tours-anglais-gallery"
            element={<Rivertrip_tours_gallery />}
          />

          <Route
            path="/tours/weast-anglais/RiverTrip-Bemaraha-anglais"
            element={<Rivertrip_Bemaraha />}
          />

          <Route
            path="/tours/weast-anglais/RiverTrip-Bemaraha-anglais-gallery"
            element={<Rivertrip_Bemaraha_gallery />}
          />

          <Route path="/FR" element={<Accueil_FR />} />

          <Route path="/FR/ouest" element={<Ouest_Welcome />} />

          <Route
            path="/FR/ouest/Rivertrip-tours-francais"
            element={<Rivertrip_tour_FR />}
          />

          <Route
            path="/FR/ouest/Rivertrip-bemaraha-gallery-FR"
            element={<Rivertrip_Bemaraha_gallery_FR />}
          />

          <Route
            path="/FR/ouest/Rivertrip-bemaraha-FR"
            element={<Rivertrip_Bemaraha_FR />}
          />

         

          <Route
            path="/FR/ouest/Rivertrip-tours-francais-gallery"
            element={<Rivertrip_tours_gallery_FR />}
          />

          <Route
            path="/FR/ouest/Baobab-tours-francais"
            element={<Baobab_tours_Fr />}
          />

          <Route
            path="/FR/ouest/Baobab-tours-francais-gallery"
            element={<Baobab_tours_gallery_FR />}
          />

          <Route
            path="/FR/ouest/Tsingy-tours-francais"
            element={<Tsingy_tour_FR />}
          />

          <Route
            path="/FR/ouest/Tsingy-tours-francais-gallery"
            element={<Tsingy_tours_gallery_FR />}
          />

          <Route path="/FR/sud" element={<Sud_welcome />} />

          <Route
            path="/FR/sud/Classique-sud-francais"
            element={<Classique_sud />}
          />

          <Route
            path="/FR/sud/Classique-sud-activity"
            element={<Activity_classical_south_FR />}
          />

          <Route
            path="/FR/sud/Classique-sud-pictures"
            element={<Classical_gallery_FR />}
          />

          <Route
            path="/FR/sud/Combined-sud-francais"
            element={<Combined_sud_FR />}
          />

          <Route
            path="/FR/sud/Combined-sud-activity-francais"
            element={<Activity_combined_FR />}
          />

          <Route
            path="/FR/sud/Combined-sud-gallery"
            element={<Combined_gallery_FR />}
          />

          <Route
            path="/FR/sud/Andringitra-sud-FR"
            element={<Andringitra_FR />}
          />

          <Route
            path="/FR/sud/Andringitra-gallery-FR"
            element={<Andringitra_gallery_FR />}
          />

          <Route
            path="/FR/sud/Andringitra-activités-FR"
            element={<Activity_andringitra_FR />}
          />

          <Route path="/FR/nord" element={<Nord_welcome />} />

          <Route
            path="/FR/nord/Classique-nord-FR"
            element={<Classique_nord />}
          />

          <Route
            path="/FR/nord/Classique-nord-gallery-FR"
            element={<Classique_nord_gallery_FR />}
          />

          <Route
            path="/FR/nord/Tour-nosyBE"
            element={<Tour_nord_nosyBe_FR />}
          />

          <Route
            path="/FR/nord/Tour-nosyBE-GALLERY"
            element={<Tour_nosyBe_gallery_FR />}
          />

          <Route path="/FR/nord/nosy-Be-FR" element={<Nosy_Be_FR />} />

          <Route path="/FR/est-FR" element={<Est_welcome />} />

          <Route
            path="/FR/est-FR/Classique-Est-FR"
            element={<Classique_est />}
          />

          <Route
            path="/FR/est-FR/Classique-Est-gallery-FR"
            element={<Classique_est_gallery_FR />}
          />

          <Route
            path="/FR/est-FR/Est-avec-Pangalane"
            element={<Est_avec_pangalane />}
          />

          <Route
            path="/FR/est-FR/Est-avec-Pangalane-gallery"
            element={<Pangalane_est_gallery_FR />}
          />

          <Route
            path="/FR/est-FR/Sainte-Marie-FR"
            element={<SainteMarie_FR />}
          />

          <Route path="/FR/adventures-FR" element={<Adventures_tours_FR />} />

          <Route
            path="/FR/adventures-gallery-FR"
            element={<Adventures_gallery_FR />}
          />
        </Routes>

        <Button_scroll_top />
      </BrowserRouter>
    );
};

export default PagesRoutes;
