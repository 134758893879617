import React from "react";
import weast from "./../../../assets/ouest-.jpg";
import south from "./../../../assets/lemurs.jpg";
import north from "./../../../assets/couvertureNord.jpg";
import est from "./../../../assets/aye.jpg";
import aventures from "./../../../assets/Baobabs-andavadaoka.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Tours_contents_FR = () => {
    return (
      <div className="tours_content">
        <div className="row" id="row1">
          <div className="col-12">
            <div className="title text-center" id="tour">
              <h2>Nos circuits</h2>
              <h1>Découvrir Madagascar avec nos circuits</h1>
            </div>

            <div className="row gy-5" id="row2">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="box text-center">
                  <div className="box_image">
                    <img src={weast} alt="" />
                  </div>

                  <div className="box_content">
                    <div className="title">
                      <h1>
                        {" "}
                        <FontAwesomeIcon icon={faLocationDot} id="icon" /> Ouest
                      </h1>
                    </div>

                    <div className="desc mt-3 ">
                      <h2 className=" text-start">Circuit Ouest</h2>
                      <div className="lig"></div>
                      <p className="truncate h-50 text-start">
                        Le circuit Ouest à Madagascar, est déstinés pour les
                        gens qui aiment l' aventure, nature Le Circuit Ouest à
                        Madagascar vous émerveille par l'originalité et la
                        beauté des paysages le long du fleuve de Tsiribihina
                        avec la locale pirogue ou le bateau à moteur . Le Tsingy
                        de Bemaraha unique au monde formé par la formation
                        calcaire dont les lémuriens, oiseaux y habitent;
                        l'irrésistible fameux et unique coucher de soleil sur l'
                        avenue de baobab vous éblouit. Sans oublier le Fossa ,
                        le prédateur des lémuriens . L' ambiance avec la
                        population locale toujours souriant, avec une style de
                        vie loin de la technologie, vous laissent un mémorable
                        meilleur souvenir de Madagascar
                      </p>
                      <a href="/FR/ouest">
                        <button className="btn text-center mt-2">
                          Plus de détails{" "}
                          <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="box text-center">
                  <div className="box_image">
                    <img src={south} alt="" />
                  </div>

                  <div className="box_content">
                    <div className="title">
                      <h1>
                        {" "}
                        <FontAwesomeIcon icon={faLocationDot} id="icon" /> Sud
                      </h1>
                    </div>

                    <div className="desc mt-3">
                      <h2 className=" text-start">Circuit du grand Sud</h2>
                      <div className="lig"></div>
                      <p className="truncate h-50 text-start">
                        LE CIRCUIT SUD de MADAGASCAR le long du RN7 vous offre
                        le meilleur et variété de paysages à travers les
                        montagnes, les riziéres en terasse, les canyons et
                        massifs d' Isalo , le montagne granitique du PIC BOBY .
                        Les variétés de climat en passant par le climat tropical
                        humide de Ranomafana , la verdure de l' arbre de
                        voyageur à Manakara par le climat aride et végétation du
                        Sud à L'Isalo . Lés lémuriens, les chaméleons, lés
                        oiseaux idéals par les ornithologues rendent le CIRCUIT
                        SUD excéptionnel
                      </p>
                      <a href="/FR/sud">
                        <button className="btn text-center mt-2">
                          Plus de détails{" "}
                          <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="box text-center">
                  <div className="box_image">
                    <img src={north} alt="" />
                  </div>

                  <div className="box_content">
                    <div className="title">
                      <h1>
                        {" "}
                        <FontAwesomeIcon icon={faLocationDot} id="icon" /> Nord
                      </h1>
                    </div>

                    <div className="desc mt-3">
                      <h2 className=" text-start">Circuit Nord </h2>
                      <div className="lig"></div>
                      <p className="truncate h-50 text-start">
                        Le Circuit NORD renferme des surprises de découvrir en
                        une place tous les beautés de Madagascar avec la plus
                        belle plage au monde. Avec ses diversifications ,
                        endémiques faunes et flores, qui existent seulement à
                        Madagascar, comme les plantes médicinales , les baobabs,
                        les oiseaux, les lémuriens, le chaméléon dont le
                        BROOKESIA MINIMA le plus petit chaméleon au monde qui
                        mesure moin d'une pouce . le TSINGY à Ankarana . le
                        TSINGY ROUGE , LE CIRCUIT NORD vous offre aussi la belle
                        plage le Mer d' Eméraude un lagon aux eaux cristallines,
                        des massifs de coraux aux poissons multicolores, , les 3
                        baies ( baies de Sakalava, baie de pigeon, baie dunes) .
                        LE CIRCUIT NORD vous fais découvrir aussi les meilleurs
                        plages de Madagascar à NOSY BE , avec ses bancs de
                        sables blancs,eau turquoise, C'est juste à côté du
                        paradis . LE SAFARI de BALEINE au mois de juillet jusqu'
                        à novembre .
                      </p>
                      <a href="/FR/nord">
                        <button className="btn text-center mt-2">
                          Plus de détails{" "}
                          <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="box text-center">
                  <div className="box_image">
                    <img src={est} alt="" />
                  </div>

                  <div className="box_content">
                    <div className="title">
                      <h1>
                        {" "}
                        <FontAwesomeIcon icon={faLocationDot} id="icon" /> Est
                      </h1>
                    </div>

                    <div className="desc mt-3">
                      <h2 className=" text-start">Circuit Est</h2>
                      <div className="lig"></div>
                      <p className="truncate h-50 text-start">
                        Le circuit Est à Madagascar, riche en biodiversité est
                        riche en biodiversité est déstinés pour les amoureux de
                        la nature, faune et flore. Vous serez émérvéillés par l'
                        INDRI INDRI le plus grand lémurien à Madagascar avec sa
                        puissante et magique voix qui résonne dans tous les
                        parcs et ses environs, les orchidées et les plantes
                        médicinales , les reptiles, les différentes éspèces de
                        chaméléons endémiques à Madagascar. L' Aye Aye , le
                        microcébus le plus petit lémuriens dans le monde avec
                        ses 50g de poids rend le CIRCUIT EST exceptionnel durant
                        la visite nocturne . L' occasion de voir près les
                        lémuriens à Vakona réserve vous laissent une inoubliable
                        souvenir de votre passage à Madagascar
                      </p>
                      <a href="/FR/est-FR">
                        <button className="btn text-center mt-2">
                          Plus de détails{" "}
                          <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="box text-center">
                  <div className="box_image">
                    <img src={aventures} alt="" />
                  </div>

                  <div className="box_content">
                    <div className="title">
                      <h1>
                        {" "}
                        <FontAwesomeIcon icon={faLocationDot} id="icon" /> Sud
                      </h1>
                    </div>

                    <div className="desc mt-3">
                      <h2 className=" text-start">
                        Circuit Aventures et Boucles{" "}
                      </h2>
                      <div className="lig"></div>
                      <p className="truncate h-50 text-start">
                        CIRCUIT AVENTURES&BOUCLES de Morondava à Tuléar en
                        longeant la côte littorale de Sud Ouest de Madagascar
                        est déstiné pour les passionnés d' aventures et les
                        naturalistes . Vous serez éblouit en jouissant d'une vue
                        magnifique de la mer de côté tout en roulant en voiture
                        . La variété des baobabs tout au long de la route ,
                        ainsi que les meilleurs plages de Salary et Andavadoaka,
                        vous émerveillent et rendent spéciale votre voyage
                      </p>
                      <a href="/FR/adventures-FR">
                        <button className="btn text-center mt-2">
                          Plus de détails{" "}
                          <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Tours_contents_FR;