import React from 'react';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Pangalane_est_content_FR from '../../../../components/version_francaise/estFR/pangalana_tours/itineraire/Pangalane_est_content_FR';
import Footer_FR from '../../../../components/Footer_FR';


const Est_avec_pangalane = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />
        <Pangalane_est_content_FR />
        <Footer_FR />
      </div>
    );
};

export default Est_avec_pangalane;