import React from 'react';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Baobab_tours_content_FR from '../../../../components/version_francaise/OuestFr/Baobab_tours/itineraire/Baobab_tours_content_FR';
import Footer_FR from '../../../../components/Footer_FR';


const Baobab_tours_Fr = () => {
     return (
       <div className="app-tours">
         <Navbar_tours_FR/>
         <Baobab_tours_content_FR />
         <Footer_FR />
       </div>
     );
};

export default Baobab_tours_Fr;