import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i1 from "./../../../../assets/Tana.jpg";
import i2 from "./../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../assets/ouest-.jpg";
import i4 from "./../../../../assets/belo-sur-mer-3.jpg";
import i5 from "./../../../../assets/manja.jpg";
import i6 from "./../../../../assets/morombe .jpg";
import i7 from "./../../../../assets/andavadaoka.jpg";
import i8 from "./../../../../assets/Salary-Bay-4.jpg";
import i9 from "./../../../../assets/salary4.jpg";
import i10 from "./../../../../assets/salary3.jpg";
import i11 from "./../../../../assets/ifaty.jpg";
import i12 from "./../../../../assets/plage_ifaty.jpg";
import i13 from "./../../../../assets/5-La-plage-dAnakao-à-Tuléar-1024x678.jpg";
import i14 from "./../../../../assets/fenetre.jpg";
import i15 from "./../../../../assets/Ambalavao.jpg";
import i16 from "./../../../../assets/ranomafana.jpg";
import i17 from "./../../../../assets/ranomafana2.jpg";
import i18 from "./../../../../assets/Fianara.jpg";
import i19 from "./../../../../assets/gare-antsirabe.jpg";
import i20 from "./../../../../assets/Tana.jpg";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Adventures_tours_map from '../../../Adventures/map/Adventures_tours_map';
import { Link } from "react-router-dom";
import Adventures_tours_cover from '../../../version_anglais/AventuresAnglais/Adventures_tours_cover';
import Adventures_tour_cover_FR from '../Adventures_tour_cover_FR';


const Adventures_content_FR = () => {
  return (
    <div className="app-tours__content">
      <div className="app-contain">
        <div className="row g-4">
          <div className="col-12  col-lg-8">
            <div className="main-contain">
              <Adventures_tour_cover_FR />

              <div className="tour-navigation">
                <div className="tour-navigation__content d-flex justify-content-center">
                  <h3 className="active">Itineraire</h3>
                  <h3>
                    {" "}
                    <Link to="/FR/adventures-gallery-FR" className="a">
                      Photos
                    </Link>
                  </h3>
                </div>

                <div className="tour-navigation__line"></div>
              </div>

              <div className="itinerary">
                <div className="row gy-5" id="row-princip">
                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i1} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 1{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antananarivo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Acceuil à l' aéroport , Transfert à l' hôtel .
                              Mise au point pour le détail du voyage Si le temps
                              vous le permet , Vous pourriez admirer au palais
                              de la reine Antananarivo et ses environs pour un
                              point de vue magnifique , La plus haute altitude à
                              Antananarivo
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i2} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 2{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana- Antsirabé
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Antsirabe, à travers le paysages des
                              hauts plateaux, la maison argile traditionelle de
                              couleur rouge pour joindre Antsirabe qui signifie
                              là où il y a beaucoup de sel , Antsirabe la ville
                              d'eau avec ses sources thermales et aussi la
                              capitale des pousses pousses
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i3} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 3{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antsirabé - Morondava
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Antsirabe, à travers le paysages des
                              hauts plateaux, la maison argile traditionelle de
                              couleur rouge pour joindre Antsirabe qui signifie
                              là où il y a beaucoup de sel , Antsirabe la ville
                              d'eau avec ses sources thermales et aussi la
                              capitale des pousses pousses
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i4} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 4{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Morondava - Belo sur Mer
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Piste pour Belo sur Mer . Vous aurez l' occasion
                              de voir l'éspèce de Baobab FONY sur la route
                              Travérsée de la riviére de Kabatomena Avant d'
                              arriver à Belo sur Mer, vous passerez à travers la
                              saline pour la fabrication de sel
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i5} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 5{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Bel sur Mer - Manja
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Piste pour Manja Belo sur Mer , le village colorée
                              de Vezo est reputée pour la construction des
                              bateaux à voiles . Visite de l'atelier artisanale
                              de construction des bateaux à voiles
                              <br />
                              Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i6} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 6{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Manja - Morombe - Andavadaoka
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Piste sabloneux pour Andavadoka après le petit
                              déjeuner . Traversée du bac de Bevoay.
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i7} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 7{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Andavadaoka
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Avec la mer de couleur bleue,Andavadoaka est le
                              plus riche des écosystémes marins du côte Sud
                              Ouest de Madagascar Ainsi vous pourrez faire la
                              plongée, le surf Le safari baleine de juillet à
                              septembre et les dauphins vous émerveillent , .
                              une excursion à baie des assasins
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i8} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 8{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Andavadaoka - Salary
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Piste sabloneux pour Salary , une vue magnifique
                              de la mer de couleur vert émeraude le long de la
                              côte tout au long de la route vous fascinent .
                              Travérsée du piste sabloneux de Befandefa
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i9} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 9{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Salary
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Relax sur le magnifique plage de Salary . Salary,
                              une des plus belles et mer de Madagascar, avec son
                              couleur vert émeraude, de sable fin blanc . Visite
                              du village des pêcheurs .
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i10} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 10{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Salary
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i11} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 11{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Salary - Ifaty
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Piste sabloneux pour joindre Ifaty
                              <br /> Nuit à l' hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i12} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 12{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ifaty
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Relax après le piste Visite du RENIALA RESERVE :
                              une forét épineuse avec des baobabs, plantes
                              médicinales, tenrecs, tortues , oiseaux idéals
                              pour LES ORNITHOLOGUES
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i13} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 13{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ifaty - Tuléar -Isalo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Isalo à travers le paysage désertique
                              du Sud, Rencontre avec la populatiol locale
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i14} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 14{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Isalo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visite du parc nationale d' Isalo , le massifs d'
                              Isalo .Toute la journée pour visiter l' Isalo à
                              travers le canyon de l' Isalo , le piscine
                              naturelle , la piscine bleu et noir , les
                              lémuriens, les oiseaux
                              <br />
                              Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i15} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 15{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Isalo - Ambalavao
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Ambalavao après le petit déjeuner
                              Visite du réserve villageoise ANJA avant d'
                              arriver à Ambalavao , Anja réserve crée par des
                              villageois pour la protéction du faune et flore
                              dont Anja réserve est un modéle pour la protection
                              de l' environnement Vous aurez l' opportunité de
                              voir spécialement le LEMUR CATTA , chaméleons,
                              plantes médicinales , le point de vue où vous
                              pourrez aperçevoir Ambalavao et ses environs.
                              <br /> Nuit à l' hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i16} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 16{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ambalavao - Ranomafana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Ranomafana , visite du marché de zébu
                              tous les mercredis à Ambalavao , le plus grand des
                              marché de zébu à Madagascar avant de partir pour
                              Ranomafana Ambalavao est connu aussi pour le
                              papier Antemoro et la soie .Visite de l' atelier
                              artisanale de fabrication de papier Antemoro et de
                              la soie Visite nocturne pour les lémuriens,
                              grenouilles; chaméléons
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i17} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 17{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ranomafana National Park
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit du Ranomafana national parc , de climat
                              tropical humide, apprécié par les
                              naturalistes.Avec ses 12éspèces de lémuriens , les
                              chaméléons, les plantes médicinales, les
                              orchidées, les oiseaux idéal pour les
                              ornithologues
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i18} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 18{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ranomafana - Fianarantsoa -Ambositra - Antsirabé
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Antsirabe à travers la région Betsileo
                              , avec le paysage des riziéres en térasses ,
                              spécialité du tribus Betsileo Contact avec les
                              populations locales en cours de route Stop à
                              Ambositra , Ambositra ville reputée pour la
                              marquetterie et sculpture, visite de boutique
                              d'artisan de marquetterie
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i19} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 19{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antsirabé - Tana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visite de la ville d' Antsirabe avant de prendre
                              la route pour Tana Antsirabe la ville d'eau avec
                              ses sources thermales et aussi la capitale des
                              pousses pousses .Visite de l' atelier de
                              fabrication des byciclettes en miniature . Visite
                              de l' atelier de transformation locale des cornes
                              de zébu en ustensils de cuisine, bijoux, jeux
                              collectifs... Route pour Tana , à travers le
                              paysages des hauts plateaux, la maison argile
                              traditionelle de couleur rouge
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12" id="dernier-col">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i20} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 20{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Tour de ville à Tana
                              <br /> Transfert à l' aéroport pour votre vol
                              international
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
            <div className="main-secondary">
              <Adventures_tours_map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adventures_content_FR;