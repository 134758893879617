import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import fb from "./../../../assets/facebook (1).png";
import tp from "./../../../assets/tripadvisor.png";
import lk from "./../../../assets/linkedin (1).png";
import logo from "./../../../assets/madagascar-visite-ico.png";
import { Link } from "react-router-dom";
import { useApp } from "../../../AppContext";
import fr from "./../../../assets/fr.jpg";
import ang from "./../../../assets/ang.jpg";

const Navbar: React.FC = () => {
  const [openTours, setOpenTours] = useState(false);

  const { scrollToContact, scrollToGuestbook } = useApp();

  const handleToursHover = () => {
    setOpenTours(true);
  };

  const handleToursLeave = () => {
    setOpenTours(false);
  };

  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const ClickItem = () => {
    setOpen(false);
  };

  return (
    <div className="nav">
      <div className="nav_primary">
        <div className="icones d-block d-md-flex text-center justify-content-center">
          <div className="vers d-flex justify-content-center">
            <div className="ang  d-flex">
              <h6> ANG</h6> <img src={ang} alt="" />
            </div>

            <div className="fr">
              <a href="/FR" className="d-flex">
                <h6> FR</h6> <img src={fr} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="nav_secondary">
        <div className="logo d-flex">
          <img src={logo} alt="" />
          <h1>
            Madagascar<span>Visit</span>
          </h1>
        </div>

        <div className="nav_toggle d-lg-flex d-none">
          <ul className="d-block d-lg-flex m-4 justify-content-center justify-content-lg-end text-center text-lg-start">
            <li onClick={ClickItem}>
              <a href="/" className="a">
                <FontAwesomeIcon icon={faHome} className="icon" /> HOME
              </a>
            </li>

            <li onMouseEnter={handleToursHover} onMouseLeave={handleToursLeave}>
              <a className="a" id="active">
                <FontAwesomeIcon icon={faCar} className="icon" /> OUR TOURS{" "}
                <FontAwesomeIcon icon={faSortDown} className="icon" />
              </a>
              <ul
                className={`dropdown-menu tour-container  ${openTours ? "open_1" : "close_1"}`}
              >
                <li className=" mt-2 mt-lg-1 li">
                  <a href="/tours/weast-anglais" className="a">
                    Weast Tour
                  </a>
                </li>
                <li className=" mt-2 mt-lg-1 li">
                  <a href="/tours/south-anglais" className="a">
                    South Tour
                  </a>
                </li>
                <li className=" mt-2 mt-lg-1 li">
                  <a href="/tours/north-anglais" className="a">
                    North Tour
                  </a>
                </li>
                <li className=" mt-2 mt-lg-1 li">
                  <a href="/tours/east-anglais" className="a">
                    East Tour
                  </a>
                </li>
                <li className=" mt-2 mt-lg-1 li">
                  <a href="/tours/Adventures-tours" className="a">
                    Adventures Tour
                  </a>
                </li>
                {/* Ajoutez d'autres liens vers les pages de tournée ici */}
              </ul>
            </li>
            <li onClick={ClickItem}>
              <a href="/" className="a">
                <FontAwesomeIcon icon={faNewspaper} className="icon" /> BLOG
              </a>
            </li>
            <li onClick={ClickItem}>
              <a href="/" className="a">
                <FontAwesomeIcon icon={faBook} className="icon" /> GUESTBOOK
              </a>
            </li>
            <li className="contact" onClick={ClickItem}>
              <a className="a" onClick={scrollToContact}>
                <FontAwesomeIcon icon={faEnvelope} className="icon" /> CONTACT
              </a>
            </li>
          </ul>
        </div>

        <div className="hamburger d-block d-lg-none" onClick={handleClick}>
          <FontAwesomeIcon icon={faBars} className="icon" />
        </div>
      </div>
      <div className={`nav_responsive d-lg-none ${open ? "open" : "close"}`}>
        <ul className="d-block d-lg-flex m-4 justify-content-center justify-content-lg-end text-center text-lg-start">
          <li onClick={ClickItem}>
            <a href="/" className="a">
              <FontAwesomeIcon icon={faHome} className="icon" /> HOME
            </a>
          </li>

          <li onMouseEnter={handleToursHover} onMouseLeave={handleToursLeave}>
            <a className="a"  id="active">
              <FontAwesomeIcon icon={faCar} className="icon" /> OUR TOURS{" "}
              <FontAwesomeIcon icon={faSortDown} className="icon" />
            </a>
            <ul className={`dropdown-menu ${openTours ? "open_1" : "close_1"}`}>
              <li className=" mt-2 mt-lg-1 li">
                <a href="/tours/weast-anglais" className="a">
                  Weast Tour
                </a>
              </li>
              <li className=" mt-2 mt-lg-1 li">
                <a href="/tours/south-anglais" className="a">
                  South Tour
                </a>
              </li>
              <li className=" mt-2 mt-lg-1 li">
                <a href="/tours/north-anglais" className="a">
                  North Tour
                </a>
              </li>
              <li className=" mt-2 mt-lg-1 li">
                <a href="/tours/east-anglais" className="a">
                  East Tour
                </a>
              </li>
              <li className=" mt-2 mt-lg-1 li">
                <a href="/tours/Adventures-tours" className="a">
                  Adventures Tour
                </a>
              </li>
              {/* Ajoutez d'autres liens vers les pages de tournée ici */}
            </ul>
          </li>
          <li onClick={ClickItem}>
            <a href="/" className="a">
              <FontAwesomeIcon icon={faNewspaper} className="icon" /> BLOG
            </a>
          </li>
          <li onClick={ClickItem}>
            <a href="/" className="a">
              <FontAwesomeIcon icon={faBook} className="icon" /> GUESTBOOK
            </a>
          </li>
          <li className="contact" onClick={ClickItem}>
            <a className="a" onClick={scrollToContact}>
              <FontAwesomeIcon icon={faEnvelope} className="icon" /> CONTACT
            </a>
          </li>
        </ul>
      </div>
      {open && <div className="overlay"></div>}
    </div>
  );
};

export default Navbar;
