import React from 'react';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Baobab_tours_content from '../../../../components/version_anglais/OuestAng/Baobab_tour/itineraire/Baobab_tours_content';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const Baobab_tours = () => {
    return (
        <div className='app-tours'>
            <Navbar_tours />
            <Baobab_tours_content />
            <Footer/>
        </div>
    );
};

export default Baobab_tours;