import React from "react";
import Navbar_tours from "../../../components/version_anglais/Navbar/Navbar_tours";
import Adventures_tours_content from "../../../components/version_anglais/AventuresAnglais/itineraire/Adventures_tours_content";
import Footer from "../../../components/version_anglais/Footer/Footer";

const Adventures_tours = () => {
  return (
    <div className="app-tours">
      <Navbar_tours />
      <Adventures_tours_content />
      <Footer />
    </div>
  );
};

export default Adventures_tours;
