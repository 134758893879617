import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i1 from "./../../../../assets/Tana.jpg";
import i2 from "./../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../assets/ouest-.jpg";
import i4 from "./../../../../assets/belo-sur-mer-3.jpg";
import i5 from "./../../../../assets/manja.jpg";
import i6 from "./../../../../assets/morombe .jpg";
import i7 from "./../../../../assets/andavadaoka.jpg";
import i8 from "./../../../../assets/Salary-Bay-4.jpg";
import i9 from "./../../../../assets/salary4.jpg";
import i10 from "./../../../../assets/salary3.jpg";
import i11 from "./../../../../assets/ifaty.jpg";
import i12 from "./../../../../assets/plage_ifaty.jpg";
import i13 from "./../../../../assets/5-La-plage-dAnakao-à-Tuléar-1024x678.jpg";
import i14 from "./../../../../assets/fenetre.jpg";
import i15 from "./../../../../assets/Ambalavao.jpg";
import i16 from "./../../../../assets/ranomafana.jpg";
import i17 from "./../../../../assets/ranomafana2.jpg";
import i18 from "./../../../../assets/Fianara.jpg";
import i19 from "./../../../../assets/gare-antsirabe.jpg";
import i20 from "./../../../../assets/Tana.jpg";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Adventures_tours_map from '../../../Adventures/map/Adventures_tours_map';
import { Link } from "react-router-dom";
import Adventures_tours_cover from '../Adventures_tours_cover';


const Adventures_tours_content = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <Adventures_tours_cover />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itinerary</h3>
                    <h3>
                      {" "}
                      <Link to="/tours/Adventures-tours-gallery" className="a">
                        Pictures
                      </Link>
                    </h3>
                  </div>

                  <div className="tour-navigation__line"></div>
                </div>

                <div className="itinerary">
                  <div className="row gy-5" id="row-princip">
                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i1} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 1{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antananarivo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Assistance at the airport, and transfer to the
                                hotel. If you have time, going to the point of
                                view that you can see Antananarivo and his area
                                Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i2} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 2{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana- Antsirabé
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive to Antsirabe across the highland
                                sceneries, traditional red clay house of
                                highland, to reach the town of Antsirabe which
                                means a place of much salt Antsirabe , a town of
                                water for its thermal source ,spring water
                                .Antsirabe is also a capital of rickshaws
                                Overnight at the hotel.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i3} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 3{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antsirabé - Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive to Morondava , beautiful landscape of
                                mountain on the way. Lunch at Miandrivazo
                                .Sunseat in Baobab Alley for amazing and
                                unforgettable picture Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i4} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 4{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava - Belo sur Mer
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Road to Belo sur Mer through the bumpy and sandy
                                road along the differents species of Baobab Fony
                                Crossing Kabatomena river Before Belo sur Mer;
                                you will pass through the Saline for the
                                manufactory of salt Overnight to the hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i5} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 5{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Bel sur Mer - Manja
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive through the bumpy and sandy road to Manja
                                Belo sur Mer, colorful village of Vezo reputed
                                for its shipbuilding Overnight to the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i6} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 6{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Manja - Morombe - Andavadaoka
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive through the bumpy and sandy road to
                                Andavadoaka . Crossing through the ferry Bevoay
                                Overnight to the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i7} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 7{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Andavadaoka
                              </h2>
                              <div className="lig"></div>
                              <p>
                                With blue color of the sea, Andavadoaka is the
                                most richest marine ecosystem on the South West
                                of Madagascar Whalewatching in july until
                                September, dolphins Kitesurf, snorkelling ,
                                excursion to the "Bay of Assasins" are the
                                activities Overnight to the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i8} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 8{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Andavadaoka - Salary
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive through the bumpy and sandy road to
                                Salary, the sea colors like emerauld along the
                                seaside gives you a magnific view Crossing the
                                sandy road of Befandefa Overnight to the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i9} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 9{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Salary
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Relax on the magnific beach of Salary Salary is
                                one of the best beach in Madagascar with his
                                colors like emerauld , the white sand
                                Snorkelling; diving are award Overnight to the
                                hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i10} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 10{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Salary
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i11} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 11{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Salary - Ifaty
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive through the sandy road to join Ifaty
                                Meeting with the local people on the road
                                Ovenight to the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i12} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 12{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ifaty
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Relax after the bumpy and sandy road Visit of
                                RENIALA RESERV : spiny forest with the baobas,
                                edicinal plants, tenrecs, turtlles, Birds,
                                Reniala reserv specially for the birdwatching
                                Overnight to the hôtel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i13} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 13{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ifaty - Tuléar -Isalo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive to Isalo , through across the desertic
                                scenery of the South Stop along the road for
                                having contact with the local people Overnight
                                at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i14} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 14{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Isalo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visit of Isalo National Park. Isalo National
                                Park formed by a massifs and canyons, whole day
                                to explore the National Park, trekking through
                                the Isalo’s canyon, natural swimming pool,the
                                blue and black swimming pool, a refuge for
                                lemurs such as Brown lemur, ring-tailed lemur;
                                birds such as Benson’s rock ,Madagascar Wagtail
                                endemic in Isalo overnight at the hotel .
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i15} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 15{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Isalo - Ambalavao
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Road to Ambalavao after the breakfast Passing at
                                Ihosy, the capital of the Bara tribe.Crossing
                                through the dry and desertic landscape of the
                                South, the land plateau of Horombe which extend
                                as far as the eye can see. Visit of ANJA reserve
                                before arriving to Ambalavao , Anja reserve
                                created by the local villageous for protecting
                                the fauna,It’s a model for environmental
                                protection .You can see specially the ring-
                                tailed lemurs OR LEMUR CATTA , the pointof view
                                that you can see Amabalavao and his area , the
                                medicial plants. Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i16} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 16{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ambalavao - Ranomafana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive to Ranomafana , Possibility to see the
                                market of zebu in Ambalavao, if you arrive at
                                Wednesday day . Ambalavao is known for the
                                market of zebu , the first biggest market of
                                zebu in Madagascar in each Wednesday Ambalavao
                                is known also for the paper Antemoro. and the
                                silk . Visiting of the craftswoman local making
                                Antemoro paper,which is done by hand Stop along
                                the road for having contact with the local
                                people Night walking for spotting the lemurs,
                                frogs and chameleon Overnight at the hotel.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i17} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 17{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ranomafana National Park
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Visit of the Ranomafana National Park for more
                                wildlife of this rainforest .12species of lemurs
                                such as golden bamboo lemur, the greater bamboo
                                lemur ,birds, chameleons are seeing in this park
                                without forgetting orchids, different medicinal
                                plants. Overnight at the hotel.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i18} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 18{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Ranomafana - Fianarantsoa -Ambositra - Antsirabé
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Stop at Ambositra for the lunch .Ambositra is
                                the capital of woodcarving sculptures and
                                carving, possibility to visit the artisan market
                                . Then, you will across Betsileo region, with a
                                scenery of rice fields in terraces , specific
                                for this tribe. Stop along the road for having
                                contact with the local people Overnight at the
                                hotel .
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i19} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 19{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antsirabé - Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                City tour in Antsirabe with local rickshaw ,
                                visit of manufactory local of transformation of
                                the horn to jewel, games , visit of manufactory
                                local of bicycle in miniature Drive to TANA
                                across the highland sceneries, traditional red
                                clay house of highland, Overnight at the hotel.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12" id="dernier-col">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i20} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 20{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                City tour in TANA Transfert to the airport for
                                your international flight
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Adventures_tours_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Adventures_tours_content;