import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
} from "react-leaflet";

type Position = [number, number];

const Classical_map_east = () => {
  const initialPosition: Position = [-18.1495, 49.4023];

  const coordinates: { name: string; position: Position }[] = [
    { name: "Antananarivo", position: [-18.8792, 47.5079] },
    { name: "Andasibé", position: [-18.9746, 48.4195] },
    { name: "Tamatave", position: [-18.1495, 49.4023] },
    { name: "Mahambo", position: [-17.9496, 49.0978] },
    { name: "Sainte-Marie", position: [-16.9672, 49.9633] },
  ];

  return (
    <div className="map">
      <MapContainer
        center={initialPosition}
        zoom={6}
        scrollWheelZoom={false}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {coordinates.map((city) => (
          <Marker key={city.name} position={city.position}>
            <Popup>{city.name}</Popup>
          </Marker>
        ))}

        <Polyline
          positions={coordinates.map((city) => city.position)}
          color="blue"
          weight={3}
          opacity={0.7}
          dashArray="5, 10"
        />
      </MapContainer>
    </div>
  );
};

export default Classical_map_east;
