import React, { useState } from "react";
import Navbar_tours from "../../../Navbar/Navbar_tours";
import i1 from "./../../../../../assets/fleuve1.jpg";
import i2 from "./../../../../../assets/crocodile.jpg";
import i3 from "./../../../../../assets/tsiribihina (1).jpg";
import i4 from "./../../../../../assets/Lac_Tritriva.jpg";
import i5 from "./../../../../../assets/pousse-pousse.jpg";
import i6 from "./../../../../../assets/morondava.jpg";
import i7 from "./../../../../../assets/pigmic.jpg";
import i8 from "./../../../../../assets/descente_tsiribihina.jpg";
import i9 from "./../../../../../assets/SIFAKA.jpg";

import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Rivertrip_tours_map from "../../map/Rivertrip_tours_map";
import { Link } from "react-router-dom";
import Footer from "../../../Footer/Footer";
import Rivertrip_tour_cover from "../Rivertrip_tour_cover";

const Rivertrip_tours_gallery: React.FC = () => {
  const [isImageOverlayOpen, setImageOverlayOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const galleryImages = [i1, i2, i3, i4, i5, i6, i7, i8, i9];

  const openImageOverlay = (image: string) => {
    setSelectedImage(image);
    setImageOverlayOpen(true);
  };

  const closeImageOverlay = () => {
    setSelectedImage(null);
    setImageOverlayOpen(false);
  };

  return (
    <div className="app-tours">
      <Navbar_tours />

      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12 col-lg-8">
              <div className="main-contain">
                <Rivertrip_tour_cover />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                  <h3>
                      <Link
                        to="/tours/weast-anglais/RiverTrip-tours-anglais"
                        className="a"
                      >
                        Itinerary
                      </Link>
                    </h3>
                    <h3 className="active">Pictures</h3>
                   
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>
                <div className="gallery">
                  <div className="row g-0" id="row-gall">
                    <div className="row g-0" id="row-gall">
                      {galleryImages.map((image, index) => (
                        <div className="col-4 col-md-4" key={index}>
                          <div
                            className="image"
                            onClick={() => openImageOverlay(image)}
                          >
                            <img src={image} alt="" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {isImageOverlayOpen && selectedImage && (
                  <div className="image-overlay" onClick={closeImageOverlay}>
                    <div className="image-overlay-content">
                      <img
                        src={selectedImage}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Rivertrip_tours_map />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Rivertrip_tours_gallery;
