import React, { useState } from 'react';
import i1 from "./../../../../../assets/aye.jpg";
import i2 from "./../../../../../assets/ravinala-1.jpg";
import i3 from "./../../../../../assets/elmet.jpg";
import i4 from "./../../../../../assets/PALMARIUM.jpg";
import i5 from "./../../../../../assets/indri.jpg";
import i6 from "./../../../../../assets/papillon.jpg";
import Pangalane_map_east from '../../../../map_east/Pangalane_map_east';
import { Link } from "react-router-dom";
import Navbar_tours_FR from '../../../Navbar/Navbar_tours_FR';
import Footer_FR from '../../../../Footer_FR';
import Pangalane_cover_FR from '../Pangalane_cover_FR';


const Pangalane_est_gallery_FR = () => {
    const [isImageOverlayOpen, setImageOverlayOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
  
    const galleryImages = [i1, i2, i3, i4,i5,i6];
  
    const openImageOverlay = (image: string) => {
      setSelectedImage(image);
      setImageOverlayOpen(true);
    };
  
    const closeImageOverlay = () => {
      setSelectedImage(null);
      setImageOverlayOpen(false);
    };
  
      return (
        <div className="app-tours">
          <Navbar_tours_FR />

          <div className="app-tours__content">
            <div className="app-contain">
              <div className="row g-4">
                <div className="col-12 col-lg-8">
                  <div className="main-contain">
                    <Pangalane_cover_FR />

                    <div className="tour-navigation">
                      <div className="tour-navigation__content d-flex justify-content-center">
                        <h3>
                          <Link
                            to="/FR/est-FR/Est-avec-Pangalane"
                            className="a"
                          >
                            Itineraire
                          </Link>
                        </h3>
                        <h3 className="active">Photos</h3>
                      </div>
                      <div className="tour-navigation__line"></div>
                    </div>

                    <div className="gallery">
                      <div className="row g-0" id="row-gall">
                        <div className="row g-0" id="row-gall">
                          {galleryImages.map((image, index) => (
                            <div className="col-4 col-md-4" key={index}>
                              <div
                                className="image"
                                onClick={() => openImageOverlay(image)}
                              >
                                <img src={image} alt="" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {isImageOverlayOpen && selectedImage && (
                      <div
                        className="image-overlay"
                        onClick={closeImageOverlay}
                      >
                        <div className="image-overlay-content">
                          <img
                            src={selectedImage}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                  <div className="main-secondary">
                    <Pangalane_map_east />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer_FR />
        </div>
      );
};

export default Pangalane_est_gallery_FR;