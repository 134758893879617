/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../../assets/alley.jpg";
import i4 from "./../../../../../assets/bekopaka.jpg";
import i5 from "./../../../../../assets/tsingy1.jpg";
import i6 from "./../../../../../assets/alley.jpg";
import i7 from "./../../../../../assets/alle_baobab.jpg";
import i8 from "./../../../../../assets/alle_baobab.jpg";
import i9 from "./../../../../../assets/Tana.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Tsingy_tour_map from '../../map/Tsingy_tour_map';
import { Link } from "react-router-dom";
import Tsingy_tour_cover from '../Tsingy_tour_cover';


const Tsingy_tour_content:React.FC = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <Tsingy_tour_cover />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itinerary</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/tours/weast-anglais/Tsingy-weast-anglais-gallery"
                        className="a"
                      >
                        Pictures
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>

                <div className="itinerary">
                    <div className="row gy-5" id="row-princip">
                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i1} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 1{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Antananarivo
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Assistance at the airport, and transfer to the
                                  hotel. If you have time, going to the point of
                                  view that you can see Antananarivo and his
                                  area
                                  <br /> Overnight at the hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i2} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 2{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Tana- Antsirabé
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Drive to Antsirabe across the highland
                                  sceneries, traditional red clay house of
                                  highland, to reach the town of Antsirabe which
                                  means a place of much salt Antsirabe , a town
                                  of water for its thermal source ,spring water
                                  .Antsirabe is also a capital of rickshaws{" "}
                                  <br />
                                  Overnight at the hotel.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i3} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 3{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Antsirabé - Morondava
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Drive to Morondava , beautiful landscape of
                                  mountain on the way. Lunch at Miandrivazo
                                  .Sunseat in Baobab Alley for amazing and
                                  unforgettable picture
                                  <br /> Overnight at the hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i4} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 4{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Morondava - Bekopaka
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Departure to Bekopaka by 4x 4 car, lunch at
                                  Belo/Tsiribihina, Crossing by ferry the 2
                                  rivers (Tsiribihina and Manambolo), Passing
                                  through the dry landscape with a sandy and
                                  bumpy road <br />
                                  Overnight at the hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i5} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 5{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Visit of the Tsingy de Bemaraha (Limestones)
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Departure by 4X4 car for joining the park
                                  entrance of the BigTsingy, exploring the big
                                  tsingy during 3hours until 8hours, it’s depend
                                  on your physical condition A strong feeling
                                  through the bridge hanging of Andamozavaky
                                  circuits The Tsingy gives you opportunity to
                                  see different species of lemurs such as
                                  Decken's Sifaka, Red Fronted Brown Lemur, Grey
                                  Mouse Lemur, home of bird such as Coquerel's
                                  Coua, Sickle Billed Vanga, White Headed Vanga
                                  and many kinds of reptiles.
                                  <br /> Overnight at the hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i6} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 6{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Bekopaka - Morondava
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Drive to Morondava , Crossing the ferry of
                                  Belo sur Tsiribihina, drive to Morondava, Stop
                                  on the road for the sacred baobab, baobab in
                                  love,Sakalava’s tomb.Stop at the famous and
                                  unique Alley of Baobab .Sunseat at the Alley
                                  of Baobab for an amazing unforgettable
                                  picture. <br />
                                  Overnight at the hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i7} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 7{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Morondava
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Relax on the beach after the bad road , Visit
                                  of the fisherman village through the mangrove
                                  with the local’s pirogue
                                  <br /> Overnight at the hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i8} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 8{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Morondava - Tana by flight
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Transfert to the airport
                                  <br /> Overnight at the hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12" id="dernier-col">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i9} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 9{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Tana
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Pick you at the aiport .City tour in Tana ,
                                  depends on your schedule for the international
                                  flight Visiting the lemur’s park,
                                  <br /> Digue’s market,market of souvenirs
                                  before your transfert to the airport for your
                                  international flight
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Tsingy_tour_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Tsingy_tour_content;