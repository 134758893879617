import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDesc, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import {useLocation} from "react-router-dom";
import logo from "./../assets/chevron-up.svg";

const Button_scroll_top : React.FC = () => {
    const [scrollY, setScrollY] = useState<number>(0);

    useEffect(() => {
      const handleScroll = () => {
        setScrollY(window.scrollY);
      };

      window.addEventListener("scroll", handleScroll);
      
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [scrollY]);

     const affiche= scrollY > 100;

    const top= () => {
        window.scrollTo(0,0);
    }
      
    return (
        <>
        {affiche && (<img src={logo} alt="" className='icon_top' onClick={top}/>)}
        </>
    );
};

export default Button_scroll_top;