import React from "react";
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Pangalane_east_content from "../../../../components/version_anglais/estAng/pangalane_tours/itineraire/Pangalane_east_content";
import Footer from "../../../../components/version_anglais/Footer/Footer";

const East_pangalane = () => {
  return (
    <div className="app-tours">
      <Navbar_tours />
      <Pangalane_east_content />
      <Footer/>
    </div>
  );
};

export default East_pangalane;
