import React from 'react';
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    Polyline,
  } from "react-leaflet";
  
  type Position = [number, number];
  

const Classical_north_map:React.FC = () => {

    const initialPosition: Position = [-14.8833, 47.9833] ;

    const coordinates: { name: string; position: Position }[] = [
      { name: "Antananarivo", position: [-18.8792, 47.5079] },
      { name: "Ankarafantsika", position: [-16.2167, 45.8667] },
      { name: "Port-Berger", position: [-16.5000, 45.6167] },
      { name: "Antsohihy", position: [-14.8833, 47.9833] },
      { name: "Ambanja", position: [-13.6843, 48.4525] },
      { name: "Ankarana", position: [-12.8867, 49.1308] },
      { name: "Red Tsingy", position: [-12.2885, 49.0975] },
      { name: "Montagne d'Ambre Parc", position: [-12.5214, 49.1903] },
      { name: "Diego", position: [-12.2856, 49.2918] },
      { name: "Ramena", position: [-12.7447, 49.3730] },
      { name: "Mer d'Emeraude", position: [-12.6897, 49.4964] },
      { name: "Ramena", position: [-12.7447, 49.3730] },
      { name: "Baie du Sakalava", position: [-12.3067, 49.3117] },
      { name: "Ramena", position: [-12.7447, 49.3730] },
      { name: "Diego", position: [-12.2856, 49.2918] }
    ];
  
    return (
      <div className="map">
        <MapContainer
          center={initialPosition}
          zoom={6}
          scrollWheelZoom={false}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {coordinates.map((city) => (
            <Marker key={city.name} position={city.position}>
              <Popup>{city.name}</Popup>
            </Marker>
          ))}

          <Polyline
            positions={coordinates.map((city) => city.position)}
            color="blue"
            weight={3}
            opacity={0.7}
            dashArray="5, 10"
          />
        </MapContainer>
      </div>
    );
};

export default Classical_north_map;