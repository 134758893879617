// PanierContext.tsx
import React, { createContext, useContext, useState , ReactNode } from 'react';
import Context from './Typescript';

type Props = {
    children: ReactNode;
  };

const AppContext = createContext<Context | undefined>(undefined);

export const useApp = () => {

  const context = useContext(AppContext);

  if (!context) {
    throw new Error('usePanier must be used within a PanierProvider');
  }
  return context;
};

export const AppProvider = ({ children }: Props) => {
  
  const scrollToTour = () => {
    const tourElement = document.getElementById('tour');
    if (tourElement) {
      const offset = 200; // Ajustez cette valeur en fonction de la quantité de défilement souhaitée
      const tourPosition = tourElement.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: tourPosition,
        behavior: 'smooth'
      });
    }
  };

  const scrollToGuestbook = () => {
    const tourElement = document.getElementById('guestbook');
    if (tourElement) {
      const offset = 200; 
      const tourPosition = tourElement.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: tourPosition,
        behavior: 'smooth'
      });
    }
  };

  const scrollToContact = () => {
    const footerElement = document.getElementById('footer');
    if (footerElement) {
      const offset = 150; // Ajustez cette valeur en fonction de la quantité de défilement souhaitée
      const tourPosition = footerElement.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: tourPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <AppContext.Provider value={{scrollToTour,scrollToContact, scrollToGuestbook}}>
      {children}
    </AppContext.Provider>
  );
};
