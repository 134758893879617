import React from 'react';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Andringitra_south_content from '../../../../components/version_anglais/SudAng/Andringitra_tours/itineraire/Andringitra_south_content';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const Andringitra_south:React.FC = () => {
    return (
        <div className='app-tours'>
            <Navbar_tours />
            <Andringitra_south_content />
            <Footer/>
        </div>
    );
};

export default Andringitra_south;