import React from 'react';
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    Polyline,
  } from "react-leaflet";
  
  type Position = [number, number];


const Rivertrip_Bemaraha_map :React.FC = () => {

    const initialPosition: Position = [-19.8642, 47.0389] ;

    const coordinates: { name: string; position: Position }[] = [
      { name: "Antananarivo", position: [-18.8792, 47.5079] },
      { name: "Antsirabé", position: [-19.8642, 47.0389] },
      { name: "Miandrivazo", position: [-19.5167, 45.4667] }, 
      { name: "Rivière de Tsiribihina", position: [-19.7591, 44.8335] },
      { name: "Belo sur Tsiribihina", position: [-19.7000, 44.5499] }, 
      { name: "Bekopaka", position: [-20.5833, 44.6167] },
      { name: "Morondava", position: [-20.2847, 44.3176] },
    ];

    return (
      <div className="map">
        <MapContainer
          center={initialPosition}
          zoom={6}
          scrollWheelZoom={false}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {coordinates.map((city) => (
            <Marker key={city.name} position={city.position}>
              <Popup>{city.name}</Popup>
            </Marker>
          ))}

          <Polyline
            positions={coordinates.map((city) => city.position)}
            color="blue"
            weight={3}
            opacity={0.7}
            dashArray="5, 10"
          />
        </MapContainer>
      </div>
    );
};

export default Rivertrip_Bemaraha_map;