import React from "react";
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/akarafantsika.jpg";
import i3 from "./../../../../../assets/ankarafantsika.jpg";
import i4 from "./../../../../../assets/antsohihy.jpg";
import i5 from "./../../../../../assets/ambanja.jpg";
import i6 from "./../../../../../assets/Ankarana2.jpg";
import i7 from "./../../../../../assets/tsingyankaranan.jpg";
import i8 from "./../../../../../assets/tsingyrouge-900x525.jpg";
import i9 from "./../../../../../assets/amber.jpg";
import i10 from "./../../../../../assets/diego.jpg";
import i11 from "./../../../../../assets/emerald.jpg";
import i12 from "./../../../../../assets/baie-de-sakalava.jpg";
import i13 from "./../../../../../assets/ramena.jpg";
import i14 from "./../../../../.././assets/Tana.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Classical_north_map from "../../map/Classical_north_map";
import { Link } from "react-router-dom";
import Classical_north_cover from "../Classical_north_cover";

const Classical_north_content = () => {
  return (
    <div className="app-tours__content">
      <div className="app-contain">
        <div className="row g-4">
          <div className="col-12  col-lg-8">
            <div className="main-contain">
              
              <Classical_north_cover />

              <div className="tour-navigation">
                <div className="tour-navigation__content d-flex justify-content-center">
                  <h3 className="active">Itinerary</h3>
                  <h3>
                    {" "}
                    <Link
                      to="/tours/north-anglais/classical-north-gallery"
                      className="a"
                    >
                      Pictures
                    </Link>
                  </h3>
                </div>
                <div className="tour-navigation__line"></div>
              </div>

              <div className="itinerary">
                <div className="row gy-5" id="row-princip">
                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i1} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 1{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antananarivo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Assistance at the airport, and transfer to the
                              hotel. If you have time, going to the point of
                              view that you can see Antananarivo and his area
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i2} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 2{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana - Ankarafantsika
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i3} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 3{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarafantsika
                            </h2>
                            <div className="lig"></div>
                            <p>
                              like Coquerel's Sifaka, Brown lemur, Milne Edwards
                              Sportive lemurs if you're lucky. The bird,
                              Ankarafantsika National Park is home of bird ,
                              BAOBAB, medicinal plants
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i4} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 4{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarafantsika - Port Berger - Antsohihy
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Road to Antsohihy through the local people in the
                              North part of Madagascar
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i5} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 5{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antsohihy - Ambanja
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit ot 1h for the reserv lead by the local
                              people for the BLACK INDRI in wild.
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i6} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 6{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ambanja - Ankarana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit of MILLOT PLANTATION in Ambanja for the
                              plantation of cacao, vanilla, Ilang Ilang , before
                              road to Ankarana .Millot plantation show you how
                              to plant cocoa until the packing of exportation ,
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i7} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 7{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit of ANKARANA NATIONAL PARK for the TSINGY
                              d'ANKARANA , Tsingy formed by llimestones similar
                              in Tsingy of Bemaraha .The lemurs, birds, The
                              sacred bat's cave, Baobab
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i8} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 8{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarana - Red Tsingy- Joffre ville
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Road to Joffre Ville Visit of Red Tsingy formed by
                              laterite formation with his amazing colours red
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i9} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 9{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Joffre ville - Mountain Amber National Park
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit of Mountain Amber National Park , the oldest
                              park in Madagascar with his rainforest climate
                              gives you opportunity to see the waterfall,
                              lemurs, like crowned Sanford, Bamboo lemurs, Red
                              lemur; frog; tenrecs; birds; chameleon specially
                              the "BROOKESIA MINIMA" the smallest chameleon in
                              the world less that 1inch .
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i10} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 10{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Joffre Ville - Diego - Ramena
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Road to Ramena , you will pass to "SUGAR LOAF"
                              emblematic of Diégo , the Baobab Madagascar
                              Suarenzis endemic on the North , fisherman
                              village. Visit of Diego town ,
                              <br /> Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i11} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 11{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ramena - Emerald Sea - Ramena
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Excursion at Emerald sea . You will take motors
                              boat to Emerald Sea . The boat will navigate
                              across the most beautiful lagoon's cristalline
                              waters, coral reefs teeming with hundred of red
                              fish . Emerald sea is perfect for swimming with
                              it's shallow water . It's perfect to exploring
                              this underwater paradise . Tasty fresh and sea
                              food during the excursion.
                              <br /> Overnight at the hotel .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i12} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 12{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ramena - 3 baies Baies of Sakalava , Pigeon, Baies
                              of Sakalava ideal for surfing
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Baies of Sakalava ideal for surfing , Water sports
                              like surfing ,Sakalava bay is ideal .
                              <br />
                              Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i13} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 13{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ramena - Diego - Tana
                            </h2>
                            <div className="lig"></div>
                            <p>Road to Diego Flight to Antananarivo</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12" id="dernier-col">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i14} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 14{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              City tour in Antananarivo.
                              <br /> Transfert to the airport for your
                              international flight
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
            <div className="main-secondary">
              <Classical_north_map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classical_north_content;
