import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCircle,
  faCopyright,
  faEnvelope,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faInstagram,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import image from "./../assets/alley.jpg";
import { Link } from "react-router-dom";
import { useApp } from "../../../AppContext";

const Footer: React.FC = () => {
  const { scrollToTour, scrollToGuestbook } = useApp();

  return (
    <div className="Footer">
      <div className="row Footer_primary" id="footer">
        <div className="col-12 col-md-6 col-lg-3 gy-4">
          <div className="links">
            <div className="main-title">
              <h1>
                <FontAwesomeIcon icon={faCircle} id="icon" /> Quick Links
              </h1>
            </div>

            <div className="links_content">
              <ul>
                <li>
                  <a href="#" className="a">
                    Home
                  </a>
                </li>
                <li>
                  <Link to="" onClick={scrollToTour} className="a">
                    Our Tours
                  </Link>
                </li>
                <li>
                  <Link to="/" className="a">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="" className="a" onClick={scrollToGuestbook}>
                    Guestbook
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3 gy-4">
          <div className="tours">
            <div className="main-title">
              <h1>
                <FontAwesomeIcon icon={faCar} id="icon" /> Our tours
              </h1>
            </div>

            <div className="links_content">
              <ul>
                <li>
                  <a href="/tours/weast-anglais" className="a">
                    Weast Tour
                  </a>
                </li>
                <li>
                  <a href="/tours/south-anglais" className="a">
                    South Tour
                  </a>
                </li>
                <li>
                  <a href="/tours/north-anglais" className="a">
                    North Tour
                  </a>
                </li>
                <li>
                  <a href="/tours/east-anglais" className="a">
                    East Tour
                  </a>
                </li>
                <li>
                  <a href="/tours/Adventures-tours" className="a">
                    Adventures Tour
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3 gy-4">
          <div className="tours">
            <div className="main-title">
              <h1>
                <FontAwesomeIcon icon={faEnvelope} id="icon" /> Our Contacts
              </h1>
            </div>

            <div className="links_content">
              <ul>
                <li>
                  <FontAwesomeIcon icon={faPhone} /> 034 73 440 45
                </li>
                <li className="mt-3">
                  <FontAwesomeIcon icon={faEnvelope} /> lovasson@yahoo.fr
                </li>
                <li className="mt-3">
                  <img src="" alt="" />
                  <FontAwesomeIcon icon={faWhatsapp} /> +261(0)347344045
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3 gy-4">
          <div className="follow">
            <div className="main-title">
              <h1>
                <FontAwesomeIcon icon={faUser} id="icon" /> Follow us on
              </h1>
            </div>

            <div className="icones d-flex">
              <div className="icone1">
                <a
                  href="https://www.facebook.com/madagascarvisite"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} className="icon" />
                </a>
              </div>
              <div className="icone2">
                <a
                  href="https://www.linkedin.com/company/madagascar-visite-company/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} className="icon" />
                </a>
              </div>
              <div className="icone3">
                <a
                  href="https://instagram.com/madagascarvisitecompany?igshid=YWYwM2I1ZDdmOQ=="
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} className="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row Footer_secondary">
        <div className="col-12">
          <div className="copyright text-center">
            <h1>
              Madagascar Visit Company <FontAwesomeIcon icon={faCopyright} />{" "}
              2023 | by Johan
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
