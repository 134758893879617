import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i1 from "./../../assets/5-La-plage-dAnakao-à-Tuléar-1024x678.jpg";
import i2 from "./../../assets/art-zafimaniry-sculpture.jpg";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Andringitra_map from '../version_anglais/SudAng/map/Andringitra_map';
import Footer_FR from '../Footer_FR';

const Activity_South_components = () => {
    return (
      <div className="activity">
        <div className="row gy-5" id="row-princip">
          <div className="col-12">
            <div className="row row-child" id="row-child">
              <div className="col-10  col-md-7 col-lg-5">
                <div className="image">
                  <img src={i1} alt="" />
                </div>
              </div>

              <div className="col-12 col-lg-7">
                <div className="tour_details">
                  <div className="tour_details_title">
                    <h1>Extension Anakao for the Best Beact </h1>
                    <h2>
                      <FontAwesomeIcon icon={faLocationDot} id="icon" />
                      Anakao
                    </h2>
                    <div className="lig"></div>
                    <div className="div">
                      <b>ACTIVITIES in ANAKAO:</b> <br />
                      <ul className="ul">
                        <li>Relax on the beach </li>
                        <li>Snorkelling, Diving </li>
                        <li>
                          Excursion with local’s pirogue’s for seeing the coral
                          reefs{" "}
                        </li>
                        <li>
                          Excursion in Nosy Ve with the pirogue’s local that you
                          have opportunity to see the long tailed tropic bird
                          specially for the BIRDWATCHING{" "}
                        </li>
                        <li>
                          Whalewatching : From July to mid September, humpbacked
                          whales come around Anakao .
                        </li>
                        <li>
                          WHALEWATCHING: From July to mid September, humpbacked
                          whales come around Anakao .
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" id="dernier_col">
            <div className="row row-child" id="row-child">
              <div className="col-10  col-md-7 col-lg-5">
                <div className="image">
                  <img src={i2} alt="" />
                </div>
              </div>

              <div className="col-12 col-lg-7">
                <div className="tour_details">
                  <div className="tour_details_title">
                    <h1>Extension: Zafimaniry Village in Antoetra </h1>
                    <h2>
                      <FontAwesomeIcon icon={faLocationDot} id="icon" />
                      Antoetra
                    </h2>
                    <div className="lig"></div>
                    <div className="div">
                      <ul className="ul">
                        <li>
                          <b> ANTOETRA </b> situated 45km from Ambositra .There
                          lives a group of people known as the Zafimaniry, or
                          the "the people of the forest."{" "}
                        </li>
                        <li>
                          <b> ZAFIMANIRY </b> is famous for its woods carvings,
                          most of the families are involved in the manufacture
                          of wooden objects from towers "by hand ". Most of all
                          the houses are built with wood. The sculptures carved
                          in the doors and windows,furnitures,tools,and every
                          day objects with attractive geometric patterns are
                          highly codified,and so wonderful ; that’s why they
                          were included in the world cultural heritage of
                          UNESCO.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Activity_South_components;