import React from "react";
import weast from "./../../../assets/ouest-.jpg";
import south from "./../../../assets/lemurs.jpg";
import north from "./../../../assets/couvertureNord.jpg";
import est from "./../../../assets/aye.jpg";
import aventures from "./../../../assets/Baobabs-andavadaoka.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight ,faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const Tours_content = () => {
  
  return (
    <div className="tours_content">
      <div className="row" id="row1">
        <div className="col-12">
          <div className="title text-center"  id="tour">
            <h2>Our tours</h2>
            <h1>Discover Madagascar with our  tours</h1>
          </div>

          <div className="row gy-5" id="row2">
            <div className="col-12 col-lg-4 col-md-6">
              <div className="box text-center">
                <div className="box_image">
                  <img src={weast} alt="" />
                </div>

                <div className="box_content">
                  <div className="title">
                    <h1>
                      {" "}
                      <FontAwesomeIcon icon={faLocationDot} id="icon" /> Weast
                     
                    </h1>
                  </div>

                  <div className="desc mt-3 ">
                    <h2 className=" text-start">Weast Tour </h2>
                    <div className="lig"></div>
                    <p className="truncate h-50 text-start">
                      WEST TOUR is for the people who loves adventures , natures
                      ,and specially the birds. A paradise of the birds. You
                      might discover the originality and beauty of the scenery
                      among the Tsiribihina river with local’s pirogue,or boat
                      motor”s . The gorge of Bemaraha , and also the Tsingy of
                      Bemaraha known as ‘the forest of the stones” , an insolate
                      site formed with calcareous pics .The famous and unique
                      Baobab Alley .The famous sunset in the baobab alley
                      without forgetting the Fossa in freddom in Kirindy reserve
                      . The ambiance during the camping with fire , meeting with
                      a local people always smiling , a life so far from the
                      technology gives you an unforgettable memory
                    </p>
                    <a href="/tours/weast-anglais">
                      <button className="btn text-center mt-2">
                        More details{" "}
                        <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                      </button>
                    </a>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-6">
              <div className="box text-center">
                <div className="box_image">
                  <img src={south} alt="" />
                </div>

                <div className="box_content">
                  <div className="title">
                    <h1>
                      {" "}
                      <FontAwesomeIcon icon={faLocationDot} id="icon" /> South
                      
                    </h1>
                  </div>

                  <div className="desc mt-3">
                  <h2 className=" text-start">The South Adventures</h2>
                    <div className="lig"></div>
                    <p className="truncate h-50 text-start">
                      SOUTH TOUR IN MADAGASCAR is done along the national road
                      7, this tour is one of the most popular destinations by
                      visitors since it offers the best and variety of scenery
                      across the mountains, the rice field in terrace, valleys,
                      forest, canyon ; different National park ; and different
                      cultures as you will pass through different tribes . SOUTH
                      TOUR gives you opportunity to pass through the rainforest
                      ; Ranomafana national park ; Manakara along the evergreen
                      landscape ,with the excursion in the Pangalane’s channel;
                      to have an experience with the train Fianarantsoa until
                      Manakara ;to visit the zafimaniry’s village reputed of
                      hability of woodcarving; not forgetting the mountain
                      hiking in Andringitra National park , which you can reach
                      BOBY’S PEAK, the 2nd mountain highest in Madagascar , and
                      trekking in the tsaranoro’s valley. Circuit can be
                      modified according to your choice .
                    </p>
                    <a href="/tours/south-anglais">
                      <button className="btn text-center mt-2">
                        More details{" "}
                        <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-6">
              <div className="box text-center">
                <div className="box_image">
                  <img src={north} alt="" />
                </div>

                <div className="box_content">
                  <div className="title">
                    <h1>
                      {" "}
                      <FontAwesomeIcon icon={faLocationDot} id="icon" /> North
                      
                    </h1>
                  </div>

                  <div className="desc mt-3">
                  <h2 className=" text-start">North Tour </h2>
                    <div className="lig"></div>
                    <p className="truncate h-50 text-start">
                      NORTH TOUR offers you opportunity to discover in one place
                      all best beauty of Madagascar . North Tour contains many
                      others surprise with his different diversification and
                      endemic fauna and flora ,that you can find only in
                      Madagascar .It's ideal for the WILDLIFE , like birds it's
                      home of birds, lemurs, BAOBAB , chameleon ,the BROOKESIA
                      MINIMA the smallest chameleon in the word less that 1inch
                      , TSINGY of ANKARANA , RED TSINGY NORTH TOUR offers you
                      also opportunity the best beach in Madagascar , the most
                      bay in the world . The Emerald Sea with his turquoise
                      water . The 3 bays . NORTH TOUR includ NOSY BE. NOSY BE is
                      one best beach destination .The volcanic island of Nosy Be
                      has all ingredients that you'd except with his soft white
                      sand, turquoise water and wonderful seafood .It's ideal
                      starting point for cruises to paradisiac archipelagos.
                    </p>
                    <a href="/tours/north-anglais">
                      <button className="btn text-center mt-2">
                        More details{" "}
                        <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-6">
              <div className="box text-center">
                <div className="box_image">
                  <img src={est} alt="" />
                </div>

                <div className="box_content">
                  <div className="title">
                    <h1>
                      {" "}
                      <FontAwesomeIcon icon={faLocationDot} id="icon" /> East
                      
                    </h1>
                  </div>

                  <div className="desc mt-3">
                  <h2 className=" text-start">East Trip</h2>
                    <div className="lig"></div>
                    <p className="truncate h-50 text-start">
                      The biggest lemur’s INDRI INDRI in Andasibe park ,with his
                      magical call in the morning . Madagascar Excotic reserve
                      which gives you opportunity to see different chameleons,
                      reptiles, Vakona reserv that you have possibility to see
                      come close the lemurs without forgetting the night walking
                      that you gives you opportunity to see the microcebus or
                      mouse lemurs, the smallest lemurs in the world with 50g of
                      weight , frog , chameleon … We propose you : -Classical
                      East Tour -East Tour in the channel of Pangalane and
                      Palmarium - Sainte-Marie
                    </p>
                    <a href="/tours/east-anglais">
                      <button className="btn text-center mt-2">
                        More details{" "}
                        <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                      </button>
                    </a>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-6">
              <div className="box text-center">
                <div className="box_image">
                  <img src={aventures} alt="" />
                </div>

                <div className="box_content">
                  <div className="title">
                    <h1>
                      {" "}
                      <FontAwesomeIcon icon={faLocationDot} id="icon" />{" "}
                     South
                    </h1>
                  </div>

                  <div className="desc mt-3">
                  <h2 className=" text-start">Adventures and Looping Trip </h2>
                    <div className="lig"></div>
                    <p className="truncate h-50 text-start">
                   
                      From Morondava towards Tuléar following the seaside in
                      South Weast to Madagascar is specially for the people who
                      loves nature and adventure The nice view of the sea on
                      your right side during the driving will be fascinate you
                      Your trip will be amazing with the differents species of
                      the baobab among the road and the best beaches in Salary
                      and Andavadoaka
                    </p>
                    <a href="/tours/Adventures-tours">
                      <button className="btn text-center mt-2">
                        More details{" "}
                        <FontAwesomeIcon icon={faCircleInfo} id="icon" />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tours_content;

