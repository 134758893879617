import React from 'react';
import Navbar_tours from "../../../components/version_anglais/Navbar/Navbar_tours";
import Zoom from "react-reveal/Zoom";
import Couv from "./../.././../assets/couvertureNord.jpg";
import i1 from "./../../../assets/tsingyrouge-900x525.jpg";
import i2 from "./../../../assets/nosy-Be.jpg";
import i3 from "./../../../assets/nosy-iranja.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from '../../../components/version_anglais/Footer/Footer';

const North_welcome:React.FC = () => {
    return (
      <div className="north_welcome">
        <Navbar_tours />

        <div className="North_welcome_content">
          <div className="row" id="couv">
            <div className="col-12">
              <div className="image">
                <img src={Couv} alt="" className="w-100" />
              </div>

              <div className="col-12">
                <div className="title text-center">
                  <Zoom>
                    <h2>North Tour</h2>
                    <h1>Madagascar Visit for the BROOKESIA MINIMA</h1>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="desc">
            <div className="col-12">
              <div className="title">
                <h1> North Trip details</h1>
                <div className="lig"></div>
              </div>

              <div className="desc">
                <p>
                  NORTH TOUR offers you opportunity to discover in one place all
                  best beauty of Madagascar . North Tour contains many others
                  surprise with his different diversification and endemic fauna
                  and flora ,that you can find only in Madagascar .It's ideal
                  for the WILDLIFE , like birds it's home of birds, lemurs,
                  BAOBAB , chameleon ,the BROOKESIA MINIMA the smallest
                  chameleon in the word less that 1inch , TSINGY of ANKARANA ,
                  RED TSINGY NORTH TOUR offers you also opportunity the best
                  beach in Madagascar , the most bay in the world . The Emerald
                  Sea with his turquoise water . The 3 bays . NORTH TOUR includ
                  NOSY BE. NOSY BE is one best beach destination .The volcanic
                  island of Nosy Be has all ingredients that you'd except with
                  his soft white sand, turquoise water and wonderful seafood
                  .It's ideal starting point for cruises to paradisiac archipel
                </p>
              </div>
            </div>
          </div>

          <div className="row" id="tours">
            <div className="row" id="row1">
              <div className="col-12">
                <div className="title">
                  <h1> Avalaible tours in the North</h1>
                  <div className="lig"></div>
                </div>
              </div>
            </div>

            <div className="tour-list">
              <div className="contain1">
                <div className="row  gy-5 gy-lg-0 text-center gx-4" id="row2">
                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i1} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 14 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>The Classical North Tour</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/north-anglais/classical-north">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i2} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 9 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>
                            {" "}
                            Classical North Tour with Nosy-be
                          </h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/north-anglais/north-tour-with-nosyBe">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={i3} alt="" />
                      </div>

                      <div className="box-content" id="nosy-be">
                        <div className="title1">
                          <h1>Nosy-Be Hell-Ville</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/north-anglais/nosyBe">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
};

export default North_welcome;