import React from 'react';
import Tours_contents_FR from '../../components/version_francaise/Accueil/Tours_contents_FR';

const Tours_FR :React.FC = () => {
    return (
        <div className='tours'>
              <Tours_contents_FR/>
        </div>
    );
};

export default Tours_FR;