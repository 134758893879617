import React from 'react';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Combined_tours_content from '../../../../components/version_anglais/SudAng/Combined_tours/itineraire/Combined_tours_content';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const With_train_south = () => {
    return (
        <div className='app-tours'>
              <Navbar_tours />
              <Combined_tours_content />
              <Footer/>
        </div>
    );
};

export default With_train_south;