import React from 'react';
import Navbar_tours_FR from '../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Zoom from "react-reveal/Zoom";
import Couv from "./../../../assets/COUVERTURE_EST.jpg";
import Classic from "./../../../assets/SIFAKA.jpg";
import Palmarium from "./../../../assets/aye.jpg";
import Sainte_marie from "./../../../assets/Paradis des baleines l ile sainte Marie Madagascar.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer_FR from '../../../components/Footer_FR';


const Est_welcome = () => {
    return (
      <div className="east_welcome">
        <Navbar_tours_FR />

        <div className="East_welcome_content">
          <div className="row" id="couv">
            <div className="col-12">
              <div className="image">
                <img src={Couv} alt="" className="w-100" />
              </div>

              <div className="col-12">
                <div className="title text-center">
                  <Zoom>
                    <h2>Tour Est</h2>
                    <h1>Voyager à Madagascar dans la Partie Est</h1>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="desc">
            <div className="col-12">
              <div className="title">
                <h1> Détails du Circuit</h1>
                <div className="lig"></div>
              </div>

              <div className="desc">
                <p>
                  Le circuit Est à Madagascar, riche en biodiversité est riche
                  en biodiversité est déstinés pour les amoureux de la nature,
                  faune et flore. Vous serez émérvéillés par l' INDRI INDRI le
                  plus grand lémurien à Madagascar avec sa puissante et magique
                  voix qui résonne dans tous les parcs et ses environs, les
                  orchidées et les plantes médicinales , les reptiles, les
                  différentes éspèces de chaméléons endémiques à Madagascar. L'
                  Aye Aye , le microcébus le plus petit lémuriens dans le monde
                  avec ses 50g de poids rend le CIRCUIT EST exceptionnel durant
                  la visite nocturne . L' occasion de voir près les lémuriens à
                  Vakona réserve vous laissent une inoubliable souvenir de votre
                  passage à Madagascar
                </p>
              </div>
            </div>
          </div>

          <div className="row" id="tours">
            <div className="row" id="row1">
              <div className="col-12">
                <div className="title">
                  <h1> Circuits disponibles pour l' Est </h1>
                  <div className="lig"></div>
                </div>
              </div>
            </div>

            <div className="tour-list">
              <div className="contain1">
                <div className="row  gy-5 gy-lg-0 text-center gx-4" id="row2">
                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={Classic} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 9 Jours
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>Circuit Classique Est</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/est-FR/Classique-Est-FR">
                            <button className="btn text-center mt-2">
                            Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={Palmarium} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 7 Jours
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>
                            {" "}
                            Circuit Est avec Canal de Pangalane et Palmarium
                          </h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/est-FR/Est-avec-Pangalane">
                            <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={Sainte_marie} alt="" />
                      </div>

                      <div className="box-content" id="sainte-marie">
                        <div className="title1">
                          <h1>L' île de Sainte-Marie</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/est-FR/Sainte-Marie-FR">
                            <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer_FR />
      </div>
    );
};

export default Est_welcome;