import React from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
} from "react-leaflet";

type Position = [number, number];

const Combined_map: React.FC = () => {

    const initialPosition: Position = [-21.4419, 47.0896];

    const coordinates: { name: string; position: Position }[] = [
      { name: "Antananarivo", position: [-18.8792, 47.5079] },
      { name: "Antsirabé", position: [-19.8642, 47.0389] },
      { name: "Fianarantsoa", position: [-21.4419, 47.0896] },
      { name: "Manakara", position: [-22.1333, 48.0167] },
      { name: "Ranomafana", position: [-21.2555, 47.4241] },
      { name: "Ambalavao", position: [-21.8498, 46.9383] },
      { name: "Isalo", position: [-22.7333, 45.35] },
      { name: "Tuléar", position: [-23.3437, 43.6856] },
      { name: "Ifaty", position: [-23.1334, 43.6375] },
     
    ];
    return (
      <div className="map">
        <MapContainer
          center={initialPosition}
          zoom={6}
          scrollWheelZoom={false}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {coordinates.map((city) => (
            <Marker key={city.name} position={city.position}>
              <Popup>{city.name}</Popup>
            </Marker>
          ))}

          <Polyline
            positions={coordinates.map((city) => city.position)}
            color="blue"
            weight={3}
            opacity={0.7}
            dashArray="5, 10"
          />
        </MapContainer>
      </div>
    );
};

export default Combined_map;