import React from 'react';
import Tour_NosyBe_content from '../../../../components/version_anglais/nordAng/nosyBe_Tours/itineraire/Tour_NosyBe_content';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Footer from '../../../../components/version_anglais/Footer/Footer';


const North_tour_nosyBe:React.FC = () => {
    return (
        <div className='app-tours'>
        <Navbar_tours />
        <Tour_NosyBe_content />
        <Footer/>
    </div>
    );
};

export default North_tour_nosyBe;