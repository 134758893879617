import React from 'react';
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/akarafantsika.jpg";
import i3 from "./../../../../../assets/ankarafantsika.jpg";
import i4 from "./../../../../../assets/antsohihy.jpg";
import i5 from "./../../../../../assets/ambanja.jpg";
import i6 from "./../../../../../assets/Ankarana2.jpg";
import i7 from "./../../../../../assets/tsingyankaranan.jpg";
import i8 from "./../../../../../assets/tsingyrouge-900x525.jpg";
import i9 from "./../../../../../assets/ankify.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Tour_Nosy_Be_map from '../../../../version_anglais/nordAng/map/Tour_Nosy_Be_map';
import { Link } from "react-router-dom";
import Tour_nosyBe_cover from '../../../../version_anglais/nordAng/nosyBe_Tours/Tour_nosyBe_cover';
import NosyBeTour_cover_FR from '../NosyBeTour_cover_FR';


const Tour_nosyBe_content_FR = () => {
  return (
    <div className="app-tours__content">
      <div className="app-contain">
        <div className="row g-4">
          <div className="col-12  col-lg-8">
            <div className="main-contain">
              <NosyBeTour_cover_FR />

              <div className="tour-navigation">
                <div className="tour-navigation__content d-flex justify-content-center">
                  <h3 className="active">Itineraire</h3>
                  <h3>
                    {" "}
                    <Link to="/FR/nord/Tour-nosyBE-GALLERY" className="a">
                      Photos
                    </Link>
                  </h3>
                </div>
                <div className="tour-navigation__line"></div>
              </div>

              <div className="itinerary">
                <div className="row gy-5" id="row-princip">
                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i1} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 1{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antananarivo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Acceuil à l' aéroport , Transfert à l' hôtel .
                              Mise au point pour le détail du voyage Si le temps
                              vous le permet , Vous pourriez admirer au palais
                              de la reine Antananarivo et ses environs pour un
                              point de vue magnifique , La plus haute altitude à
                              Antananarivo
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i2} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 2{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana - Ankarafantsika
                            </h2>

                            <div className="lig"></div>
                            <p>
                              Route pour Ankarafantsika à travers les vastes
                              étendues de savanes, du plateaux du Tampoketsa,
                              avec le paysage subdésertiques: les marchands des
                              mangues, jujubes, tamarins en fonction des saisons
                              .
                              <br /> Nuit à l' hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i3} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 3{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarafantsika
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visite du PARC NATIONAL d' ANKARAFANTSIKA pour les
                              plantes médicinales, le baobab, les lémuriens
                              comme le Coquerelle Sifaka, les oiseaux, Le parc
                              Nationaql Ankarafantsika est idéal pour
                              l'observation des oiseaux
                              <br /> Nuit à l'hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i4} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 4{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarafantsika - Port Berger - Antsohihy
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Antsohihy . Sur la route , vous
                              rencontreriez les populations locales du Nord
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i5} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 5{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antsohihy - Ambanja
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Ambanja Ambanja , une ville entourée de
                              plantation de cacao , d'Ilang Ilang et de Vanille
                              Visite du réserve villageoise pour le Black INDRI
                              INDRI
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i6} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 6{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ambanja - Ankarana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Ankarana Visite de la plantation MILLOT
                              , plantation de vanille, d' Ilang Ilang, de cacao
                              où ils vont vous démontrer les étapes à suivre
                              pour planter le cacao jusqu' à son exportation .
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i7} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 7{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visite du parc National de l' ANKARANA pour le
                              TSINGY d' ANKARANA . Tsingy d' Ankarana est le
                              même formation que ceux du Tsingy de Bemaraha. A
                              la découverte des Tsingys , une vastes étendues de
                              roches calcaires sculptés par l' érosion, les
                              grottes et rivières souterraines , les caves des
                              chauves souris , les faunes et flores les
                              euphorbias, les pachypodium, les baobabs ; les
                              lémuriens , kes oiseaux, tenrecs, mangoustes
                              Galidia Elegans à queue annelés.
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i8} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 8{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankarana - Red Tsingy- Ambanja
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Ambanja . A la découverte du Tsingy
                              rouge avant de partir pour Ambanja , de formation
                              latérite avec ses fabuleux couleurs rouges TSINGY
                              ROUGE née de la rencontre de l'eau de pluie et
                              d'une roche compactée il y a plusieurs millions d'
                              années .Tsingy rouge est le résultat de l' érosion
                              de ce massif de grés .
                              <br /> Nuit à l'hôtel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12" id="dernier-col">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i9} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Jour 9{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ambanja - ANKIFY- Nosy-Be
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Route pour Ankify, l' embarcadère où vous
                              prendriez la vedette pour Nosy Be Travérsée de la
                              mer de 2h pour rejoindre Nosy Be.
                              <br /> Nuit à l'hôtel
                            </p>

                            <button className="btn btn-warning text-light">
                              Voir Nosy-Be Tour
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
            <div className="main-secondary">
              <Tour_Nosy_Be_map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tour_nosyBe_content_FR;