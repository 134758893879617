import React, { useState } from 'react';
import Navbar_tours from "../../../Navbar/Navbar_tours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Couv from "./../../../../../assets/SIFAKA.jpg";
import i1 from "./../../../../../assets/s.jpg";
import i2 from "./../../../../../assets/sary2.jpg";
import i3 from "./../../../../../assets/elmet.jpg";
import i4 from "./../../../../../assets/PALMARIUM.jpg";
import i5 from "./../../../../../assets/indri.jpg";
import i6 from "./../../../../../assets/papillon.jpg";
import i7 from "./../../../../../assets/east6_o.jpg";
import i8 from "./../../../../../assets/madagascar-flycatcher.webp";
import i9 from "./../../../../../assets/SAINTE MARIE2.jpg";
import {
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Classical_map_east from '../../../../map_east/Classical_map_east';
import { Link } from "react-router-dom";
import Footer from '../../../Footer/Footer';
import Classical_east_cover from '../Classical_east_cover';


const Classical_east_gallery:React.FC = () => {

    const [isImageOverlayOpen, setImageOverlayOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
  
    const galleryImages = [i1, i2, i3, i4,i5,i6,i7,i8,i9]; 
  
    const openImageOverlay = (image: string) => {
      setSelectedImage(image);
      setImageOverlayOpen(true);
    };
  
    const closeImageOverlay = () => {
      setSelectedImage(null);
      setImageOverlayOpen(false);
    };
  
    return (
      <div className="app-tours">
        <Navbar_tours />

        <div className="app-tours__content">
          <div className="app-contain">
            <div className="row g-4">
              <div className="col-12 col-lg-8">
                <div className="main-contain">
                  <Classical_east_cover />

                  <div className="tour-navigation">
                    <div className="tour-navigation__content d-flex justify-content-center">
                      <h3>
                        <Link
                          to="/tours/east-anglais/classical_east"
                          className="a"
                        >
                          Itinerary
                        </Link>
                      </h3>

                      <h3 className="active">Pictures</h3>
                    </div>
                    <div className="tour-navigation__line"></div>
                  </div>

                  <div className="gallery">
                    <div className="row g-0" id="row-gall">
                      <div className="row g-0" id="row-gall">
                        {galleryImages.map((image, index) => (
                          <div className="col-4 col-md-4" key={index}>
                            <div
                              className="image"
                              onClick={() => openImageOverlay(image)}
                            >
                              <img src={image} alt="" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {isImageOverlayOpen && selectedImage && (
                    <div className="image-overlay" onClick={closeImageOverlay}>
                      <div className="image-overlay-content">
                        <img
                          src={selectedImage}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <Classical_map_east />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
};

export default Classical_east_gallery;