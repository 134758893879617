import React from 'react';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Andringitra_content_FR from '../../../../components/version_francaise/sudFR/Andrigitra_tours/itineraire/Andringitra_content_FR';
import Footer_FR from '../../../../components/Footer_FR';


const Andringitra_FR = () => {
    return (
        <div className='app-tours'>
            <Navbar_tours_FR />
            <Andringitra_content_FR />
            <Footer_FR/>
        </div>
    );
};

export default Andringitra_FR;