import React from 'react';
import Classique_nord_content from '../../../../components/version_francaise/nordFR/nord_classique/itineraire/Classique_nord_content_FR';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Footer_FR from '../../../../components/Footer_FR';


const Classique_nord = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />
        <Classique_nord_content />
        <Footer_FR />
      </div>
    );
};

export default Classique_nord;