// PanierContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";
import { IMenuContext } from "../Data/Interface";

type Props = {
  children: ReactNode;
};

const MenuContext = createContext<IMenuContext | undefined>(undefined);

export const MenuProvider = ({ children }: Props) => {
  const [activeTour, setActiveT] = useState<boolean>(false);

  const setActiveTour = () => {
    setActiveT(true);
  };

  return (
    <MenuContext.Provider value={{ activeTour, setActiveTour }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error("usePanier must be used within a PanierProvider");
  }
  return context;
};
