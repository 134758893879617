import React from "react";
import card1 from "./../../../assets/team.jpg";
import card2 from "./../../../assets/tour.png";
import card3 from "./../../../assets/east6_o.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCar } from "@fortawesome/free-solid-svg-icons";
import { useApp } from "../../../AppContext";

const Choice : React.FC = () => {

  const {scrollToTour}  =useApp();

  return (
    <div className="choice">
      <div className="row text-center" id="row1">
        <div className="col-12">
          <div className="title">
            <h2>Best choice</h2>
            <h1>Why to trip with us ?</h1>
          </div>
        </div>
      </div>

      <div className="row gx-4 gy-5 gy-lg-0 text-center" id="row2">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="box text-center">
            <div className="box_image">
              <img src={card1} alt="" />
            </div>

            <div className="box_title">
              <h1>Experimented Team</h1>
              <div className="lig"></div>
            </div>

            <div className="box_desc">
              <p className="truncate">Madagascar Visit Company has an experimented Team member</p>
              <button className="btn" onClick={scrollToTour}>See available tours <FontAwesomeIcon icon={faCar} id="icon" /></button>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <div className="box text-center">
            <div className="box_image">
              <img src={card2} alt="" />
            </div>

            <div className="box_title">
              <h1>Some Tours proposed</h1>
              <div className="lig  text-center"></div>
            </div>

            <div className="box_desc">
              <p className="truncate">Madagascar Visit Company proposed you many tours for your holidays in Madagascar the Great Island</p>
              <button className="btn" onClick={scrollToTour}>See available tours <FontAwesomeIcon icon={faCar} id="icon" /></button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="box text-center">
            <div className="box_image">
              <img src={card3} alt="" />
            </div>

            <div className="box_title">
              <h1>Have a unforgetable Trip</h1>
              <div className="lig "></div>
            </div>

            <div className="box_desc">
              <p className="truncate">Have an great and unforgetable Trip with Madagascar Visit Company</p>
              <button className="btn" onClick={scrollToTour}>See available tours <FontAwesomeIcon icon={faCar} id="icon" /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choice;

