import React from "react";
import PagesRoutes from "./PagesRoutes";
import { AppProvider } from "./AppContext";
import { MenuProvider } from "./hooks/useMenu";

const App: React.FC = () => {
  return (
    <>
      <MenuProvider>
        <AppProvider>
          <PagesRoutes />
        </AppProvider>
      </MenuProvider>
    </>
  );
};

export default App;
