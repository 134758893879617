import React from 'react';
import Tsingy_tour_content from '../../../../components/version_anglais/OuestAng/Tsingy_tour/itineraire/Tsingy_tour_content';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const Tsingy_tour_weast = () => {
    return (
        <div className='app-tours'>
             <Navbar_tours />
            <Tsingy_tour_content />
            <Footer/>
        </div>
    );
};

export default Tsingy_tour_weast;