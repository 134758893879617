import Welcoming from '../../../components/Welcoming';
import Choice from '../../../components/version_anglais/Accueil/Choice';
import Travel from '../../../components/version_anglais/Accueil/Travel';
import Tours from './Tours';
import Guestbook from '../../../components/version_anglais/Accueil/Guestbook';
import Navbar from '../../../components/version_anglais/Navbar/Navbar';
import Carousel from '../../../components/version_anglais/Accueil/Carousel';
import About from '../../../components/version_anglais/Accueil/About';
import Footer from '../../../components/version_anglais/Footer/Footer';
  

const Accueil: React.FC= () => {
    return (
      <div className="accueil">
        <Navbar />
        <Carousel />
        <About />
        <Welcoming />
        <Choice />
        <Travel />
        <Tours/>
        <Guestbook />
        <Footer />
      </div>
    );
};

export default Accueil;