import { TGuestbook } from "../Type";

export default function GuestbookData() {
  const testimonialData: TGuestbook[] = [
    {
      name: " Timo",
      date: "2022-05-18 13:01:50",
      content: `
    We spent two weeks with Johanna and enjoyed every moment. Our itinerary was adjusted to our needs even at short notice. Johanna is a great guide with lots of knowledge and she has the ability to think on her feet even when things don’t go as planned. We recommend Madagascar Visite without any doubt.`,
    },
    {
      name: " Gerda ",
      date: "2022-08-28 14:20:34",
      content: `
      We booked our tour with Johanna and enjoyed every moment. Our itinerary was tailor made and even when things did not work out as planned, Johanna was ready with great solutions and alternatives. She made our experience something to remember with her knowledge about the country and customs and with her flexible “can do” attitude. We would recommend her services to anyone who would like to visit Madagascar and have a great experience.`,
    },
    {
      name: "  Ana G ",
      date: "2016-09-01 09:11:31",
      content: `
      My friends and I wanted to explore Madagascar and we preferred to hire a guide who could show us the country. A friend of a friend recommended us Madagascar Visite Company, and today is our 12th day in Madagascar with them! It is been great up to now - we still have 5 more days left. We've been visiting the South, and although Madagascar requires long hours in the car, it is really worth it: Ambositra, Ambalavao, Isalo, Ifaty, Ranomafana, Manakara... Lemurs, chameleons, geckos, frogs, snakes, amazing flora... And the people! So nice, smiling and generous, in spite of the poverty... Such a beautiful country!!
      `,
    },
    {
      name: "  Loahoo ",
      date: "2010-06-07 19:26:37",
      content: `
      The place is must see attraction in Madagascar. The only reserve where you can encounter so many kinds of chameleons, frogs, tendrics, etc.In our later travel we have never get chance to see them in one place in such variety. travel guide: lova emadisson`,
    },
    {
      name: " lamako ",
      date: "2014-12-11 19:32:12",
      content: `
      Bonjour, Nous revenons d'un séjour de 15 jours à Madagascar avec l'organisme de voyage Lova Tour dont nous sommes tout à fait satisfaits. Nous avons effectué le trajet Tana - Tuléar en 4*4 avec un guide et un chaufeur, tout 2 très professionnels, compétents et très aimables. Nous sommes ravis de notre voyage et les recommandons pour toute personne souhaitant partir à Madagacar. Leur contact : www.madagascar-visite.com ou lovasson@yahoo.fr `,
    },
    {
      name: "  Irina ",
      date: "2022-05-11 14:17:12",
      content: `
      We travelled with Johanna through Madagascar twice. Each trip was amazing. I used to visit many countries in the world and know very well that everything could happen during the tour, but the best travel guide is able to help and find solution of your problems. Johanna is exactly the person who made our dreams come true in Madagascar. If I would be lucky enough to come back, I will travel with Johanna again.`,
    },
  ];

  return { testimonialData };
}
