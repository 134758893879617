import React from 'react';
import Navbar_tours_FR from '../../../components/version_francaise/Navbar/Navbar_tours_FR';
import fenter from "./../../../assets/lemurs.jpg";
import Zoom from "react-reveal/Zoom";
import img_classical from "./../../../assets/fenetre.jpg";
import img_combined from "./../../../assets/train.jpg";
import img_andringitra from "./../../../assets/Parc-national-dAndringitra.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer_FR from '../../../components/Footer_FR';

const Sud_welcome = () => {
    return (
      <div className="south_welcome">
        <Navbar_tours_FR />

        <div className="South_welcome_content">
          <div className="row" id="couv">
            <div className="col-12">
              <div className="image">
                <img src={fenter} alt="" className="w-100" />
              </div>

              <div className="col-12">
                <div className="title text-center">
                  <Zoom>
                    <h2>Tour Sud</h2>
                    <h1>Madagascar Tour pour le Lémurien maki-catta</h1>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="desc">
            <div className="col-12">
              <div className="title">
                <h1> Détails du circuit</h1>
                <div className="lig"></div>
              </div>

              <div className="desc">
                <p>
                  LE CIRCUIT SUD de MADAGASCAR le long du RN7 vous offre le
                  meilleur et variété de paysages à travers les montagnes, les
                  riziéres en terasse, les canyons et massifs d' Isalo , le
                  montagne granitique du PIC BOBY . Les variétés de climat en
                  passant par le climat tropical humide de Ranomafana , la
                  verdure de l' arbre de voyageur à Manakara par le climat aride
                  et végétation du Sud à L'Isalo . Lés lémuriens, les
                  chaméleons, lés oiseaux idéals par les ornithologues rendent
                  le CIRCUIT SUD excéptionnel
                </p>
              </div>
            </div>
          </div>

          <div className="row" id="tours">
            <div className="row" id="row1">
              <div className="col-12">
                <div className="title">
                  <h1> Circuits disponibles pour le Sud</h1>
                  <div className="lig"></div>
                </div>
              </div>
            </div>

            <div className="tour-list">
              <div className="contain1">
                <div className="row  gy-5 gy-lg-0 text-center gx-4" id="row2">
                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={img_classical} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 12
                            Jours
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>Circuit Sud Classique</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/sud/Classique-sud-francais">
                          <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={img_combined} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 15 Jours
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>
                            Circuit Sud Classique en Combinaison avec Train
                          </h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/sud/Combined-sud-francais">
                            <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={img_andringitra} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 15 Jours
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>
                          Circuits Aventures: Parc National Andringitra
                          </h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/FR/sud/Andringitra-sud-FR">
                          <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer_FR />
      </div>
    );
};

export default Sud_welcome;