import React from 'react';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Couv from "./../../../../assets/nosy-iranja.jpg";
import i1 from "./../../../../assets/couv_nosy-Be.webp";
import i2 from "./../../../../assets/nosy-tanikely-1.jpg";
import i3 from "./../../../../assets/SAINTE MARIE2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faLocationDot,
    faCalendarDay,
    faCircle
  } from "@fortawesome/free-solid-svg-icons";
  import { Link } from "react-router-dom";
  import NosyBe_map from '../../../../components/version_anglais/nordAng/map/NosyBe_map';
import Footer from '../../../../components/version_anglais/Footer/Footer';


const NosyBe:React.FC = () => {
    return (
      <div className="app-tours">
        <Navbar_tours />

        <div className="app-tours__content">
          <div className="app-contain">
            <div className="row g-4">
              <div className="col-12  col-lg-8">
                <div className="main-contain">
                  <div className="tour-cover">
                    <div
                      className="tour-coverpictures"
                      style={{
                        backgroundImage: `url(${Couv})`,
                      }}
                    >
                      <div className="tour-cover__header">
                        <div className="title">
                          <div className="title_1">
                            <h1>Nosy-Be Hell-Ville</h1>
                          </div>

                          <div className="title_2 mt-3 d-flex">
                            <div className="duration">
                              <h1>
                                <FontAwesomeIcon icon={faClock} id="icon" />
                                {/* 16
                                Days */}
                              </h1>
                            </div>

                            <div className="location">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />{" "}
                                Nosy-Be
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="activity">
                    <div className="row gy-5" id="row-princip">
                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10  col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i1} alt="" />
                            </div>
                            <div className="image mt-4">
                              <img src={i2} alt="" />
                            </div>

                            <div className="image mt-4">
                              <img src={i3} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7 mt-3 mt-lg-0">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon icon={faCircle} fade />{" "}
                                  Nosy-Be 's Description{" "}
                                </h1>
                                <p className="p">
                                  NOSY BE is one the best beach destination .The
                                  volcanic island of Nosy Be has all ingredients
                                  that you'd except with his soft white sand,
                                  turquoise water and wonderful seafood .You're
                                  like in Paradise . It's ideal starting point
                                  for cruises to paradisiac archipelagos.
                                  Snorkelling, Diving, Fishing,
                                  Sailing,windsurf,kitesurf ; visit of Park
                                  National of Lokobe , Sunseat in Mount Passo
                                  are the activities in Nosy Be . WHALEWATCHING
                                  in July until NOvember is one of activitie
                                  also .
                                </p>
                                <div className="lig"></div>
                                <div className="div">
                                  <b>Activity possible in Nosy-Be:</b> <br />
                                  <ul className="ul">
                                    <li>
                                      <b>Nosy Komba </b>
                                      <br /> Visit of Nosy Komba to find the
                                      lemur makako in nature as well Swimming,{" "}
                                    </li>
                                    <li>
                                      <b>Nosy Sakatia </b>
                                      <br /> For diving; swimming
                                    </li>
                                    <li>
                                      <b>Nosy Iranja</b>
                                      <br /> Reputed for 2islands Iranja Be and
                                      Iranja Kely connected by a sand bank; and
                                      separated when it's low tide . The water
                                      is clear with his white beach and
                                      beautiful sand bar.
                                    </li>
                                    <li>
                                      <b>Nosy Tanikely </b>
                                      <br />
                                      Nosy Tanikely and its marine park. Nosy
                                      Tanikely is located at the South of Nosy
                                      Be. It’s also a real live aquarium, with
                                      exceptional marine life and one of the
                                      most beautiful scuba diving sites of the
                                      region. Since 2010 Nosy Tanikely has
                                      become a marine national park and also a
                                      protected site.Protected Marins Park for
                                      diving and snorkelling with the
                                      seaturtlles , protected coral with the
                                      tropical fish .
                                    </li>
                                    <li>
                                      <b>Andilana Beach </b>
                                      <br /> Most beautiful beach of the bay ,
                                      nickely stretched .
                                    </li>
                                    <li>
                                      <b>WhaleWatching </b>
                                      <br /> in july until november
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <NosyBe_map />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
};

export default NosyBe;