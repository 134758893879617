import React from 'react';
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    Polyline,
  } from "react-leaflet";
  
  type Position = [number, number];


const Sainte_marie_map:React.FC = () => {

    const initialPosition: Position = [-16.9672,  49.9633];

    const coordinates: { name: string; position: Position }[] = [
      { name: "Sainte-Marie", position: [-16.9672,  49.9633] },
      
    ];

    return (
        <div className="map">
        <MapContainer
          center={initialPosition}
          zoom={6}
          scrollWheelZoom={false}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {coordinates.map((city) => (
            <Marker key={city.name} position={city.position}>
              <Popup>{city.name}</Popup>
            </Marker>
          ))}

          <Polyline
            positions={coordinates.map((city) => city.position)}
            color="blue"
            weight={3}
            opacity={0.7}
            dashArray="5, 10"
          />
        </MapContainer>
      </div>
    );
};

export default Sainte_marie_map;