import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../../assets/Fianara.jpg";
import i4 from "./../../../../../assets/train.jpg";
import i5 from "./../../../../../assets/plage_manakara.jpg";
import i6 from "./../../../../../assets/ranomafana.jpg";
import i7 from "./../../../../../assets/ranomafana2.jpg";
import i8 from "./../../../../../assets/Ambalavao.jpg";
import i9 from "./../../../../../assets/fenetre.jpg";
import i10 from "./../../../../../assets/isalo_6.jpg";
import i11 from "./../../../../../assets/tulear.jpg";
import i12 from "./../../../../../assets/plage_ifaty.jpg";
import i13 from "./../../../../../assets/Tana.jpg";
import i14 from "./../../../../../assets/Tana.jpg";
import {
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Combined_map from '../../../../version_anglais/SudAng/map/Combined_map';
import { Link } from "react-router-dom";
import Combined_tours_cover_FR from '../Combined_tours_cover_FR';

const Combined_south_content_FR = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <Combined_tours_cover_FR />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itineraire</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/FR/sud/Combined-sud-activity-francais"
                        className="a"
                      >
                        Activités
                      </Link>
                    </h3>
                    <h3>
                      {" "}
                      <Link to="/FR/sud/Combined-sud-gallery" className="a">
                        Photos
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>

                <div className="row" id="part-2">
                  <div className="itinerary">
                    <div className="row gy-5" id="row-princip">
                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i1} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 1{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Antananarivo
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Acceuil à l' aéroport , Transfert à l' hôtel .
                                  Mise au point pour le détail du voyage Si le
                                  temps vous le permet , Vous pourriez admirer
                                  au palais de la reine Antananarivo et ses
                                  environs pour un point de vue magnifique , La
                                  plus haute altitude à Antananarivo
                                  <br /> Nuit à l'hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i2} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 2{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Tana- Antsirabé
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Route pour Antsirabe, à travers le paysages
                                  des hauts plateaux, la maison argile
                                  traditionelle de couleur rouge pour joindre
                                  Antsirabe qui signifie là où il y a beaucoup
                                  de sel , Antsirabe la ville d'eau avec ses
                                  sources thermales et aussi la capitale des
                                  pousses pousses Nuit à l' hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i3} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 3{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Antsirabé - Fianarantsoa
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Route pour Fianarantsoa à travers le Betsileo
                                  région avec des variétés de montagnes, les
                                  fameux riziéres en térasses
                                  <br /> Nuit à l' hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i4} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 4{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Fianarantsoa -Manakara en Train
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Départ le matin à Fianarantsoa pour prendre le
                                  train pour joindre Manakara. Les paysages
                                  verdoyants , rencontre direct avec les
                                  populations locaux, rend ce circuit
                                  spectaculaire
                                  <br /> Nuit à l' hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i5} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 5{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Manakara
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Excursion sur le canal de Pangalane avec la
                                  pirogue locale , des fruits de mers,
                                  langoustes frais préparés par les guides comme
                                  déjeuner Visite du village des pêcheurs
                                  <br /> Nuit à l'hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i6} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 6{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Manakara - Ranomafana
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Route pour Ranomafana à travers les fôréts de
                                  Ravinala ou arbre de voyageurs
                                  <br /> Nuit à l' hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i7} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 7{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Parc National de Ranomafana
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Visit du Ranomafana national parc , de climat
                                  tropical humide, apprécié par les
                                  naturalistes.Avec ses 12éspèces de lémuriens ,
                                  les chaméléons, les plantes médicinales, les
                                  orchidées, les oiseaux idéal pour les
                                  ornithologues
                                  <br /> Nuit à l' hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i8} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 8{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Ranomafana - Ambalavao
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Route pour Ambalavao , grand marché de zébu
                                  tous les mercredis à Ambalavao , le plus grand
                                  des marché de zébu à Madagascar. Ambalavao est
                                  connu aussi pour le papier Antemoro et la soie
                                  .Visite de l' atelier artisanale de
                                  fabrication de papier Antemoro et de la soie
                                  <br /> Nuit à l' hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i9} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 9{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Ambalavao - Isalo
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Visite du réserve villageoise ANJA avant de
                                  partir pour Isalo , Anja réserve crée par des
                                  villageois pour la protéction du faune et
                                  flore dont Anja réserve est un modéle pour la
                                  protection de l' environnement Vous aurez l'
                                  opportunité de voir spécialement le LEMUR
                                  CATTA , chaméleons, plantes médicinales , le
                                  point de vue où vous pourrez aperçevoir
                                  Ambalavao et ses environs. On continue la
                                  route en passant par Ihosy, le capital de
                                  tribus Bara à travers le climat désertique du
                                  Sud, le plateau de Ihorombe à perte de vue
                                  <br /> Nuit à l' hotel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i10} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 10{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Isalo
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Visite du parc nationale d' Isalo , le massifs
                                  d' Isalo .Toute la journée pour visiter l'
                                  Isalo à travers le canyon de l' Isalo , le
                                  piscine naturelle , la piscine bleu et noir ,
                                  les lémuriens, les oiseaux
                                  <br /> Nuit à l' hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i11} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 11{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Isalo - Tuléar - Ifaty
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Visite du parc nationale d' Isalo , le massifs
                                  d' Isalo .Toute la journée pour visiter l'
                                  Isalo à travers le canyon de l' Isalo , le
                                  piscine naturelle , la piscine bleu et noir ,
                                  les lémuriens, les oiseaux
                                  <br /> Nuit à l' hôtel
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i12} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 12, Jour 13{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Ifaty
                                </h2>
                                <div className="lig"></div>
                                <p>Nuit à l'hôtel</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i13} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Day 14{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Ifaty - Tana en Avion
                                </h2>
                                <div className="lig"></div>
                                <p>Nuit à l'hôtel</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12" id="dernier-col">
                        <div className="row row-child" id="row-child">
                          <div className="col-10 col-md-7 col-lg-5">
                            <div className="image">
                              <img src={i14} alt="" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-7">
                            <div className="tour_details">
                              <div className="tour_details_title">
                                <h1>
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    id="icon"
                                  />{" "}
                                  Jour 15{" "}
                                </h1>
                                <h2>
                                  <FontAwesomeIcon
                                    icon={faLocationDot}
                                    id="icon"
                                  />
                                  Tana
                                </h2>
                                <div className="lig"></div>
                                <p>
                                  Transfert à l' aéroport
                                  <br /> Tour de ville dépend de votre horaire
                                  de vol international .Visite à lemur's parc,
                                  marché de Digue pour les souvenirs
                                  <br /> Transfert à l' aéropor pour votre vol
                                  international
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Combined_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Combined_south_content_FR;