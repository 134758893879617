import React from 'react';
import Navbar_tours_FR from '../../../../components/version_francaise/Navbar/Navbar_tours_FR';
import i1 from "./../../../../assets/sm1.jpg";
import i2 from "./../../../../assets/sm2.jpg";
import Couv from "./../../../../assets/paradis_baleine.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faLocationDot,
    faCalendarDay,
    faCircle
  } from "@fortawesome/free-solid-svg-icons";
  import { Link } from "react-router-dom";
  import Sainte_marie_map from '../../../../components/map_east/Sainte_marie_map';
import Footer_FR from '../../../../components/Footer_FR';


const SainteMarie_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />

        <div className="app-tours__content">
          <div className="app-contain">
            <div className="row g-4">
              <div className="col-12  col-lg-8">
                <div className="main-contain">
                  <div className="tour-cover">
                    <div
                      className="tour-coverpictures"
                      style={{
                        backgroundImage: `url(${Couv})`,
                      }}
                    >
                      <div className="tour-cover__header">
                        <div className="title">
                          <div className="title_1">
                            <h1>Island of Sainte-Marie</h1>
                          </div>

                          <div className="title_2 mt-3 d-flex">
                            <div className="duration">
                              <h1>
                                <FontAwesomeIcon icon={faClock} id="icon" />
                                {/* 16
                              Days */}
                              </h1>
                            </div>

                            <div className="location">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />{" "}
                                Sainte-marie
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="activity">
                    <div className="container">
                      <div className="row gy-5" id="row-princip">
                        <div className="col-12">
                          <div className="row row-child" id="row-child">
                            <div className="col-10  col-md-7 col-lg-5">
                              <div className="image">
                                <img src={i1} alt="" />
                              </div>
                              <div className="image mt-4">
                                <img src={i2} alt="" />
                              </div>
                            </div>

                            <div className="col-12 col-lg-7">
                              <div className="tour_details">
                                <div className="tour_details_title">
                                  <h1>
                                    <FontAwesomeIcon icon={faCircle} fade />{" "}
                                    Sainte-Marie 's Description{" "}
                                  </h1>
                                  <p className="p">
                                    L'ile paradisiaque de Sainte Marie à
                                    Madagascar vous donne une impression dans
                                    une carte postale, avec des longues plages
                                    de sables ourlées de côcôtiers, une belle
                                    fôret tropicale et des petits villages de
                                    pécheurs. C'est un lieu de ressemblement des
                                    baleines à bosses
                                  </p>
                                  <div className="lig"></div>
                                  <div className="div">
                                    <b>Activités possible à Sainte-Marie:</b>{" "}
                                    <br />
                                    <ul className="ul">
                                      <li>Surf et plongée sous marine </li>
                                      <li>
                                        Location de motocyclette ou quad pour
                                        visiter le nord de lîle où se trouve la
                                        piscine naturelle , comme l' aquarium
                                      </li>
                                      <li>
                                        Sainte Marie est seulement 50 km de long
                                        et 7km de large
                                      </li>
                                      <li>
                                        Observation des baleines à bosses de
                                        juillet à septembre
                                      </li>
                                      <li>Visite des cimétiéres de pirates</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
                <div className="main-secondary">
                  <Sainte_marie_map />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer_FR />
      </div>
    );
};

export default SainteMarie_FR;