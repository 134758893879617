import React from 'react';
import Navbar_tours_FR from '../../../components/version_francaise/Navbar/Navbar_tours_FR';
import Adventures_content_FR from '../../../components/version_francaise/adventuresFR/itineraire/Adventures_content_FR';
import Footer_FR from '../../../components/Footer_FR';


const Adventures_tours_FR = () => {
    return (
      <div className="app-tours">
        <Navbar_tours_FR />
        <Adventures_content_FR />
        <Footer_FR />
      </div>
    );
};

export default Adventures_tours_FR;