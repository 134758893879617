import React from 'react';
import Navbar_tours_FR from '../../../components/version_francaise/Navbar/Navbar_tours_FR';
import fenter from "./../../assets/fenetre.jpg";
import Zoom from "react-reveal/Zoom";
import weast from "./../../../assets/ouest-.jpg";
import i1 from "./../../../assets/tsiribihina.jpg";
import i2 from "./../../../assets/tsingy2.jpg";
import i3 from "./../../../assets/fosa_.jpg";
import i4 from "./../../../assets/tsingy1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Footer_FR from '../../../components/Footer_FR';


const Ouest_Welcome = () => {
  return (
    <div className="weast_welcome">
      <Navbar_tours_FR/>

      <div className="Weast_welcome_content">
        <div className="row" id="couv">
          <div className="col-12">
            <div className="image">
              <img src={weast} alt="" className="w-100" />
            </div>

            <div className="col-12">
              <div className="title text-center">
                <Zoom>
                  <h2>Ouest Tour</h2>
                  <h1>Madagascar Tour pour le fameux Allé des Baobab</h1>
                </Zoom>
              </div>
            </div>
          </div>
        </div>

        <div className="row" id="desc">
          <div className="col-12">
            <div className="title">
              <h1> Détails du circuit</h1>
              <div className="lig"></div>
            </div>

            <div className="desc">
              <p>
                Le circuit Ouest à Madagascar, est déstinés pour les gens qui
                aiment l' aventure, nature Le Circuit Ouest à Madagascar vous
                émerveille par l'originalité et la beauté des paysages le long
                du fleuve de Tsiribihina avec la locale pirogue ou le bateau à
                moteur . Le Tsingy de Bemaraha unique au monde formé par la
                formation calcaire dont les lémuriens, oiseaux y habitent;
                l'irrésistible fameux et unique coucher de soleil sur l' avenue
                de baobab vous éblouit. Sans oublier le Fossa , le prédateur des
                lémuriens . L' ambiance avec la population locale toujours
                souriant, avec une style de vie loin de la technologie, vous
                laissent un mémorable meilleur souvenir de Madagascar
              </p>
            </div>
          </div>
        </div>

        <div className="row" id="tours">
          <div className="row" id="row1">
            <div className="col-12">
              <div className="title">
                <h1> Circuits disponibles pour l' Ouest</h1>
                <div className="lig"></div>
              </div>
            </div>
          </div>

          <div className="tour-list">
            <div className="contain1">
              <div className="row  gy-5 gy-lg-4 text-center gx-4" id="row2">
                <div className="col-12 col-lg-4 col-md-6">
                  <div className="box text-start">
                    <div className="box-image">
                      <img src={i1} alt="" />
                      <div className="date">
                        <h1>
                          {" "}
                          <FontAwesomeIcon icon={faClock} id="icon" /> 9 jours
                        </h1>
                      </div>
                    </div>

                    <div className="box-content">
                      <div className="title1">
                        <h1>Descente sur la rivière de Tsiribihina</h1>
                        {/* <div className="lig"></div>
                        <p className="truncate h-50 text-start">
                          NORTH TOUR offers you opportunity to discover in one
                          place all best beauty of Madagascar . North Tour
                          contains many others surprise with his different
                          diversification and endemic fauna and flora ,that you
                          can find only in Madagascar .It's ideal for the
                        </p> */}
                      </div>

                      <div className="desc mt-4">
                        <a href="/FR/ouest/Rivertrip-tours-francais">
                        <button className="btn text-center mt-2">
                              Voir le circuit{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-4 col-md-6">
                  <div className="box text-start">
                    <div className="box-image">
                      <img src={i2} alt="" />
                      <div className="date">
                        <h1>
                          {" "}
                          <FontAwesomeIcon icon={faClock} id="icon" /> 12 jours
                        </h1>
                      </div>
                    </div>

                    <div className="box-content">
                      <div className="title1">
                        <h1>
                          Descente sur le fleuve de Tsiribihina et et visite du
                          Tsingy de Bemaraha
                        </h1>
                        {/* <div className="lig"></div>
                        <p className="truncate h-50 text-start">
                          NORTH TOUR offers you opportunity to discover in one
                          place all best beauty of Madagascar . North Tour
                          contains many others surprise with his different
                          diversification and endemic fauna and flora ,that you
                          can find only in Madagascar .It's ideal for the
                        </p> */}
                      </div>

                      <div className="desc mt-4">
                        <a href="/FR/ouest/Rivertrip-bemaraha-FR">
                        <button className="btn text-center mt-2">
                        Voir le circuit{" "}
                            <FontAwesomeIcon icon={faArrowRight} id="icon" />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-4 col-md-6">
                  <div className="box text-start">
                    <div className="box-image">
                      <img src={i3} alt="" />
                      <div className="date">
                        <h1>
                          {" "}
                          <FontAwesomeIcon icon={faClock} id="icon" /> 8 jours
                        </h1>
                      </div>
                    </div>

                    <div className="box-content">
                      <div className="title1">
                        <h1>Baobab Tour et Kirindy Reserve</h1>
                        {/* <div className="lig"></div>
                        <p className="truncate h-50 text-start">
                          NORTH TOUR offers you opportunity to discover in one
                          place all best beauty of Madagascar . North Tour
                          contains many others surprise with his different
                          diversification and endemic fauna and flora ,that you
                          can find only in Madagascar .It's ideal for the
                        </p> */}
                      </div>

                      <div className="desc mt-4">
                        <a href="/FR/ouest/Baobab-tours-francais">
                        <button className="btn text-center mt-2">
                        Voir le circuit{" "}
                            <FontAwesomeIcon icon={faArrowRight} id="icon" />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-4 col-md-6">
                  <div className="box text-start">
                    <div className="box-image">
                      <img src={i4} alt="" />
                      <div className="date">
                        <h1>
                          {" "}
                          <FontAwesomeIcon icon={faClock} id="icon" /> 8 jours
                        </h1>
                      </div>
                    </div>

                    <div className="box-content">
                      <div className="title1">
                        <h1> Tsingy de Bemaraha</h1>
                        {/* <div className="lig"></div>
                        <p className="truncate h-50 text-start">
                          NORTH TOUR offers you opportunity to discover in one
                          place all best beauty of Madagascar . North Tour
                          contains many others surprise with his different
                          diversification and endemic fauna and flora ,that you
                          can find only in Madagascar .It's ideal for the
                        </p> */}
                      </div>

                      <div className="desc mt-4">
                        <a href="/FR/ouest/Tsingy-tours-francais">
                        <button className="btn text-center mt-2">
                        Voir le circuit{" "}
                            <FontAwesomeIcon icon={faArrowRight} id="icon" />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer_FR />
    </div>
  );
};

export default Ouest_Welcome;