import React from "react";
import image_about from "./../../../assets/about_518x389.jpg";

const About_FR: React.FC = () => {
  return (
    <div className="about">
      <div className="row">
        <div className="col-12 col-lg-5 order-2 order-lg-1">
          <div className="profil">
            <img src={image_about} alt="" />
          </div>
        </div>

        <div className="col-12 col-lg-6 order-1 order-lg-2">
          <div className="row" id="title">
            <div className="title">
              <h2>Qui sommes-nous ?</h2>
              <h1>Un guide Touristique local</h1>
              <p className="mt-4">
                MADAGASCAR VISIT COMPANY est un guide touristique local à
                Madagascar, dirigé par Johanna EMADISSON pour vos voyages,
                circuits et vacances à Madagascar, C'est un réel plaisir pour
                nous de vous guider à travers notre pays, pour rendre votre
                visite et votre séjour confortable et inoubliable. Madagascar,
                haut lieu de biodiversité, alors, si vous aimez la faune et la
                flore, les baobabs, les lémuriens, les caméléons, les oiseaux,
                les beaux paysages, la plage, les gens. Madagascar Visit Company
                vous propose une exclusivité touristique, un circuit
                ornithologique, un circuit aventure dans les Tsingy de Bemaraha,
                le long de la côte. Parce que nous sommes un ambassadeur de
                notre pays. Notre objectif, avec mon travail d'équipe, les
                chauffeurs, guides, bateliers, porteurs, est de partager avec
                vous, l'amour de notre pays et de vous donner satisfaction
                pendant vos vacances, c'est ainsi que vous garderez un bon
                souvenir inoubliable de Madagascar. Et c'est un privilège. pour
                nous de mener un tourisme durable et responsable afin de laisser
                cette faune unique et irremplaçable aux générations futures
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About_FR;
