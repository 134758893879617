import React from 'react';
import Navbar_tours from "../../../components/version_anglais/Navbar/Navbar_tours";
import Zoom from "react-reveal/Zoom";
import Couv from "./../../../assets/COUVERTURE_EST.jpg";
import Classic from "./../../../assets/SIFAKA.jpg";
import Palmarium from "./../../../assets/aye.jpg";
import Sainte_marie from "./../../../assets/Paradis des baleines l ile sainte Marie Madagascar.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from '../../../components/version_anglais/Footer/Footer';


const East_welcome = () => {
    return (
      <div className="east_welcome">
        <Navbar_tours />

        <div className="East_welcome_content">
          <div className="row" id="couv">
            <div className="col-12">
              <div className="image">
                <img src={Couv} alt="" className="w-100" />
              </div>

              <div className="col-12">
                <div className="title text-center">
                  <Zoom>
                    <h2>East Tour</h2>
                    <h1>Madagascar Holidays to the East Part </h1>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="desc">
            <div className="col-12">
              <div className="title">
                <h1> East Trip details</h1>
                <div className="lig"></div>
              </div>

              <div className="desc">
                <p>
                  The biggest lemur’s INDRI INDRI in Andasibe park ,with his
                  magical call in the morning . Madagascar Excotic reserve which
                  gives you opportunity to see different chameleons, reptiles,
                  Vakona reserv that you have possibility to see come close the
                  lemurs without forgetting the night walking that you gives you
                  opportunity to see the microcebus or mouse lemurs, the
                  smallest lemurs in the world with 50g of weight , frog ,
                  chameleon … <br />
                  We propose you : <br />
                  -Classical East Tour <br /> -East Tour in the channel of
                  Pangalane and Palmarium <br /> - Sainte-Marie
                </p>
              </div>
            </div>
          </div>

          <div className="row" id="tours">
            <div className="row" id="row1">
              <div className="col-12">
                <div className="title">
                  <h1> Avalaible tours in the East</h1>
                  <div className="lig"></div>
                </div>
              </div>
            </div>

            <div className="tour-list">
              <div className="contain1">
                <div className="row  gy-5 gy-lg-0 text-center gx-4" id="row2">
                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={Classic} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 9 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1>The Classical East Tour</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/east-anglais/classical_east">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={Palmarium} alt="" />
                        <div className="date">
                          <h1>
                            {" "}
                            <FontAwesomeIcon icon={faClock} id="icon" /> 7 days
                          </h1>
                        </div>
                      </div>

                      <div className="box-content">
                        <div className="title1">
                          <h1> East Tour in the channel of Pangalane and Palmarium</h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/east-anglais/pangalane-east">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-6">
                    <div className="box text-start">
                      <div className="box-image">
                        <img src={Sainte_marie} alt="" />
                       
                      </div>

                      <div className="box-content" id="sainte-marie">
                        <div className="title1">
                          <h1>
                            The Island of Sainte-Marie 
                          </h1>
                          {/* <div className="lig"></div>
                          <p className="truncate h-50 text-start">
                            NORTH TOUR offers you opportunity to discover in one
                            place all best beauty of Madagascar . North Tour
                            contains many others surprise with his different
                            diversification and endemic fauna and flora ,that
                            you can find only in Madagascar .It's ideal for the
                          </p> */}
                        </div>

                        <div className="desc mt-4">
                          <a href="/tours/east-anglais/sainte-marie-east">
                            <button className="btn text-center mt-2">
                            See this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} id="icon" />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
};

export default East_welcome;
