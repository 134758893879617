import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Couv from "./../../../../../assets/tsingy2.jpg";
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets/gare-antsirabe.jpg";
import i3 from "./../../../../../assets/miandrivazo.jpg";
import i4 from "./../../../../../assets/fleuve1.jpg";
import i5 from "./../../../../../assets/tsiribihina (1).jpg";
import i6 from "./../../../../../assets/belo_tsiribihina.jpg";
import i7 from "./../../../../../assets/bekopaka.jpg";
import i8 from "./../../../../../assets/tsingy1.jpg";
import i9 from "./../../../../../assets/alley.jpg";
import i10 from "./../../../../../assets/alle_baobab.jpg";
import i11 from "./../../../../../assets/alle_baobab.jpg";
import i12 from "./../../../../../assets/Tana.jpg";
import {
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Rivertrip_Bemaraha_map from '../../map/Rivertrip_Bemaraha_map';
import { Link } from "react-router-dom";
import Rivertrip_bemaraha_cover from '../Rivertrip_bemaraha_cover';


const Rivertrip_Bemaraha_content = () => {
    return (
      <div className="app-tours__content">
        <div className="app-contain">
          <div className="row g-4">
            <div className="col-12  col-lg-8">
              <div className="main-contain">
                <Rivertrip_bemaraha_cover />

                <div className="tour-navigation">
                  <div className="tour-navigation__content d-flex justify-content-center">
                    <h3 className="active">Itinerary</h3>
                    <h3>
                      {" "}
                      <Link
                        to="/tours/weast-anglais/RiverTrip-Bemaraha-anglais-gallery"
                        className="a"
                      >
                        Pictures
                      </Link>
                    </h3>
                  </div>
                  <div className="tour-navigation__line"></div>
                </div>

                <div className="itinerary">
                  <div className="row gy-5" id="row-princip">
                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i1} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 1{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antananarivo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Assistance at the airport, and transfer to the
                                hotel. If you have time, going to the point of
                                view that you can see Antananarivo and his area
                                Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i2} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 2{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana- Antsirabé
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive to Antsirabe across the highland
                                sceneries, traditional red clay house of
                                highland, to reach the town of Antsirabe which
                                means a place of much salt Antsirabe , a town of
                                water for its thermal source ,spring water
                                .Antsirabe is also a capital of rickshaws
                                Overnight at the hotel.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i3} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 3{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Antsirabé - Miandrivazo
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive to Miandrivazo , beautiful landscape of
                                mountain on the way. Lunch at the Malagasy local
                                restaurant Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i4} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 4{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Starting of cruise along the TSIRIBIHINA RIVER
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Start the rivertrip after the breakfast along
                                the Tsiribihina River with the local pirogue or
                                boat motors. Full day on the canoeing.
                                Tsiribihina River is one of the best places to
                                enjoy the nature, birdlife.Meeting with the
                                local people always smiling. Lunch in the
                                riverbank prepared by the localguid . The
                                landscape of the forest still primary forest,
                                the white sand along the Tsiribihina river, the
                                tranquility on the tsiribihina river with the
                                campfire, songs, dinner with candle under the
                                beauty sky with stars and moon make this
                                rivertrip exciting and special Dinner prepared
                                by the local guid Camping for the night
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i5} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 5{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Gorge of Tsiribihina , with the cascade and
                                natural swimming pool
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Continuation of the cruise after breakfast,
                                passing through the gorge of the Tsiribihina
                                where you will have possibility to see the
                                lemurs, Sifaka lemur or /and the Lemur Fulvus,
                                chameleons,birds , crocodile. Lunch in the
                                riverbank prepared by the local guid Walking to
                                the cascade ,and the natural swimming pool
                                ,swimming in the cascade and the swimming pool .
                                Beautiful sunseat. Dinner with candle under the
                                beauty sky with stars and/or moon prepared by
                                the local guid. Camping for the night
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i6} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 6{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                End of the river – ANTSIRARAKY- BELO sur
                                TSIRIBIHINA
                              </h2>
                              <div className="lig"></div>
                              <p>
                                After Breakfast, continuation of the cruise,
                                going through a cliff home to bats, birds such
                                as owl bird .After lunch, continuation with
                                zebu’s car for 1 hours until Antsiraraky
                                Overnight at the hotel.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i7} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 7{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                BELO sur TSIRIBIHINA - Bekopaka
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Departure to Bekopaka by 4x 4 car, lunch at
                                Belo/Tsiribihina, Crossing by ferry the 2 rivers
                                (Tsiribihina and Manambolo), Passing through the
                                dry landscape with a sandy and bumpy road
                                Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i8} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 8{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Visit of the Tsingy de Bemaraha National Park
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Departure by 4X4 car for joining the entrance
                                park of the BigTsingy, exploring the big tsingy
                                during 3hours until 8hours, it’s depend on your
                                physical condition A strong feeling,through the
                                bridge hanging of Andamozavaky circuits The
                                Tsingy gives you opportunity to see different
                                species of lemurs such as Decken's Sifaka, Red
                                Fronted Brown Lemur, Grey Mouse Lemur, home of
                                bird such as Coquerel's Coua, Sickle Billed
                                Vanga, White Headed Vanga and many kinds of
                                reptiles.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i9} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 9{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Bekopaka - Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Drive to coming back to Morondava with a sansdy
                                and bumpy road, lunch at Belo sur
                                Tsiribihina.Cross again the 2 ferrys; Stop on
                                the road for the sacred baobab, baobab in
                                love,Sakalava’s tomb.Stop at the famous and
                                unique Alley of Baobab .Sunseat at the Alley of
                                Baobab for an amazing unforgettable picture.
                                Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i10} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 10{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Relax on the beach after the bad road , Visit of
                                the fisherman village through the mangrove with
                                the local’s pirogue Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i11} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 11{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Morondava - Tana by Flight
                              </h2>
                              <div className="lig"></div>
                              <p>
                                Transfert to the airport Overnight at the hotel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12" id="dernier-col">
                      <div className="row row-child" id="row-child">
                        <div className="col-10 col-md-7 col-lg-5">
                          <div className="image">
                            <img src={i12} alt="" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-7">
                          <div className="tour_details">
                            <div className="tour_details_title">
                              <h1>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  id="icon"
                                />{" "}
                                Day 12{" "}
                              </h1>
                              <h2>
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  id="icon"
                                />
                                Tana
                              </h2>
                              <div className="lig"></div>
                              <p>
                                City tour in Tana , visit of lemur's park , king
                                palace in Ambohimanga
                                <br /> Transfert to the airport for your
                                international flight
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
              <div className="main-secondary">
                <Rivertrip_Bemaraha_map />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Rivertrip_Bemaraha_content;