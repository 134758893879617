import React from 'react';
import Classical_east_content from '../../../../components/version_anglais/estAng/est_classique/itineraire/Classical_east_content';
import Navbar_tours from '../../../../components/version_anglais/Navbar/Navbar_tours';
import Footer from '../../../../components/version_anglais/Footer/Footer';

const Classical_east: React.FC = () => {
    return (
        <div className='app-tours'>
            <Navbar_tours />
            <Classical_east_content />
            <Footer/>
        </div>
    );
};

export default Classical_east;