import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i1 from "./../../../../../assets/Tana.jpg";
import i2 from "./../../../../../assets//indri.jpg";
import i3 from "./../../../../../assets/andasibé.jpg";
import i4 from "./../../../../../assets/manambato.jpg";
import i5 from "./../../../../../assets/aye.jpg";
import i6 from "./../../../../../assets/manambato2.jpg";
import i7 from "./../../../../../assets/Tana.jpg";
import {
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import Pangalane_map_east from "../../../../map_east/Pangalane_map_east";
import { Link } from "react-router-dom";
import Pangalane_tour_cover from "../Pangalane_tour_cover";

const Pangalane_east_content = () => {
  return (
    <div className="app-tours__content">
      <div className="app-contain">
        <div className="row g-4">
          <div className="col-12  col-lg-8">
            <div className="main-contain">
              <Pangalane_tour_cover />

              <div className="tour-navigation">
                <div className="tour-navigation__content d-flex justify-content-center">
                  <h3 className="active">Itinerary</h3>
                  <h3>
                    {" "}
                    <Link
                      to="/tours/east-anglais/pangalane-east-gallery"
                      className="a"
                    >
                      Pictures
                    </Link>
                  </h3>
                </div>

                <div className="tour-navigation__line"></div>
              </div>

              <div className="itinerary">
                <div className="row gy-5" id="row-princip">
                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i1} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 1{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Antananarivo
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Assistance at the airport, and transfer to the
                              hotel. If you have time, going to the point of
                              view that you can see Antananarivo and his area
                              Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i2} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 2{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana- Andasibé
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Departure in the morning for Andasibe, going
                              through the green scenery. Stop at Madagascar
                              Exotic reserve, which is reputed for its
                              butterflies, frogs,chameleons, reptiles,
                              batrachians, and crocodiles. Night walk to
                              spotting the nocturnal lemurs known as the
                              smallest of the lemurs in the world. Overnight at
                              the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i3} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 3{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Andasibé
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit of Andasibe Park . You will have opportunity
                              to observe the INDRI-INDRI , the biggest lemur,
                              endemic to the island,Orchids, ferns as well as
                              birds. Visit of Vakona reserve which gives you
                              opportunity to see the lemurs jumping in your
                              shoulders Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i4} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 4{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Andasibé - Manambato - Akanin ' ny nofy
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Drive Andasibe to Manambato ; then boat speed
                              Manambato to Ankanin'ny nofy (Palmarium) ,boarding
                              the lake RASOABE, RASOAMASAY exit to the
                              Pangalane's channel Cruise in the Channel of
                              Pangalane gives you opportunity to see the real
                              life of the people living alongside Overnight at
                              the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i5} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 5{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Palmarium Reserve
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Visit of the Palmarium reserv . Only the reserv
                              that you have opportunity to come close to the
                              Indri indri and the another species of lemurs in
                              the forest such as crowned lemur,black and white
                              ruffed lemur,red fronted brown lemur ; the
                              plantation of vanilla , niaouly . Visit of
                              fisherman village in the afternoon Night walking
                              specially for the lemurs AYE AYE AYE AYE :
                              endangered lemurs Overnight at the hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i6} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 6{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Ankanin' ny nofy(Palmarium) - Manambato
                            </h2>
                            <div className="lig"></div>
                            <p>
                              Boat speed Akanin’ny nofy to Manambato , then car
                              for coming back to Antananarivo Overnight at the
                              hotel
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12" id="dernier-col">
                    <div className="row row-child" id="row-child">
                      <div className="col-10 col-md-7 col-lg-5">
                        <div className="image">
                          <img src={i7} alt="" />
                        </div>
                      </div>

                      <div className="col-12 col-lg-7">
                        <div className="tour_details">
                          <div className="tour_details_title">
                            <h1>
                              <FontAwesomeIcon icon={faCalendarDay} id="icon" />{" "}
                              Day 7{" "}
                            </h1>
                            <h2>
                              <FontAwesomeIcon icon={faLocationDot} id="icon" />
                              Tana
                            </h2>
                            <div className="lig"></div>
                            <p>
                              City tour in Tana , visit of lemur’s park ,
                              digue”s market Transfert to the airport for the
                              international flight
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-11 col-md-12  col-lg-4 mt-5 mt-md-0">
            <div className="main-secondary">
              <Pangalane_map_east />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pangalane_east_content;
